import React, { useEffect, useState } from 'react';
import { DataGrid, stringFilterFn, highlightFilterValue } from 'mantine-data-grid';
import { useLocation, useNavigate } from 'react-router-dom';
import Homepage from '../components/Navigation/parentHome';
import { AllocationData } from '../components/Calculator/allocationData';

interface PoNoData {
    po_id?: string;
    poNo: string;
    status: string;
}

interface TableData {
    po_id?: string;
    poNo: string;
    status: string;
    maxDate: Date | string;
}


const Worksheet: React.FC = () => {
    const [poNoData, setPoNoData] = useState<PoNoData[]>([]);
    const [navbarOpened, setNavbarOpened] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Access the state object from the location
    const path = location.state;


    // const formatDateTime = (dateTimeString: Date) => {
    //     const date = new Date(dateTimeString);
    //     // Check if date is valid
    //     if (isNaN(date.getTime())) {
    //         // Return the original string if it's not a valid date
    //         return dateTimeString;
    //     }
    //     const formattedDate = date.toLocaleDateString(); // Format date
    //     const formattedTime = date.toLocaleTimeString(); // Format time
    //     return `${formattedDate} \u00A0\u00A0\u00A0 ${formattedTime}`; // Combine date and time with a space
    // };


    const fetchAllData = async () => {
        try {
            // const response = await fetch('/allocation/');
            const response = await fetch('/allocation/getPoNo', { credentials: 'include' });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            // const data: AllocationData[] = await response.json();
            // // const uniquePoNo = new Set(data.map(alloc => alloc.poNo));
            // const poNoList = Array.from(new Set(data.map(alloc => alloc.poNo)));
            // const poNoWithStatus: TableData[] = poNoList.map(poNo => {
            //     let flags: boolean[] = [];
            //     data.filter(alloc => alloc.poNo === poNo)
            //         .forEach(alloc => {
            //             if (alloc.status.every(status => status === true)) {
            //                 flags.push(true);
            //             } else {
            //                 flags.push(false);
            //             }
            //         });
            //     let maxDate: string | Date = '';
            //     const allDates = data.filter((allocationItem: AllocationData) => allocationItem.poNo === poNo)
            //         .map((allocationItem: AllocationData) => new Date(allocationItem.updatedAt));

            //     maxDate = formatDateTime(new Date(Math.max(...allDates.map(date => date.getTime()))));
            //     if (flags.every(status => status === true)) {
            //         return { poNo, status: 'Done', maxDate };
            //     } else if (flags.every(status => status === false)) {
            //         return { poNo:poNo, status: 'Pending', maxDate };
            //     } else {
            //         return { poNo:poNo, status: 'In progress', maxDate };
            //     }
            // });

            const poNoWithStatus: TableData[] = await response.json();
            
            poNoWithStatus.sort((a: TableData, b: TableData) => {
                if (a.status === 'In progress' && b.status !== 'In progress') return -1;
                if (a.status === 'Pending' && b.status !== 'Pending' && b.status !== 'In progress') return -1;
                return 0;
            });

            await setPoNoData(poNoWithStatus);
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        fetchAllData();
    }, []);


    // Handler function to navigate to the "/styleqty" route with row data
    const handleNavigateToStyleQty = (event: React.MouseEvent<HTMLElement>) => {
        const rowData = event.currentTarget.dataset.rowData;
        let parsedRowData: PoNoData | null = null;
        if (rowData) {
            parsedRowData = JSON.parse(rowData)['original'];
        }
        navigate(path, { state: { parsedRowData } }); // Pass the row data as state
    };

    return (
        <div>
            <Homepage setNavbarOpened={setNavbarOpened} />
            <div style={{ marginTop: '120px', width: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
                <h1 style={{ textAlign: 'center', alignItems: 'center' }}>Po Worksheet</h1>
                <DataGrid
                    data={poNoData}
                    striped
                    highlightOnHover
                    withGlobalFilter
                    withPagination
                    withColumnFilters
                    withSorting
                    onRow={(row) => ({
                        onClick: (row) => handleNavigateToStyleQty(row),
                        'data-row-data': JSON.stringify(row),
                    })}
                    styles={{
                        globalFilter: {
                            border: '1px solid black',
                            radius: '5px',
                            backgroundColor: 'white',
                        },
                        table: {
                            border: '1px solid black',
                            radius: '5px',
                        },
                        thead: {
                            border: '1px solid black',
                            backgroundColor: 'gold',
                        },
                    }}
                    columns={[
                        {
                            accessorKey: 'poNo',
                            header: 'Po No',
                            filterFn: stringFilterFn,
                            size: 200,
                            cell: highlightFilterValue,
                        },
                        {
                            accessorKey: 'status',
                            header: 'Status',
                            filterFn: stringFilterFn,
                            size: 70,
                        },
                        {
                            accessorKey: 'maxDate',
                            header: 'Last Updated Date',
                            filterFn: stringFilterFn,
                            size: 70,
                        }
                    ]}
                    initialState={{
                        pagination: {
                            pageIndex: 0,
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default Worksheet;
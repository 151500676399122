

import React, { useEffect, useState } from 'react';
// import AllocationInvoice from './AllocationInvoice';
import { Flex, Select, Table } from '@mantine/core';
import Homepage from '../Navigation/parentHome';
import axios from 'axios';
import '../../components/Styles/allocationInvoice.css';


interface AllocationData {
    storeName: string[];
    sizeQuantities: SizeQuantity[][];
    supplierName: string;
    poNo: string;
    styles: StyleData;
}


interface StyleData {
    style_no: string;
    description: string;
}


interface SizeQuantity {
    size: string;
    quantity: number;
}

interface StoreData {
    store: string;
    styles: number; // Represents number of unique styles in the store
    quantity: number; // Total quantity of all styles in the store
    stylesMen: number; // Represents number of unique styles in the store for Men
    stylesWomen: number; // Represents number of unique styles in the store for Women
    stylesKids: number; // Represents number of unique styles in the store for Kids
}
interface InvocieDataPoNo {
    props: { poNoList: {label: string, value: number}[]; };
}
 
const InvoiceDataPoNo: React.FC<InvocieDataPoNo> = ({props}) => {
    const [navbarOended, setNavbarOpened] = useState(false);
    const [allocationData, setAllocationData] = useState<AllocationData[]>([]);
    const [loading, setLoading] = useState(true);
    const [poNumber, setPoNumber] = useState('');
    const [supplier, setSupplier] = useState('');
    const [totalStyles, setTotalStyles] = useState(0);
    const [storeData, setStoreData] = useState<StoreData[]>([]);
    const [poId, setPoId] = useState<string>('');
    const [poNoList, setPoNoList] = useState<{label: string, value: string}[]>([]);
    const [uniquePoNoList, setUniquePoNoList] = useState<{label: string, value: number}[]>([]);

    useEffect(() => {    
    const fetchEntireData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`/allocation/poNo/${poId}`, { credentials: 'include' });
            const data = await response.json() as AllocationData[];
            setAllocationData(data);

            if (data.length > 0) {
                setSupplier(data[0].supplierName);
                setPoNumber(data[0].poNo);
            }

            const styleSet = new Set();
            const storeCounts: { [key: string]: number } = {};
            const storeQuantities: { [key: string]: number } = {};
            const storeCountsMen: { [key: string]: number } = {};
            const storeCountsWomen: { [key: string]: number } = {};
            const storeCountsKids: { [key: string]: number } = {};

            data.forEach(item => {
                styleSet.add(item.styles.style_no);
                item.storeName.forEach((store, index) => {
                    storeQuantities[store] = (storeQuantities[store] || 0) + item.sizeQuantities[index].reduce((sum, sq) => sum + sq.quantity, 0);
                    if (storeQuantities[store] > 0) {
                        storeCounts[store] = (storeCounts[store] || 0) + 1;
                        if (item.styles.description.startsWith('M ')) {
                            storeCountsMen[store] = (storeCountsMen[store] || 0) + 1;
                        }
                        if (item.styles.description.startsWith('W ')) {
                            storeCountsWomen[store] = (storeCountsWomen[store] || 0) + 1;
                        }
                        if (item.styles.description.startsWith('K ')) {
                            storeCountsKids[store] = (storeCountsKids[store] || 0) + 1;
                        }
                    }else{
                        storeCounts[store] = 0;
                        storeCountsMen[store] = 0;
                        storeCountsWomen[store] = 0;
                        storeCountsKids[store] = 0;
                    }
                });
            });

            const computedStoreData = Object.keys(storeCounts).map(store => ({
                store,
                styles: storeCounts[store],
                quantity: storeQuantities[store],
                stylesMen: storeCountsMen[store] || 0,
                stylesWomen: storeCountsWomen[store] || 0,
                stylesKids: storeCountsKids[store] || 0
            }));

            setTotalStyles(styleSet.size);
            setStoreData(computedStoreData);

            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };
    fetchEntireData();
},[poId]);

useEffect(() => {
    setUniquePoNoList(props.poNoList);
},[props.poNoList]);
    const handlePoNoSearch = (value: string) => {
        // fetchPoNo();
        if(value !== null){
            setPoNoList(uniquePoNoList.map((item) => ({label: item.label, value: item.value.toString()})));
        }
    }

    const handleOnChangePoNo = async (value: string | null) => {

        if (value !== null) {
            setPoId(value);
        }
    }

    return (
        <div>
            <Homepage setNavbarOpened={setNavbarOpened} />
            <div style={{ marginLeft: '120px', marginRight: '120px', justifyContent: "center", alignItems: "center" }}>
                <Select
                    type="text"
                    label="Po No"
                    value={poId} // Convert to uppercase
                    onChange={handleOnChangePoNo}
                    data={poNoList}
                    searchable
                    onSearchChange={handlePoNoSearch}
                    placeholder="Enter po_no"
                    mih={50}
                />
                <Flex
                    mih={50}
                    gap="large"
                    justify="center"
                    align="center"
                    direction="row"
                    wrap="wrap"
                    style={{ width: "80%", marginLeft: "30px" }}
                >
                    <div className='label-container'>
                        <label className='label'>Po No:</label>
                        <label className='label-value'>{uniquePoNoList.find(item => item.value.toString() === poId)?.label}</label>
                    </div>
                    <div className='label-container'>
                        <label className='label'>Supplier:</label>
                        <label className='label-value'>{supplier}</label>
                    </div>
                    <div className='label-container'>
                        <label className='label'>Total Styles:</label>
                        <label className='label-value'>{totalStyles}</label>
                    </div>
                </Flex>
                <Table className='responsive-table'>
                    <tbody>
                        <tr className='table-header'>
                            <td>Index</td>
                            <td>Store</td>
                            <td>Number of Style</td>
                            <td>Quantity</td>
                            <td>Men</td>
                            <td>Women</td>
                            <td>Kids</td>
                        </tr>
                        {storeData?.map((item, index) => (
                            <tr key={index} className='table-row'>
                                <td className='col col-1'>{index + 1}</td> {/* Display index */}
                                <td className='col col-1'>{item.store}</td>
                                <td className='col col-1'>{item.styles}</td>
                                <td className='col col-1'>{item.quantity}</td>
                                <td className='col col-1'>{item.stylesMen}</td>
                                <td className='col col-1'>{item.stylesWomen}</td>
                                <td className='col col-1'>{item.stylesKids}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default InvoiceDataPoNo; // Export
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Col, Container, Grid, Input, Table, TextInput, Text, Center, MantineProvider, Group, Select, Flex, Image, Switch } from '@mantine/core';
import Homepage from '../Navigation/parentHome';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import { RefreshContext } from '../privateRoute/RefreshContext';
import JsBarcode from 'jsbarcode';
import { useLocation } from 'react-router-dom';
import PrintStoreLabels from './printStoreLables';


pdfMake.vfs = pdfFonts.pdfMake.vfs;


const PrintLabel = () => {
    const [allocationData, setAllocationData] = useState<any>(null);
    const [styleData, setStyleData] = useState<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [navbarOpened, setNavbarOpened] = useState(false);
    const [poNo, setPoNo] = useState<string>('');
    const [poId, setPoId] = useState<string>('');
    const [styleNo, setStyleNo] = useState<string>('');
    const [storeTotals, setStoreTotals] = useState<number[]>([]);
    const [totalAllocation, setTotalAllocation] = useState<number>(0);
    const [overstockTotal, setOverstockTotal] = useState<number>(0);
    const [isMounted, setIsMounted] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const [checked, setChecked] = useState(false);
    const [manualOverstock, setManualOverstock] = useState<string>('');
    const [manualsize, setManualsize] = useState<string>('');
    const [imageData, setImageData] = useState<string>("");
    const [styleList, setStyleList] = useState<{ value: string; label: string }[]>([]);
    const [entireData, setEntireData] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [poNoList, setPoNoList] = useState<{ label: string, value: string }[]>([]);
    const [itemType, setItemType] = useState<'clothing' | 'footware'>('clothing');
    // Inside AllocationComponent component
    const { refresh, setRefresh } = useContext(RefreshContext);
    const btnLabel = 'Store Label';
    interface AllocationData {
        storeName: string[];
        sizeQuantities: SizeQuantity[][];
        supplierName: string;
        poNo: string;
        style_id: number;
        styles: StyleData;
    }


    interface StyleData {
        style_id: number;
        style_no: string;
    }

    interface PoNoData {
        po_id: string;
        poNo: string;
        status: string;
    }

    interface SizeQuantity {
        size: string;
        quantity: number;
    }


    useEffect(() => {
        // Fetch allocation data when the component mounts
        // fetchAllocationData(style_id, poNo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    // For example, a function to fetch the allocation data based on po_no and style_no:
    const fetchAllocationData = async (style_id: number, poId: string) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get(`/allocation/style_id/${style_id}/poId/${poId}`, { withCredentials: true });
            await setStyleData(response.data.style);
            setStyleNo(response.data.style.style_no);
            await setAllocationData(response.data.allocation);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setError('The provided styleNo or poNo does not exist or match any records.');
            setIsLoading(false);
        }

    };

    useEffect(() => {
        calculateRowTotals();
        calculateTotalAllocation();
        calculateOverstockTotal();
        calculateOverstockPerSize();
    }, [allocationData]);
    const calculateOverstockTotal = async () => {
        if (!allocationData) return;

        let total = 0;

        allocationData.overstockPerSize.forEach((quantity: number) => {
            total += quantity;
        });

        await setOverstockTotal(total);
    };


    const fetchEntireData = async () => {
        setIsMounted(true);
        try {
            const response = await fetch(`/allocation/poNo/${poId}`, { credentials: 'include' });
            const data = await response.json() as AllocationData[];
            if (isMounted) {
                await setEntireData(data);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handlePoNoBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
        fetchEntireData();
        setStyleList([]); // Clear the styleList
        return () => {
            setIsMounted(false);
        }
    }
    const calculateOverstockPerSize = async () => {
        if (allocationData?.receivedQty && allocationData?.totalAllocationPerSize) {
            const overstockPerSize: number[] = [];
            const length = Math.min(allocationData.receivedQty.length, allocationData.totalAllocationPerSize.length);
            for (let i = 0; i < length; i++) {
                let overstock = allocationData.receivedQty[i].quantity - allocationData.totalAllocationPerSize[i];
                overstockPerSize.push(overstock);
            }
            await setAllocationData((prevState: any) => ({ ...prevState, overstockPerSize: overstockPerSize }));
        }
    };
    const calculateRowTotals = async () => {
        if (!allocationData) return;

        const totals: number[] = [];

        allocationData.storeName.forEach((store: string, rowIndex: number) => {
            let rowTotal = 0;

            allocationData.sizeQuantities[rowIndex].forEach((sizeObj: any) => {
                rowTotal += sizeObj.quantity;
            });

            totals.push(rowTotal);
        });

        await setStoreTotals(totals);
    };
    const calculateTotalAllocation = async () => {
        if (!allocationData) return;

        let total = 0;

        allocationData.totalAllocationPerSize.forEach((quantity: number) => {
            total += quantity;
        });

        await setTotalAllocation(total);
    };

    useEffect(() => {
        //Extracting style numbers from entire data
        if (entireData && entireData.length > 0) {
            entireData?.forEach((item: any) => {
                const option = {
                    value: item.styles.style_id,
                    label: item.styles.style_no
                };
                if (item) {
                    // item.styles.style_no && setStyleList((prevList: string[] | null) => [...(prevList || []), item.styles.style_no]);
                    setStyleList(prevList => [...prevList, option]);
                }
            }
            );
        }
    }, [entireData]);

    useEffect(() => {


        const fetchAllPoNo = async () => {
            try {
                const response = await fetch(`/allocation/getPoNo/`, { credentials: 'include' });
                const data: PoNoData[] = await response.json();
                const poData = data.map((item: PoNoData) => ({
                    label: item.poNo,
                    value: item.po_id
                }));
                await setPoNoList(poData);
            } catch (error) {
                console.error('Error :', error);
            }
        }
        fetchAllPoNo();

    }, [])

    const handleStyleDataChange = (key: string, value: any) => {
        const newData = { ...styleData, [key]: value };
        setStyleData(newData);
    };


    // Add handlers for file and image URL inputs
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageData(reader.result as string);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // Add handlers for file and image URL inputs
    const handleImageUrlChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const url = e.target.value;
        const response = await fetch(url);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onload = () => {
            setImageData(reader.result as string);
        };
        reader.readAsDataURL(blob);
    };
    //state to check the orientation


    const [orientation, setOrientation] = useState<'portrait' | 'landscape'>('portrait');

    // Handler for orientation change
    const handleOrientationChange = (value: 'portrait' | 'landscape') => {
        setOrientation(value);
    };

    // Function to generate code
    const generateCode = (): string => {

        // Assuming allocationData.storeName and storeTotals are available in the context
        let targetStoreIndex = -1;
        let code = 0;
        if (!checked) {
            targetStoreIndex = allocationData.storeName.findIndex((store: string) => store.toUpperCase() === 'STEELES');
            if (targetStoreIndex === -1 || storeTotals[targetStoreIndex] === 0) { // If "Steeles" not found
                code = overstockTotal + totalAllocation;
            } else {
                let storeTotal = 0;
                if (targetStoreIndex !== -1) {

                    // Use the total of the found store
                    storeTotal = storeTotals[targetStoreIndex];
                } else if (allocationData.storeName.length === 1) {
                    // If there is only one store, use its total regardless of the name
                    storeTotal = storeTotals[0];
                } else {
                    // If all stores have zero quantity, use overstock
                    const allStoresZero = storeTotals.every(total => total === 0);
                    if (allStoresZero) {
                        storeTotal = overstockTotal;
                    } else {
                        // Find the first non-zero total if exists
                        storeTotal = storeTotals.find(total => total > 0) || 0;
                    }
                }
                code = overstockTotal + storeTotal;
            }
        } else {
            code = parseInt(manualOverstock, 10);
        }

        // Proceed with existing logic to calculate the code
        const date = new Date();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();


        let cost: string = '';
        const costValue = parseFloat(styleData?.cost.toString());
        if (isNaN(costValue)) {
            return 'Cost is not a number';
        } else {
            if (costValue < 1) {
                // For costs less than $1
                cost = Math.round(costValue * 100).toString().padStart(4, '0');
            } else if (!Number.isInteger(costValue)) {
                // For decimal costs
                const cent = Math.round(costValue * 100);
                cost = cent.toString().padStart(4, '0');
            } else {
                // For integer costs
                cost = ((costValue * 100) / 100).toString().padStart(4, '0');
            }
        }

        const formattedCode = `${code.toString().padStart(3, '0')} - ${month.toString().padStart(2, '0')}${year.toString().slice(-2)}${cost.padStart(4, '0')}`;

        return formattedCode;
    };

    // Function to generate barcode
    const generateBarcode = (data: string) => {
        const canvas = document.createElement('canvas'); // Create a canvas element
        JsBarcode(canvas, data); // Generate the barcode
        return canvas.toDataURL(); // Convert the canvas to a data URL
    };
    // Function to generate PDF
    const generatePDF = () => {
        if (!imageData) {
            alert('Please upload an image');
            return;
        }

        const portraitMode = orientation === 'portrait';

        const contentData: Content[] = [
            {
                columns: [
                    {
                        text: `${styleData?.supplier_name}`,
                        fontSize: portraitMode ? 14 : 20,
                        alignment: 'left',
                        margin: [-15, -30, 0, 10]
                    },
                    {
                        text: `${checked ? manualsize : ''}`,
                        fontSize: portraitMode ? 14 : 20,
                        alignment: 'right',
                        margin: [-15, -30, 0, 10]
                    }
                ],
            },
            {
                columns: [
                    {

                        text:
                            `${styleData?.description}`,
                        fontSize: portraitMode ? 30 : 35,
                        alignment: 'center',
                        margin: [1, 3, 3, 3]

                    },
                ],
            },
            {
                columns: [
                    {
                        text:
                            `#${styleData?.style_no}`,
                        fontSize: portraitMode ? 35 : 65,
                        alignment: 'center',
                        bold: true,
                        margin: [1, 3, 3, 3]

                    },
                ],
            },
            {
                columns: [
                    {
                        text: `${styleData?.color}`,
                        width: '*',
                        fontSize: portraitMode ? 16 : 20,
                        alignment: 'center',
                        margin: [3, 3, 3, 3]
                    },
                    {
                        text: `R - ${parseFloat(styleData?.msrp).toFixed(0)}`,
                        width: '*',
                        fontSize: portraitMode ? 16 : 20,
                        alignment: 'center',
                        margin: [3, 3, 3, 3]
                    },
                ],
            },
            {
                columns: [
                    {
                        text:
                            checked ? `${manualOverstock} - ${generateCode().toString().split('-')[1]}` : `${generateCode()}`,
                        fontSize: portraitMode ? 16 : 20,
                        alignment: 'center',
                        margin: [0, 3, 3, 3]

                    },
                ],
            }, // Add quantity data
            {


                image: imageData,
                width: portraitMode ? 300 : 450,
                height: portraitMode ? 150 : 300,
                margin: [0, 0, 0, 50],
                alignment: 'center'


            },
            {
                image: generateBarcode(styleData?.style_no),
                width: portraitMode ? 100 : 170,
                height: portraitMode ? 30 : 50,
                margin: portraitMode ? [0, -70, -15, 0] : [0, -70, -15, 0],
                alignment: 'right'
            }
            // Add overstock data
        ];

        const contentData1: Content[] = [
            {
                columns: [
                    {
                        text: `${styleData?.supplier_name}`,

                        fontSize: portraitMode ? 14 : 20,
                        alignment: 'left',
                        margin: [-15, -20, 0, 10]
                    },
                    {
                        text: `${checked ? manualsize : ''}`,
                        fontSize: portraitMode ? 14 : 20,
                        alignment: 'right',
                        margin: [-15, -30, 0, 10]
                    }
                ],
            },
            {
                columns: [
                    {

                        text:
                            `${styleData?.description}`,
                        fontSize: portraitMode ? 30 : 35,
                        alignment: 'center',
                        margin: [3, 3, 3, 3]

                    },
                ],
            },
            {
                columns: [
                    {
                        text:
                            `#${styleData?.style_no}`,
                        fontSize: portraitMode ? 35 : 65,
                        alignment: 'center',
                        bold: true,
                        margin: [3, 3, 3, 3]

                    },
                ],
            },
            {
                columns: [
                    {
                        text: `${styleData?.color}`,
                        width: '*',
                        fontSize: portraitMode ? 16 : 20,
                        alignment: 'center',
                        margin: [3, 3, 3, 3]
                    },
                    {
                        text: `R - ${parseFloat(styleData?.msrp).toFixed(0)}`,
                        width: '*',
                        fontSize: portraitMode ? 16 : 20,
                        alignment: 'center',
                        margin: [3, 3, 3, 3]
                    }
                ],
            },
            {
                columns: [
                    {
                        text:
                            `${generateCode()}`,
                        fontSize: portraitMode ? 16 : 20,
                        alignment: 'center',
                        margin: [3, 3, 3, 3]

                    },
                ],
            }, // Add quantity data

            {

                image: imageData,
                width: portraitMode ? 300 : 450,
                height: portraitMode ? 140 : 300,
                margin: [0, 0, 0, 50],
                alignment: 'center'

            },
            {
                image: generateBarcode(styleData?.style_no),
                width: portraitMode ? 100 : 170,
                height: portraitMode ? 30 : 50,
                margin: portraitMode ? [0, -70, -15, 0] : [0, -35, -15, 0],
                alignment: 'right'
            }
            // Add overstock data
        ];

        let content: Content[];

        if (portraitMode) {
            // Add a page break and duplicate the content
            contentData.push({ text: '', marginTop: 40 });
            content = [...contentData, ...contentData1];
        } else {
            content = contentData;
        }

        const docDefinition: TDocumentDefinitions = {
            content: content,
            pageSize: 'LETTER',
            pageOrientation: orientation,
        };

        pdfMake.createPdf(docDefinition).open();
    };



    const handleOnChangePoNo = (value: string | null) => {
        // const value = event.target.value;
        if (value !== null) {
            setPoId(value.toString());
            setPoNo(value.toUpperCase());
        }
        fetchEntireData();
    }
    const handleOnChangeStyleNo = async (value: string) => {
        const tempStyle = await styleList.filter((s) => s.value === value)[0].label;
        setStyleNo(tempStyle);
        await fetchAllocationData(parseInt(value), poId);
    };

    const handleSelectType = (values: 'clothing' | 'footware') => {
        setItemType(values);
    };

    return (
        <div>
            <Homepage setNavbarOpened={setNavbarOpened} />
            <Container style={{ marginTop: "105px" }}>
                <Grid gutter="lg" justify='left' >
                    <Col span={4}>
                        <Select
                            type="text"
                            label="Po No"
                            // value={poNo.toUpperCase()} // Convert to uppercase
                            onChange={handleOnChangePoNo} // Convert to uppercase
                            onBlur={handlePoNoBlur}
                            data={poNoList.map((item) => ({ label: item.label, value: item.value.toString() }))}
                            searchable
                            // onSearchChange={handlePoNoSearch}
                            placeholder="Enter po_no"
                            mih={50}
                            disabled={checked}
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            type="text"
                            label="Style No"
                            // value={styleNo} // Convert to uppercase
                            onChange={handleOnChangeStyleNo} // Convert to uppercase
                            data={styleList}
                            placeholder="Enter style_no"
                            searchable
                            mih={50}
                            disabled={checked}
                        />
                    </Col>
                    <Col style={{ marginTop: "25px", justifyContent: "center", alignItems: "center" }} span={4}>
                        <Flex
                            mih={50}
                            gap="xs"
                            justify="flex-start"
                            align="flex-start"
                            direction="row"
                            wrap="wrap"
                        >
                            <Switch label="Manual" checked={checked} onChange={(event) => setChecked(event.currentTarget.checked)} />
                            {!checked && <PrintStoreLabels props={{ allocationData, styleData, orientation, manualsizeQty: manualsize, imageData, storeTotals, overstockTotal, styleNo, itemType, btnLabel, totalAllocation }} />}
                        </Flex>
                    </Col>
                </Grid>
            </Container>

            <MantineProvider
                theme={{
                    components: {
                        Container: {
                            defaultProps: {
                                sizes: {
                                    xs: 540,
                                    sm: 720,
                                    md: 960,
                                    lg: 1140,
                                    xl: 2340,
                                },
                            },
                        },
                    },
                }}
            >
                <Container style={{ marginTop: "30px" }} size="lg">
                    <Grid gutter="lg" justify='center'>
                        <Col span={2}>
                            <Input type="file" onChange={handleFileChange} />
                        </Col>
                        <Col span={3}>
                            <TextInput
                                placeholder="Enter image URL"
                                onChange={handleImageUrlChange}
                            />
                        </Col>
                        <Col span={2}>

                            <Select
                                data={[
                                    { value: 'portrait', label: 'Portrait' },
                                    { value: 'landscape', label: 'Landscape' },
                                ]}
                                value={orientation}
                                onChange={handleOrientationChange}
                            />
                        </Col>
                        <Col span={2}>
                            <Select
                                data={[{ value: 'clothing', label: 'Clothing' }, { value: 'footware', label: 'Footware/Accessories' }]} // Data array containing options
                                value={itemType} // Internal value
                                onChange={handleSelectType} // Handler for when an option is selected
                                placeholder="Select an option"
                            />
                        </Col>
                        <Col span={2}>
                            <Button onClick={generatePDF}>Print Label</Button>
                        </Col>
                    </Grid>
                </Container>
            </MantineProvider>

            <Grid gutter="lg" justify="center" style={{ height: "80%" }} >
                <Col span={3}>
                    <Flex
                        mih={50}
                        gap="md"
                        justify="flex-start"
                        align="flex-start"
                        direction="column"
                        wrap="wrap"
                        style={{ margin: "40px", justifyContent: "center", alignItems: "center", width: "70%" }}
                    >
                        <TextInput
                            className='text-input'
                            label="Supplier Name"
                            value={styleData?.supplier_name}
                            ta="center"
                            onChange={(e) => handleStyleDataChange('supplier_name', e.target.value)}
                        />
                        <TextInput
                            className='text-input'
                            label="Color"
                            value={styleData?.color}
                            onChange={(e) => handleStyleDataChange('color', e.target.value)}
                            ta="center"

                        />
                        <TextInput
                            className='text-input'
                            label="Cost"
                            value={styleData?.cost}
                            ta="center"
                            onChange={(e) => handleStyleDataChange('cost', e.target.value)}
                        />
                        {checked && <TextInput
                            className='text-input'
                            label="STEELES + OVERSTOCK"
                            value={manualOverstock}
                            ta={"center"}
                            onChange={(e) => setManualOverstock(e.target.value)}
                        ></TextInput>}
                    </Flex>
                </Col>
                <Col span={3}>
                    <Flex
                        mih={50}
                        gap="md"
                        justify="flex-start"
                        align="flex-start"
                        direction="column"
                        wrap="wrap"
                        style={{ margin: "40px", justifyContent: "center", alignItems: "center", width: "70%" }}
                    >

                        <TextInput
                            className='text-input'
                            label="Style Number"
                            value={styleData?.style_no}
                            ta="center"
                            onChange={(e) => handleStyleDataChange('style_no', e.target.value)}
                        />
                        <TextInput
                            className='text-input'
                            label="Description"
                            value={styleData?.description}
                            onChange={(e) => handleStyleDataChange('description', e.target.value)}
                            ta="center"
                        />
                        <TextInput
                            className='text-input'
                            label="MSRP"
                            value={styleData?.msrp}
                            ta="center"
                            onChange={(e) => handleStyleDataChange('msrp', e.target.value)} />

                        <TextInput
                            className='text-input'
                            label="Size/Qty"
                            value={manualsize}
                            ta="center"
                            onChange={(e) => setManualsize(e.target.value)}
                        ></TextInput>
                    </Flex>
                </Col>
                <Col span={6} style={{ justifyContent: "center", alignItems: "center" }} >
                    <Image style={{
                        marginTop: "70px",
                        width: '500px',
                        height: '650px'
                    }}
                        mx="auto" radius="md" src={imageData} alt="Style Image" />
                </Col>
            </Grid>
        </div>
    );
};

export default PrintLabel;
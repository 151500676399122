import { Grid, Table, Paper, Col, Input, Button, Modal, Text, createStyles, Group, Flex, Select } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import "./AllocTable.css";
import Homepage from '../components/Navigation/parentHome';
import createPdf from './poReport';
import { IconDownload, IconFilter, IconFilterX } from '@tabler/icons-react';
import { DataTable } from 'mantine-datatable';
//import HomePage from './parent';
import { useLocation } from 'react-router-dom';
import { Console } from 'console';

interface ReceivedQty {
  size: string;
  quantity: number;

}

interface StyleData {
  supplier_name: string;
  style_id: number;
  style_no: string;
  description: string;
  color: string;
  cost: number;
  msrp: number;
  total_qty: number;
  location: string;
  first_name: string;
  notes: string;
  receivedQty: ReceivedQty[];
}

interface PurchaseOrderData {
  po_id: string;
  po_no: string; 
}
interface PoNoData {
  po_id?: string;
  poNo: string;
  status: string;
}
interface AllocationData {
  style_id: number;
  supplierName: string;
  receivedQty: ReceivedQty[];
  total: number;
  totalAllocationPerSize: number[];
  overstockPerSize: number[];
  poNo: string;
  skuNumbers: string[];
  styleQty_id: number;
  styles: StyleData;
  PurchaseOrders: PurchaseOrderData;
}

const initialFilterState = {
  po_id: '',
  supplierName: '',
  poNo: '',
};

const useStyles = createStyles((theme) => ({
  fixedBar: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    background: '#ECF0F1 ',
    padding: '10px 0',
    boxShadow: '0px -2px 10px rgba(0,0,0,0.1)'
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    //tableLayout: 'fixed',
    borderCollapse: 'collapse',
    border: '1px solid',
  },
  tableCell: {
    border: '1px solid',
    width: '50px',
  },
}));

const StyleQuantitiesTable: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { classes, cx } = useStyles();
  const [data, setData] = useState<AllocationData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filter, setFilter] = useState(initialFilterState);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [navbarOpened, setNavbarOpened] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 16;
  const [poNoList, setPoNoList] = useState<string[]>([]);
  const [poNoListSearch, setPoNoListSearch] = useState<string[]>([]);

  const poNoSet = new Set<string>();

  const location = useLocation();

  // Access the state object from the location
  const rowData: PoNoData = location.state?.parsedRowData;


  useEffect(() => {
    poNoSet.clear();
    fetch('/allocation/', { credentials: 'include' })
      .then(response => response.json())
      .then(jsonData => {
        setData(jsonData);
        jsonData.forEach((item: AllocationData) => {
          poNoSet.add(item.PurchaseOrders.po_no);
        });
        setPoNoList(Array.from(poNoSet));
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (rowData) {
      setFilter(prevFilter => ({
        ...prevFilter,
        po_id: rowData?.po_id?.toString() || '',
      }));
    }
  }, [rowData?.poNo]);


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(1);
  }

  const resetFilter = () => {
    setFilter(initialFilterState);
    setSearchTerm("");
    setTempFilter(initialFilterState);
  };

  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [tempFilter, setTempFilter] = useState(initialFilterState);

  const calculateTotalDiscrepancy = (record: AllocationData): number => {
    if (record.receivedQty && Array.isArray(record.receivedQty) && record.styles && Array.isArray(record.styles.receivedQty)) {
      const discrepancy = record.receivedQty.map((row: ReceivedQty, index: number) => (
        row.quantity - (record.styles.receivedQty[index] ? record.styles.receivedQty[index].quantity : 0)
      ));
      return discrepancy.reduce((acc: number, curr: number) => acc + curr, 0);
    } else {
      return 0;
    }
  };

  const filteredData = data.map((row) => ({
    ...row,
    styles: {
      ...row.styles,
      description: capitalizeFirstLetter(row.styles.description),
    }
  }))
    .filter((row) =>
      (!filter.supplierName || row.supplierName.toLowerCase() === filter.supplierName.toLowerCase()) &&
      (!filter.po_id || row.PurchaseOrders.po_id.toString() === filter.po_id) &&
      (!filter.poNo || row.PurchaseOrders.po_no.toLowerCase() === filter.poNo.toLowerCase()) &&
      (!searchTerm || row.styles.style_no.toLowerCase().includes(searchTerm.toLowerCase()))
    ).map((row) => ({ ...row, totalDiscrepancy: calculateTotalDiscrepancy(row) }));
  const paginatedData = filteredData.slice((page - 1) * pageSize, page * pageSize)
  const applyFilter = () => {
    setPage(1);
    setFilterModalOpen(false);
    setFilter(tempFilter);
  }
  function capitalizeFirstLetter(str: string): string {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }
  const handlePoNoSearch = (value: string) => {
    setPoNoListSearch([]);
    setPoNoListSearch(poNoList.filter((item) => item.toLowerCase().includes(value.toLowerCase())));
  }
  const handlePoNoChange = (value: string | null) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      poNo: value?.toUpperCase() || '',
    }));
  }

  return (
    <>
      <Homepage setNavbarOpened={setNavbarOpened} />
      <Grid gutter="md" className="alloc-table">
        <Col span={12}>
          <Paper shadow="xs" className="table-wrapper">
            <DataTable
              withBorder
              withColumnBorders
              striped
              highlightOnHover
              columns={[
                { accessor: 'styles.style_no', title: 'Style No', titleClassName: 'expanded-vertical-table' },
                { accessor: 'styles.description', title: 'Description', titleClassName: 'expanded-vertical-table' },
                { accessor: 'styles.color', title: 'Color', titleClassName: 'expanded-vertical-table' },
                {
                  accessor: 'styles.cost',
                  title: 'Cost',
                  titleClassName: 'expanded-vertical-table',
                  render: (value) => `$${value.styles.cost}` // Assuming the cost is a number and you want it formatted as currency
                },
                {
                  accessor: 'styles.msrp',
                  title: 'MSRP',
                  titleClassName: 'expanded-vertical-table',
                  render: (value) => `$${value.styles.msrp}` // Assuming the MSRP is a number and you want it formatted as currency
                },
                { accessor: 'supplierName', title: 'Supplier', titleClassName: 'expanded-vertical-table' },
                { accessor: 'PurchaseOrders.po_no', title: 'PO No', titleClassName: 'expanded-vertical-table' },
                { accessor: 'total', title: 'Total', titleClassName: 'expanded-vertical-table' },
                { accessor: 'totalDiscrepancy', title: 'Total Discrepancy', titleClassName: 'expanded-vertical-table' },
                { accessor: 'styles.notes', title: 'Notes', titleClassName: 'expanded-vertical-table', render: (value) => value.styles.notes}
              ]}
              records={paginatedData}
              idAccessor={'allocation_id'}
              rowExpansion={{
                allowMultiple: true,
                content: ({ record }) => {
                  const discrepancy = record.receivedQty.map((row, index) => (
                    row.quantity - (record.styles && Array.isArray(record.styles.receivedQty) && record.styles.receivedQty[index]
                      ? record.styles.receivedQty[index].quantity
                      : 0)
                  ));

                  return (
                    <Table>
                      <thead>
                        <td className='classes.tableCell'>Size</td>
                        {record.receivedQty.map((row) => (
                          <td className='classes.tableCell'>{row.size}</td>
                        ))}
                      </thead>
                      <tbody>
                        <tr>
                          <td className='classes.tableCell'>RCV Qty</td>
                          {record.receivedQty.map((row) => (
                            <td className='classes.tableCell'>{row.quantity}</td>
                          ))}
                        </tr>
                        <tr>
                          <td className='classes.tableCell'>Total Allocation</td>
                          {record.receivedQty.map((row, index) => (
                            <td>{record.totalAllocationPerSize[index]}</td>
                          ))}
                        </tr>
                        <tr>
                          <td className='classes.tableCell'>Overstock</td>
                          {record.receivedQty.map((row, index) => (
                            <td>{record.overstockPerSize[index]}</td>
                          ))}
                        </tr>
                        <tr>
                          <td className='classes.tableCell'>Discrepancy</td>
                          {record.receivedQty.map((row, index) => (
                            <td>{discrepancy[index] !== 0 ? discrepancy[index] : ''}</td>
                          ))}
                        </tr>
                      </tbody>
                    </Table>
                  )
                }
              }}
            />
          </Paper>
        </Col>
        <div className={classes.fixedBar}>
          <Grid gutter="md" style={{ marginRight: "2px", marginLeft: "10px" }}>

            <Col span={5} >
              <Flex
                direction={{ base: 'column', sm: 'row' }}
                gap={{ base: 'sm', sm: 'lg' }}
                justify={{ sm: 'center' }}
                style={{ width: '100%' }}
              >
                <Select
                  type="text"
                  value={filter.poNo.toUpperCase()}
                  onChange={handlePoNoChange}
                  defaultValue={filter.poNo.toUpperCase()}
                  // onBlur={handlePoNoBlur}
                  data={poNoListSearch.slice(0, 5)}
                  searchable
                  onSearchChange={handlePoNoSearch}
                  placeholder="Search by po_no"
                  mih={50}
                />
                <Input
                  placeholder="Search by Style No"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{ width: '330px' }}
                />
              </Flex>
            </Col>
            <Col span={4} className='pagination'>
              <Button variant="subtle" size='xs' style={{ marginTop: '3px', border: '1px solid black' }} color="dark" onClick={() => page > 1 ? setPage(page - 1) : setPage(1)}>Prev</Button>
              <Button variant="subtle" style={{ fontWeight: 'bold', fontSize: '18px' }} size='sm' color="dark" disabled={true} >{page}</Button>
              <Button variant="subtle" size='xs' style={{ marginTop: '3px', border: '1px solid black' }} color="dark" onClick={() => { Math.ceil(filteredData.length / pageSize) > page ? setPage(page + 1) : setPage(page) }}>Next</Button>
            </Col>
            <Col span={1}>
              <Button onClick={() => setFilterModalOpen(true)} leftIcon={<IconFilter />} variant="subtle" color="dark" uppercase>Filter</Button>
              <Modal

                opened={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
                centered
              >
                <Group position='center'>
                  <Input
                    placeholder="Filter by Supplier Name"
                    value={tempFilter.supplierName}
                    onChange={(event) => setTempFilter({ ...tempFilter, supplierName: event.currentTarget.value })}
                  />
                  <Input
                    placeholder="Filter by PO No"
                    value={tempFilter.poNo}
                    onChange={(event) => setTempFilter({ ...tempFilter, poNo: event.currentTarget.value.toLowerCase() })}
                  />
                  <Button onClick={applyFilter}>Apply</Button>
                </Group>
              </Modal>
            </Col>
            <Col span={1}>
              <Button
                title="Reset Filter"
                onClick={resetFilter}
                leftIcon={<IconFilterX />}
                variant="subtle" color="dark"
              />
            </Col>
            <Col span={1}>
              <Button onClick={() => createPdf(filteredData, filter)} leftIcon={<IconDownload />} variant="subtle" color="dark" />
            </Col>

          </Grid>
        </div>
      </Grid>
    </>
  );
}

export default StyleQuantitiesTable;
import { useEffect, useState } from 'react';
import { Button, COLOR_PICKER_SIZES, Col, Flex, Grid, Modal, Paper, Select, Text, TextInput } from '@mantine/core';
import Homepage from '../components/Navigation/parentHome';
import { useDisclosure } from '@mantine/hooks';

type SizeQuantity = {
  size: string;
  quantity: number;
};

const ModifyAlloc = () => {
  const [navbarOpened, setNavbarOpened] = useState(false);
  const [opened, { open, close }] = useDisclosure(true);

  const [poNoList, setPoNoList] = useState<string[]>([]);
  const [poNoListId, setPoNoListId] = useState<{ poNo: string, poId: string }[]>([]);
  // const [poNo, setPoNo] = useState('');
  const [entireData, setEntireData] = useState<any[] | null>(null);
  const [allocationData, setAllocationData] = useState<any | null>();
  const [popUp, setPopUp] = useState(false);
  const [styleList, setStyleList] = useState<string[]>([]);
  const [styleListId, setStyleListId] = useState<{ styleNo: string, styleId: string }[]>([]);
  const [sizeData, setSizeData] = useState<any>();
  const [receivedQty, setReceivedQty] = useState<any>([]);
  // const [styleNo, setStyleNo] = useState<string>('');
  const [total, setTotal] = useState<number>();
  const [totalAllocationPerSize, setTotalAllocationPerSize] = useState<number[]>();
  const [overstockPerSize, setOverstockPerSize] = useState<number[]>();
  const [sampleArr, setSampleArr] = useState<any[]>([]);
  const [selectedSize, setSelectedSize] = useState<string>();
  const [newStyle, setNewStyle] = useState<SizeQuantity>({ size: '', quantity: 0 });


  const fetchAllPoNo = async () => {

    try {
      fetch('/allocation/getPoNo', { credentials: 'include' })
        .then(response => response.json())
        .then(data => {
          setPoNoList(data.map((item: any) => item.poNo));
          setPoNoListId(data.map((item: any) => ({ poNo: item.poNo, poId: item.po_id })));
        })
    } catch (error) {
      console.error(error);
    }

  }
  useEffect(() => {
    fetchAllPoNo();
  }, [])

  const handleAllocationChange = (key: string, value: string) => {
    const newData = { ...allocationData, [key]: value };
    setAllocationData(newData);
  }

  // Add size and quantity for newly added size default is 0
  const addSizeQty = (sz: string, qty: number) => {
    if (sz.trim().length != 0 && qty >= 0) {
      const updatedSizeData = sizeData.map((item: any[]) => {
        return [...item, { size: sz, quantity: 0 }];
      })
      const updatedReceivedQty = [...receivedQty, { size: sz, quantity: qty }];
      if (totalAllocationPerSize) {
        setTotalAllocationPerSize([...totalAllocationPerSize, 0])
      }
      if (overstockPerSize) {
        setOverstockPerSize([...overstockPerSize, qty])
      }
      if (total) {
        setTotal(total + qty);
      }
      setSampleArr([...sampleArr, sz]);
      setReceivedQty(updatedReceivedQty);
      setSizeData(updatedSizeData);
    }
  }

  //logic to delete size and all the related entities
  const deleteSizeQty = async () => {
    try {
      if (selectedSize) {
        const index = sampleArr.indexOf(selectedSize);
        if (index > -1) {
          let tempTotal = 0;
          sampleArr.splice(index, 1)
          setSampleArr(sampleArr);
          setSizeData(sizeData.map((item: any[]) => item.filter((size: any) => {
            return size.size !== selectedSize
          })));
          setReceivedQty(receivedQty.filter((size: any) => size.size !== selectedSize));
          if (totalAllocationPerSize) {
            tempTotal += totalAllocationPerSize[index];
            setTotalAllocationPerSize(totalAllocationPerSize.filter((size: any, i: number) => {
              return i !== index
            }));
          }
          if (overstockPerSize) {
            tempTotal += overstockPerSize[index];
            setOverstockPerSize(overstockPerSize.filter((size: any, i: number) => {
              return i !== index
            }));
          }
          setTotal(tempTotal);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const saveData = async () => {
    try {
      if (allocationData) {
        const response = await fetch(`/allocation/updated/${allocationData.allocation_id}`, {
          method: 'PUT',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            poId: allocationData.poId,
            poNo: allocationData.poNo.toUpperCase(),
            style_id: allocationData.style_id,
            styleNo: allocationData.styleNo,
            sizeQuantities: sizeData,
            receivedQty: receivedQty,
            totalAllocationPerSize: totalAllocationPerSize,
            overstockPerSize: overstockPerSize,
            total: total
          }),
        });
        const data = await response.json();

        fetchAllPoNo();
        fetchStyles(data.po_id);
        setAllocationData(null);
        if (response.status === 200) {
          setPopUp(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchStyles = async (po: string) => {
    try {
      await fetch(`/allocation/poNo/${po}`, { credentials: 'include' })
        .then(response => response.json())
        .then(data => {
          if (data.length > 0) {
            setStyleList(data.map((item: any) => item.styles.style_no));
            setStyleListId(data.map((item: any) => ({ styleNo: item.styles.style_no, styleId: item.styles.style_id })));
            setEntireData(data);
          }
        })
    } catch (error) {
      console.error(error);
    }
  }

  const handlePoNoChange = (value: string) => {
    const po = poNoListId.find((item) => item.poNo === value);
    if (value !== null && po?.poId) {
      // setPoNo(value);
      fetchStyles(po?.poId);
    }
  }

  const handleStyleChange = async (value: string) => {
    if (value !== null) {
      const style = styleListId.find((item) => item.styleNo === value);
      const data = entireData?.find(item => item.styles.style_id === style?.styleId);
      // await setStyleNo(value);
      if (data) {
        setSizeData(data.sizeQuantities);
        setReceivedQty(data.receivedQty);
        setSampleArr(data.receivedQty.map((item: any) => item.size));
        setOverstockPerSize(data.overstockPerSize);
        setTotalAllocationPerSize(data.totalAllocationPerSize);
        setTotal(data.total);
        await setAllocationData({
          ...allocationData,
          allocation_id: data?.allocation_id,
          poId: data?.po_id,
          poNo: data?.PurchaseOrders.po_no,
          style_id: data?.styles.style_id,
          styleNo: data?.styles.style_no,
        });
      }
    }
  }

  const handleSizeChange = (value: string, index: number) => {
    setSampleArr(sampleArr.map((item, i) => i === index ? value : item));
    setReceivedQty(receivedQty.map((item: SizeQuantity, i: number) => (i === index) ? { ...item, size: value } : item));
    let arr: any = [];
    Array.from(Object.values(sizeData)).forEach((item: any, i: number) => {
      let temp = item.map((sz: SizeQuantity, j: number) => j === index ? { ...sz, size: value } : sz);
      arr.push(temp);
    });
    setSizeData(arr);
  }


  return (
    <div>
      <Homepage setNavbarOpened={setNavbarOpened} />
      {popUp && (<Modal style={{ height: '100%', zIndex: 100 }} opened={opened} onClose={() => setPopUp(false)} title="Modify confirmation" centered>
        <Flex
          direction='column'
          justify='center'
          mih={220}
          gap='md'
        >
          <Text
            variant="gradient"
            gradient={{ from: 'black', to: 'red', deg: 20 }}
            sx={{ fontFamily: 'Greycliff CF, sans-serif' }}
            ta="center"
            fz="xl"
            fw={700}
          >
            Are you sure you want to change the data?
          </Text>
          <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', gap: '40px' }}>
            <Button style={{ marginTop: '25px' }} size="md" color="red" onClick={saveData} uppercase>Yes</Button>
            <Button style={{ marginTop: '25px' }} size="md" onClick={() => setPopUp(false)} uppercase>No</Button>
          </div>
        </Flex>
      </Modal>)}
      <div style={{ marginTop: "80px", display: "flex", justifyContent: "center", alignContent: "center" }}>
        <Flex
          direction="column"
          gap="lg"
          justify="center"
          align="center"
          wrap="wrap"
          style={{ width: "100vw" }}
        >
          <div>
            <h1 className="h1-modify">Modify Data</h1>
          </div>
          <Grid gutter="lg" gutterXl={"lg"} align="center" style={{ width: "50vw", display: "flex", justifyContent: "center", alignContent: "center" }}>
            <Col span={4}>
              <Select
                label="PO No"
                placeholder="Enter PO Number"
                data={poNoList}
                searchable
                onChange={handlePoNoChange}
                style={{ width: "100%" }}
              />
            </Col>
            <Col span={4}>
              <Select
                label="Style No"
                placeholder="Enter Style Number"
                data={styleList}
                searchable
                onChange={handleStyleChange}
                style={{ width: "100%" }}
              />
            </Col>
            <Col span={2}>
              <div style={{ marginTop: "20px" }}>
                <Button uppercase color="" onClick={() => setPopUp(true)} style={{ width: "100%" }}>SAVE</Button>
              </div>
            </Col>
          </Grid>
          <Grid gutter="md">
            <Col>
              {allocationData && (<Paper shadow="md" radius="lg" p="xl" withBorder>
                <Text variant="text" style={{ fontFamily: 'Greycliff CF, sans-serif', textAlign: "center" }} c="dimmed" fz={"xl"} fw={700}>Allocation</Text>
                {sizeData && (<div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', gap: '40px' }}>
                  <Grid gutter="lg" columns={12} gutterXl={"lg"} align="center" style={{ width: "50vw", display: "flex", justifyContent: "center", alignContent: "center" }}>

                    <Col span={4}>
                      <TextInput
                        label="Allocation ID"
                        placeholder="Enter Allocation ID"
                        value={allocationData?.allocation_id}
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col span={4}>
                      <TextInput
                        label="PO No"
                        placeholder="Enter PO Number"
                        value={allocationData?.poNo}
                        onChange={(e) => handleAllocationChange("poNo", e.target.value)}
                        style={{ width: "100%" }}
                      />

                    </Col>
                    <Col span={4}>
                      <TextInput
                        label="Style No"
                        placeholder="Enter Style Number"
                        value={allocationData?.styleNo}
                        onChange={(e) => handleAllocationChange("styleNo", e.target.value)}
                        style={{ width: "100%" }}
                      />

                    </Col>
                  </Grid>
                </div>)}
                {allocationData && (<div style={{ borderRadius: "5px", padding: "30px", display: "flex", justifyContent: "center", alignContent: "center" }}>
                  <Flex
                    mih={50}
                    gap="sm"
                    justify="left"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >

                    <TextInput
                      placeholder='Add size'
                      // style={{ width: '35%' }}
                      onChange={(e) => setNewStyle((prevStyle: SizeQuantity) => ({
                        ...prevStyle,
                        size: e.target.value,
                        quantity: prevStyle.quantity, // or any other default value
                      }))} />
                    <TextInput
                      placeholder='RCV Quantity'
                      // style={{ width: '35%' }}
                      onChange={(e) => setNewStyle((prevStyle: SizeQuantity) => ({
                        ...prevStyle,
                        size: prevStyle.size,
                        quantity: parseInt(e.target.value), // or any other default value
                      }))}
                    />
                    <Button style={{ display: "flex", justifyContent: "center", alignContent: "center", fontWeight: "bold" }}
                      onClick={() => addSizeQty(newStyle.size, newStyle.quantity || 0)}
                    >+ Add size</Button>
                    <Select
                      data={sampleArr}
                      placeholder='Delete size'
                      searchable
                      onChange={(value: string | null) => value && setSelectedSize(value)}
                    />
                    <Button color='red' onClick={deleteSizeQty}>Delete size</Button>
                  </Flex>
                </div>)}
                {allocationData && (<div style={{ boxShadow: " 1px 1px 1px 2px #ccc", borderRadius: "5px", padding: "30px", display: "flex", justifyContent: "center", alignContent: "center" }}>
                  <Text variant="text" style={{ fontFamily: 'Greycliff CF, sans-serif', textAlign: "center" }} c="dimmed" fz={"xl"} fw={700}>Existing Sizes: </Text>
                  <Grid gutter="lg" columns={12} gutterXl={"lg"} align="center" style={{ width: "50vw", display: "flex", justifyContent: "center", alignContent: "center" }}>
                    {/* <div><h4>Size</h4></div> */}
                    <Col span={12}>
                      <Flex
                        mih={50}
                        gap="sm"
                        justify="center"
                        align="flex-start"
                        direction="row"
                        wrap="wrap"
                      >
                        {sampleArr && sampleArr.map((item: string, index: number) => (
                          <TextInput
                            // key={index}
                            placeholder="Size"
                            value={item}
                            onChange={(e) => handleSizeChange(e.target.value, index)}
                            style={{ width: "20%" }}
                          />
                        ))}
                      </Flex>
                    </Col>
                  </Grid>
                </div>)}
              </Paper>)}
            </Col>
          </Grid>
        </Flex>
      </div>
    </div>

  );
};

export default ModifyAlloc;


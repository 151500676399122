// import { Table, Paper, Text, Button, Col, Grid, Group, Input, Modal, TextInput, } from '@mantine/core';
// import React, { useEffect, useState } from 'react';
// import { Pagination } from '@mantine/core';
// import "./AllocTable.css"

// import Homepage from '../components/Navigation/parentHome';
// import { createUseStyles } from 'react-jss';
// import { IconFilter, IconFilterX } from '@tabler/icons-react';
// //import HomePage from './parent';
// import pdfMake from "pdfmake/build/pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
// import PDFGenerator from './transferReport';
// import { DataTable } from 'mantine-datatable';
// import { useLocation } from 'react-router-dom';

// pdfMake.vfs = pdfFonts.pdfMake.vfs;

// interface SizeQuantity {
//   size: string;
//   quantity: number;
//   sku: string;
// }

// interface StyleData {

//   supplier_name: string;
//   style_id: number;
//   style_no: string;
//   description: string;
//   color: string;
//   cost: number;
//   msrp: number;
//   notes: string;
//   total_qty: number;
//   location: string;
//   first_name: string;
// }

// interface AllocationData {
//   allocation_id: number;
//   storeName: string[];
//   style_id: number;
//   supplierName: string;
//   poNo: string;
//   status: boolean[];
//   initial: boolean[];
//   skuNumbers: string[];
//   sizeQuantities: SizeQuantity[][];
//   styles: StyleData;
//   PurchaseOrders: PurchaseOrderData;
// }

// interface PurchaseOrderData {
//   po_id: string;
//   po_no: string;  
// }

// interface PoNoData {
//   po_id?: string;
//   poNo: string;
//   status: string;
// }

// const initialFilterState = {
//   po_id: '',
//   storeName: '',
//   supplierName: '',
//   styleNo: '',
//   poNo: ''
// };

// const useStyles = createUseStyles({
//   fixedBar: {
//     position: 'fixed',
//     bottom: 0,
//     width: '100%',
//     background: '#ECF0F1 ',
//     padding: '10px 0',
//     boxShadow: '0px -2px 10px rgba(0,0,0,0.1)',
//     height: '30px',
//   },

// });



// const AllocTable: React.FC = () => {
//   const classes = useStyles();
//   const [searchTerm, setSearchTerm] = useState<string>("");
//   //const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
//   const [filter, setFilter] = useState(initialFilterState);
//   const [allocations, setAllocations] = useState<AllocationData[]>([]);
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   const [navbarOpened, setNavbarOpened] = useState(false);
//   const [expandedRowNo, setexpandedRowNo] = useState<string | null>(null);
//   const location = useLocation();

//   // Access the state object from the location
//   const [rowData, setRowData]= useState<PoNoData | null>(location.state?.parsedRowData); // PoNoData = location.state?.parsedRowData;



//   const resetFilter = () => {
//     setFilter(initialFilterState);
//     setSearchTerm("");
//     setTempFilter(initialFilterState);
//     setPage(1);
//     setRowData(null);
//   };

//   const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
//   const [tempFilter, setTempFilter] = useState(initialFilterState);
//   const PAGE_SIZES = [10, 15, 17, 20];
//   const [pageSize, setPageSize] = useState(PAGE_SIZES[2]);
//   const [page, setPage] = useState(1);

//   const toggleRow = (style_no: string, poNo: string) => {
//     setexpandedRowNo(prev => (prev === style_no + poNo ? null : style_no + poNo));
//   };


//   function capitalizeFirstLetter(str: string): string {
//     return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
//   }

//   const filteredData = allocations.map((row) => ({
//     ...row,
//     styles: {
//       ...row.styles,
//       description: capitalizeFirstLetter(row.styles.description),
//     }
//   }))
//     .filter((row) =>
//       (filter.storeName ? row.storeName.some(store => store.toUpperCase() === filter.storeName.toUpperCase()) : true) &&
//       (filter.supplierName ? row.supplierName.toUpperCase() === filter.supplierName.toUpperCase() : true) &&
//       (filter.poNo ? row.PurchaseOrders.po_no.toUpperCase() === filter.poNo.toUpperCase() : true) &&
//       (filter.styleNo ? row.styles.style_no.toUpperCase() === filter.styleNo.toUpperCase() : true) &&
//       (searchTerm ? (
//         row.storeName?.some(store => store.includes(searchTerm.toLowerCase()) ||
//           row.supplierName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           row.styles.style_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           row.poNo?.toLowerCase().includes(searchTerm.toLowerCase())
//         )) : true) &&
//       (rowData?.po_id ? row.PurchaseOrders.po_id.toString() === rowData?.po_id?.toString() : true)
//     );

//   const applyFilter = () => {
//     setPage(1);
//     setFilterModalOpen(false);
//     setFilter(tempFilter);
//   }
//   useEffect(() => {
//     applyFilter();
//   }, [searchTerm]);

//   useEffect(() => {
//     fetch('/allocation/')
//       .then(response => response.json())
//       .then(jsonData => {
//         setAllocations(jsonData);
//       })
//       .catch(error => console.error('Error fetching data:', error));
//   }, []);
//   const paginatedData = filteredData.slice((page - 1) * pageSize, page * pageSize);

//   return (
//     <>
//       <Homepage setNavbarOpened={setNavbarOpened} />
//       <Paper shadow='xs' className="alloc-table">
//         <div className="table-wrapper">
//           <DataTable
//             withBorder
//             withColumnBorders
//             striped
//             highlightOnHover
//             columns={[
//               { accessor: 'styles.style_no', title: 'Style No', titleClassName: 'expanded-vertical-table' },
//               { accessor: 'styles.description', title: 'Description', titleClassName: 'expanded-vertical-table' },
//               { accessor: 'styles.color', title: 'Color', titleClassName: 'expanded-vertical-table' },
//               {
//                 accessor: 'styles.cost',
//                 title: 'Cost',
//                 titleClassName: 'expanded-vertical-table',
//                 render: (value) => `$${value.styles.cost}` // Assuming the cost is a number and you want it formatted as currency
//               },
//               {
//                 accessor: 'styles.msrp',
//                 title: 'MSRP',
//                 titleClassName: 'expanded-vertical-table',
//                 render: (value) => `$${value.styles.msrp}` // Assuming the MSRP is a number and you want it formatted as currency
//               },
//               { accessor: 'supplierName', title: 'Supplier Name', titleClassName: 'expanded-vertical-table' },
//               { accessor: 'PurchaseOrders.po_no', title: 'Po No', titleClassName: 'expanded-vertical-table' },
//               { accessor : 'styles.notes', 
//                 title: 'Notes', 
//                 titleClassName: 'expanded-vertical-table', 
//                 render: (value) => `${value.styles.notes}` },
//             ]}
//             records={paginatedData}
//             idAccessor={'allocation_id'}
//             onRowClick={(record) => toggleRow(record.styles.style_no, record.poNo)}
//             rowExpansion={{
//               allowMultiple: true,
//               content: ({ record }) => {
//                 if (record.styles.style_no + record.poNo === expandedRowNo) {
//                   return (
//                     <div>
//                       <Table>
//                         <thead>
//                           <td className='expanded-vertical-table-head'> Stores</td>
//                           <td className='expanded-vertical-table-head'>Size</td>
//                           <td className='expanded-vertical-table-head'>Quantity</td>
//                           <td className='expanded-vertical-table-head'>Total</td>
//                           <td className='expanded-vertical-table-head'>Status</td>
//                           <td className='expanded-vertical-table-head'>Initial</td>
//                         </thead>
//                         <tbody>
//                           {record.storeName.map((item, index) => (
//                             record.sizeQuantities[index].map((sq, jIndex) => (
//                               <tr key={`${record.allocation_id}-${record.styles.style_no}-${index}-${jIndex}`}>
//                                 <td className='expanded-vertical-table-data'>{jIndex === 0 ? item : ''}</td>
//                                 <td className='expanded-vertical-table-data'>{sq.size}</td>
//                                 <td className='expanded-vertical-table-data'>{sq.quantity}</td>
//                                 <td className='expanded-vertical-table-data'>{jIndex === 0 ? record.sizeQuantities[index].reduce((sum, sq) => sum + sq.quantity, 0) : ''}</td>
//                                 <td className='expanded-vertical-table-data'>{jIndex === 0 ? record.status[0] ? 'Allocated' : 'Pending' : ''}</td>
//                                 <td className='expanded-vertical-table-data'>{jIndex === 0 ? record.initial[index] : ''}</td>
//                               </tr>
//                             ))
//                           ))}
//                         </tbody>
//                       </Table>
//                     </div>
//                   )
//                 }
//               },
//             }}
//           />
//         </div>
//         <div className={classes.fixedBar}>
//           <Grid gutter="md" >
//             <Col span={4} style={{ marginLeft: '20px', justifyContent: 'center', alignItems: 'center' }}>

//               <TextInput
//                 placeholder="Search by Style No"
//                 value={searchTerm}
//                 onChange={(event) => setSearchTerm(event.currentTarget.value)}
//               />
//             </Col>
//             <Col span={4} className='pagination'>
//               <Button variant="subtle" size='xs' style={{ marginTop: '3px', border: '1px solid black' }} color="dark" onClick={() => page > 1 ? setPage(page - 1) : setPage(1)}>Prev</Button>
//               <Button variant="subtle" style={{ fontWeight: 'bold', fontSize: '18px' }} size='sm' color="dark" disabled={true} >{page}</Button>
//               <Button variant="subtle" size='xs' style={{ marginTop: '3px', border: '1px solid black' }} color="dark" onClick={() => { Math.ceil(filteredData.length / pageSize) > page ? setPage(page + 1) : setPage(page) }}>Next</Button>
//             </Col>
//             <Col span={1}>
//               <Button leftIcon={<IconFilter />} onClick={() => setFilterModalOpen(true)} variant="subtle" color="dark" uppercase>Filter</Button>
//               <Modal

//                 opened={filterModalOpen}
//                 onClose={() => setFilterModalOpen(false)}
//                 centered
//               >
//                 <Group position='center'>
//                   <Input
//                     placeholder="Filter by Style No"
//                     value={tempFilter.styleNo}
//                     onChange={(event) => setTempFilter({ ...tempFilter, styleNo: event.currentTarget.value.toUpperCase() })}
//                   />
//                   <Input
//                     placeholder="Filter by Supplier Name"
//                     value={tempFilter.supplierName}
//                     onChange={(event) => setTempFilter({ ...tempFilter, supplierName: event.currentTarget.value.toUpperCase() })}
//                   />
//                   <Input
//                     placeholder="Filter by PO No"
//                     value={tempFilter.poNo}
//                     onChange={(event) => setTempFilter({ ...tempFilter, poNo: event.currentTarget.value.toUpperCase() })}
//                   />
//                   <Input
//                     placeholder="Filter by Store Name"
//                     value={tempFilter.storeName}
//                     onChange={(event) => setTempFilter({ ...tempFilter, storeName: event.currentTarget.value.toUpperCase() })}
//                   />
//                   <Button onClick={applyFilter}>Apply</Button>
//                 </Group>
//               </Modal>
//             </Col>
//             <Col span={1}>
//               <Button
//                 leftIcon={<IconFilterX />}
//                 title="Reset Filter"
//                 onClick={resetFilter}
//                 variant="subtle" color="dark"
//               />
//             </Col>
//             <Col span={1}><PDFGenerator data={filteredData} filter={filter} />
//             </Col>

//           </Grid>
//         </div>
//       </Paper>
//     </>
//   );
// };

// export default AllocTable;
import { Table, Paper, Text, Button, Col, Grid, Group, Input, Modal, TextInput, } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { Pagination } from '@mantine/core';
import "./AllocTable.css"

import Homepage from '../components/Navigation/parentHome';
import { createUseStyles } from 'react-jss';
import { IconFilter, IconFilterX } from '@tabler/icons-react';
//import HomePage from './parent';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PDFGenerator from './transferReport';
import { DataTable } from 'mantine-datatable';
import { useLocation } from 'react-router-dom';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface SizeQuantity {
  size: string;
  quantity: number;
  sku: string;
}

interface StyleData {

  supplier_name: string;
  style_id: number;
  style_no: string;
  description: string;
  color: string;
  cost: number;
  msrp: number;
  notes: string;
  total_qty: number;
  location: string;
  first_name: string;
}

interface AllocationData {
  allocation_id: number;
  storeName: string[];
  style_id: number;
  supplierName: string;
  poNo: string;
  status: boolean[];
  initial: boolean[];
  skuNumbers: string[];
  first_name: string;
  sizeQuantities: SizeQuantity[][];
  styles: StyleData;
  PurchaseOrders: PurchaseOrderData;
}

interface PurchaseOrderData {
  po_id: string;
  po_no: string;  
}

interface PoNoData {
  po_id?: string;
  poNo: string;
  status: string;
}

const initialFilterState = {
  po_id: '',
  storeName: '',
  supplierName: '',
  styleNo: '',
  poNo: ''
};

const useStyles = createUseStyles({
  fixedBar: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    background: '#ECF0F1 ',
    padding: '10px 0',
    boxShadow: '0px -2px 10px rgba(0,0,0,0.1)',
    height: '30px',
  },

});



const AllocTable: React.FC = () => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState<string>("");
  //const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
  const [filter, setFilter] = useState(initialFilterState);
  const [allocations, setAllocations] = useState<AllocationData[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [navbarOpened, setNavbarOpened] = useState(false);
  const [expandedRowNo, setexpandedRowNo] = useState<string | null>(null);
  const location = useLocation();

  // Access the state object from the location
  const [rowData, setRowData]= useState<PoNoData | null>(location.state?.parsedRowData); // PoNoData = location.state?.parsedRowData;



  const resetFilter = () => {
    setFilter(initialFilterState);
    setSearchTerm("");
    setTempFilter(initialFilterState);
    setPage(1);
    setRowData(null);
  };

  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [tempFilter, setTempFilter] = useState(initialFilterState);
  const PAGE_SIZES = [10, 15, 17, 20];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2]);
  const [page, setPage] = useState(1);

  const toggleRow = (style_no: string, poNo: string) => {
    setexpandedRowNo(prev => (prev === style_no + poNo ? null : style_no + poNo));
  };


  function capitalizeFirstLetter(str: string): string {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const filteredData = allocations.map((row) => ({
    ...row,
    styles: {
      ...row.styles,
      description: capitalizeFirstLetter(row.styles.description),
    }
  }))
    .filter((row) =>
      (filter.storeName ? row.storeName.some(store => store.toUpperCase() === filter.storeName.toUpperCase()) : true) &&
      (filter.supplierName ? row.supplierName.toUpperCase() === filter.supplierName.toUpperCase() : true) &&
      (filter.poNo ? row.PurchaseOrders.po_no.toUpperCase() === filter.poNo.toUpperCase() : true) &&
      (filter.styleNo ? row.styles.style_no.toUpperCase() === filter.styleNo.toUpperCase() : true) &&
      (searchTerm ? (
        row.storeName?.some(store => store.includes(searchTerm.toLowerCase()) ||
          row.supplierName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.styles.style_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.poNo?.toLowerCase().includes(searchTerm.toLowerCase())
        )) : true) &&
      (rowData?.po_id ? row.PurchaseOrders.po_id.toString() === rowData?.po_id?.toString() : true)
    );

  const applyFilter = () => {
    setPage(1);
    setFilterModalOpen(false);
    setFilter(tempFilter);
  }
  useEffect(() => {
    applyFilter();
  }, [searchTerm]);

  useEffect(() => {
    fetch('/allocation/')
      .then(response => response.json())
      .then(jsonData => {
        setAllocations(jsonData);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  const paginatedData = filteredData.slice((page - 1) * pageSize, page * pageSize);

  return (
    <>
      <Homepage setNavbarOpened={setNavbarOpened} />
      <Paper shadow='xs' className="alloc-table">
        <div className="table-wrapper">
          <DataTable
            withBorder
            withColumnBorders
            striped
            highlightOnHover
            columns={[
              { accessor: 'styles.style_no', title: 'Style No', titleClassName: 'expanded-vertical-table' },
              { accessor: 'styles.description', title: 'Description', titleClassName: 'expanded-vertical-table' },
              { accessor: 'styles.color', title: 'Color', titleClassName: 'expanded-vertical-table' },
              {
                accessor: 'styles.cost',
                title: 'Cost',
                titleClassName: 'expanded-vertical-table',
                render: (value) => `$${value.styles.cost}` // Assuming the cost is a number and you want it formatted as currency
              },
              {
                accessor: 'styles.msrp',
                title: 'MSRP',
                titleClassName: 'expanded-vertical-table',
                render: (value) => `$${value.styles.msrp}` // Assuming the MSRP is a number and you want it formatted as currency
              },
              { accessor: 'supplierName', title: 'Supplier Name', titleClassName: 'expanded-vertical-table' },
              { accessor: 'PurchaseOrders.po_no', title: 'Po No', titleClassName: 'expanded-vertical-table' },
              { accessor : 'styles.notes', 
                title: 'Notes', 
                titleClassName: 'expanded-vertical-table', 
                render: (value) => `${value.styles.notes}` },
                { accessor: 'first_name', title: 'Leader', titleClassName: 'expanded-vertical-table' },
              ]}
            records={paginatedData}
            idAccessor={'allocation_id'}
            onRowClick={(record) => toggleRow(record.styles.style_no, record.poNo)}
            rowExpansion={{
              allowMultiple: true,
              content: ({ record }) => {
                if (record.styles.style_no + record.poNo === expandedRowNo) {
                  return (
                    <div>
                      <Table>
                        <thead>
                          <td className='expanded-vertical-table-head'> Stores</td>
                          <td className='expanded-vertical-table-head'>Size</td>
                          <td className='expanded-vertical-table-head'>Quantity</td>
                          <td className='expanded-vertical-table-head'>Total</td>
                          <td className='expanded-vertical-table-head'>Status</td>
                          <td className='expanded-vertical-table-head'>Initial</td>
                        </thead>
                        <tbody>
                          {record.storeName.map((item, index) => (
                            record.sizeQuantities[index].map((sq, jIndex) => (
                              <tr key={`${record.allocation_id}-${record.styles.style_no}-${index}-${jIndex}`}>
                                <td className='expanded-vertical-table-data'>{jIndex === 0 ? item : ''}</td>
                                <td className='expanded-vertical-table-data'>{sq.size}</td>
                                <td className='expanded-vertical-table-data'>{sq.quantity}</td>
                                <td className='expanded-vertical-table-data'>{jIndex === 0 ? record.sizeQuantities[index].reduce((sum, sq) => sum + sq.quantity, 0) : ''}</td>
                                <td className='expanded-vertical-table-data'>{jIndex === 0 ? record.status[0] ? 'Allocated' : 'Pending' : ''}</td>
                                <td className='expanded-vertical-table-data'>{jIndex === 0 ? record.initial[index] : ''}</td>
                              </tr>
                            ))
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  )
                }
              },
            }}
          />
        </div>
        <div className={classes.fixedBar}>
          <Grid gutter="md" >
            <Col span={4} style={{ marginLeft: '20px', justifyContent: 'center', alignItems: 'center' }}>

              <TextInput
                placeholder="Search by Style No"
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.currentTarget.value)}
              />
            </Col>
            <Col span={4} className='pagination'>
              <Button variant="subtle" size='xs' style={{ marginTop: '3px', border: '1px solid black' }} color="dark" onClick={() => page > 1 ? setPage(page - 1) : setPage(1)}>Prev</Button>
              <Button variant="subtle" style={{ fontWeight: 'bold', fontSize: '18px' }} size='sm' color="dark" disabled={true} >{page}</Button>
              <Button variant="subtle" size='xs' style={{ marginTop: '3px', border: '1px solid black' }} color="dark" onClick={() => { Math.ceil(filteredData.length / pageSize) > page ? setPage(page + 1) : setPage(page) }}>Next</Button>
            </Col>
            <Col span={1}>
              <Button leftIcon={<IconFilter />} onClick={() => setFilterModalOpen(true)} variant="subtle" color="dark" uppercase>Filter</Button>
              <Modal

                opened={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
                centered
              >
                <Group position='center'>
                  <Input
                    placeholder="Filter by Style No"
                    value={tempFilter.styleNo}
                    onChange={(event) => setTempFilter({ ...tempFilter, styleNo: event.currentTarget.value.toUpperCase() })}
                  />
                  <Input
                    placeholder="Filter by Supplier Name"
                    value={tempFilter.supplierName}
                    onChange={(event) => setTempFilter({ ...tempFilter, supplierName: event.currentTarget.value.toUpperCase() })}
                  />
                  <Input
                    placeholder="Filter by PO No"
                    value={tempFilter.poNo}
                    onChange={(event) => setTempFilter({ ...tempFilter, poNo: event.currentTarget.value.toUpperCase() })}
                  />
                  <Input
                    placeholder="Filter by Store Name"
                    value={tempFilter.storeName}
                    onChange={(event) => setTempFilter({ ...tempFilter, storeName: event.currentTarget.value.toUpperCase() })}
                  />
                  <Button onClick={applyFilter}>Apply</Button>
                </Group>
              </Modal>
            </Col>
            <Col span={1}>
              <Button
                leftIcon={<IconFilterX />}
                title="Reset Filter"
                onClick={resetFilter}
                variant="subtle" color="dark"
              />
            </Col>
            <Col span={1}><PDFGenerator data={filteredData} filter={filter} />
            </Col>

          </Grid>
        </div>
      </Paper>
    </>
  );
};

export default AllocTable;
import pdfMake from "pdfmake/build/pdfmake";
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import React, { useState } from 'react';
import { Button } from "@mantine/core";

interface PrintAllocationPaperProps {
    props:{ 
        allocationData: any;
        styleData: any;
        storeTotals: number[];
        overstockTotal: number;
        styleNo: string;
        totalAllocation: number;
        poNo: string;
     }
}
const PrintAllocationPaper: React.FC<PrintAllocationPaperProps> = ({props}) => {
    const {allocationData, styleData, storeTotals,overstockTotal, styleNo, totalAllocation, poNo } = props;

    const extractSizesFromAllocationData = (allocationData: any): string[] => {
        if (!allocationData || !allocationData.sizeQuantities) {
            console.error("Invalid allocation data");
            return [];
        }

        const sizeSet = new Set<string>();
        allocationData.sizeQuantities.forEach((sizeQuantities: any[]) => {
            sizeQuantities.forEach((sq: any) => {
                if (sq && sq.size) {
                    sizeSet.add(sq.size);
                }
            });
        });

        return Array.from(sizeSet);
    };
    const createSizeSpecificTable = (size: string, allocationData: any): (string | number)[][] => {
        if (!allocationData || !allocationData.sizeQuantities) {
          console.error("Invalid allocation data");
          return [];
        }
    
        const headers = [`${styleNo}`, `(Size: ${size})`];
        const styledHeader = headers.map(header => ({ text: header, style: 'tableHeader' }));
        const body: any[][] = [styledHeader];
    
        allocationData.storeName.forEach((store: string, index: number) => {
          if (!allocationData.sizeQuantities[index]) {
            console.error(`Missing size quantities for store: ${store}`);
            body.push([store, 'Data missing']);
            return;
          }
    
          const sizeQuantity = allocationData.sizeQuantities[index].find((sq: any) => sq.size === size);
          if (!sizeQuantity) {
            // console.log(`No quantity found for size ${size} in store ${store}`);
            body.push([store, '0']); // Default to '0' if no sizeQuantity found
          } else {
            body.push([store, sizeQuantity.quantity.toString()]);
          }
        });
        body.push([{ text: 'Total Allocation', bold: true }, { text: allocationData.totalAllocationPerSize[allocationData.sizeQuantities[0].findIndex((sq: any) => sq.size === size)].toString(), bold: true }]);
        //body.push([{ text: 'Overstock', bold: true }, { text: allocationData.overstockPerSize[allocationData.sizeQuantities[0].findIndex((sq: any) => sq.size === size)].toString(), bold: true }]);
        return body;
      };
    
    const createThreeByThreeGrid = (chunk: string[], allocationData: any): Content => {
        const tableLayout = {
          hLineWidth: () => 1,
          vLineWidth: () => 1,
          hLineColor: () => '#d0d0d0',
          vLineColor: () => '#d0d0d0',
          paddingLeft: () => 3,
          paddingRight: () => 3,
          paddingTop: () => 1,
          paddingBottom: () => 1,
        };
        let gridRows: Content[] = [];
    
        for (let rowIndex = 0; rowIndex < 2; rowIndex++) {
          let rowColumns: Content[] = [];
          for (let columnIndex = 0; columnIndex < 3; columnIndex++) {
            const sizeIndex = rowIndex * 3 + columnIndex;
            if (sizeIndex < chunk.length) {
              const sizeTable = createSizeSpecificTable(chunk[sizeIndex], allocationData);
              rowColumns.push({
                table: {
                  body: sizeTable,
                  widths: ['50%', 'auto'],
                },
                margin: [0, 0, 8, 15],
                fontSize: 11,
                layout: tableLayout
              });
            } else {
              rowColumns.push({ text: '' });
            }
          }
          gridRows.push({ columns: rowColumns, columnGap: 4 });
    
          // Add a dotted line after each row except the last one
          if (rowIndex < 2) {
            gridRows.push({
              canvas: [
                {
                  type: 'line',
                  x1: 0, y1: 5,
                  x2: 520, y2: 6, // Adjust the length as needed
                  lineWidth: 1,
                  dash: { length: 4 },
                  lineColor: '#000000'
                }
              ],
              margin: [0, 3, 0, 3] // Adjust margin as needed
            });
          }
        }
    
    
        return {
          unbreakable: true,
          stack: gridRows
        };
      };
    
      const chunkSizes = (sizes: string[], chunkSize: number): string[][] => {
        const chunks: string[][] = [];
        for (let i = 0; i < sizes.length; i += chunkSize) {
          const chunk = sizes.slice(i, i + chunkSize);
          chunks.push(chunk);
          // console.log(`Created chunk ${chunks.length}:`, chunk);
        }
        // console.log(`Total chunks created: ${chunks.length}`);
        return chunks;
      };
    
    const formatStyleDataForPdf = (styleData: any): Content[] => {
        // Assuming styleData contains: supplier_name, style_no, description, color, cost, msrp, and imageData
        return [

            {
                text: 'SVP SPORTS - DISTRIBUTION CENTER',
                fontSize: 24,
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 10]
            },
            { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 700, y2: 5, lineWidth: 0.5, lineColor: '#d0d0d0' }] },
            {
                columns: [
                    { text: `#PO: ${poNo}`, style: 'header' },
                    { text: `Supplier: ${styleData.supplier_name}`, style: 'header' },
                ],
                columnGap: 10,
            },
            { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 700, y2: 5, lineWidth: 0.5, lineColor: '#d0d0d0' }] },
            {
                columns: [
                    { text: `StyleNo: ${styleData.style_no}`, style: 'header' },
                ],
                columnGap: 10,
            },
            { text: `Description: ${styleData.description}`, style: 'subheader' },
            { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 700, y2: 5, lineWidth: 0.5, lineColor: '#d0d0d0' }] },
            {
                columns: [
                    { text: `Color: ${styleData.color}`, style: 'subheader' },
                    { text: `Cost: ${styleData.cost}`, style: 'subheader' },
                    { text: `MSRP: ${styleData.msrp}`, style: 'subheader' },
                ],
                columnGap: 5,
            },
            { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 700, y2: 5, lineWidth: 0.5, lineColor: '#d0d0d0' }] },



        ];
    };

    const formatAllocationDataForPdf = (allocationData: any): Content => {
        const tableBody: any[] = [];
    
    
    
        // Custom styling for headers
        const headerCellStyle = {
          fillColor: '#f2f2f2', // light gray background
          bold: true,
          alignment: 'center',
          margin: [5, 5, 5, 5]
        };
        // Add headers with custom style
        tableBody.push(
          [{ text: 'Store Name', style: headerCellStyle },
          ...allocationData.sizeQuantities[0].map((sq: any) => ({ text: sq.size, style: headerCellStyle })),
          { text: 'Total', style: headerCellStyle },
          { text: 'Initials', style: headerCellStyle }]
        );
    
        // Add RCV QTY row
        tableBody.push(['RCV QTY', ...allocationData.receivedQty.map((qtyObj: any) => qtyObj.quantity), allocationData.total, '']);
    
    
        // Add data rows for each store
        allocationData.storeName.forEach((store: string, index: number) => {
          // Instead of using allocationData.initial[index], use an empty string for the Initials column
          const rowData = [store, ...allocationData.sizeQuantities[index].map((sq: any) => sq.quantity), storeTotals[index], ''];
          tableBody.push(rowData);
        });
    
        // Add Total Allocation row
        tableBody.push(['Total Allocation', ...allocationData.totalAllocationPerSize, totalAllocation, '']);
    
        // Add Overstock row
        tableBody.push(['Overstock', ...allocationData.overstockPerSize, overstockTotal, '']);
    
        return {
          table: {
            body: tableBody,
            widths: ['auto', ...new Array(allocationData.sizeQuantities[0].length).fill('*'), 'auto', 'auto'], // Set column widths
    
          },
          layout: {
            hLineWidth: () => 1,
            vLineWidth: () => 1,
            hLineColor: () => '#d0d0d0',
            vLineColor: () => '#d0d0d0',
            paddingLeft: () => 1,
            paddingRight: () => 1,
            paddingTop: () => 1,
            paddingBottom: () => 1,
          },
          style: {
            fontSize: 10,
            alignment: 'center'
          },
    
        };
      };
        const generatePortraitPDF = async (): Promise<void> => {
            try {
                if (!allocationData) {
                    console.error("No allocation data available");
                    return;
                }

                const sizes = extractSizesFromAllocationData(allocationData);
                // console.log("Extracted sizes for portrait PDF:", sizes);

                if (sizes.length === 0) {
                    console.error("No sizes extracted from allocation data");
                    return;
                }

                // Adjust the chunk size to 1 to create a chunk for each size
                const sizeChunks = chunkSizes(sizes, 6);
                if (sizeChunks.length === 0) {
                    console.error("Size chunks are empty");
                    return;
                }

                let portraitContent: Content[] = [];
                sizeChunks.forEach(chunk => {
                    const gridPage = createThreeByThreeGrid(chunk, allocationData);
                    portraitContent.push({
                        pageOrientation: 'portrait',
                        stack: [gridPage],
                        style: 'sizeTable'
                    });
                });

                const docDefinition: TDocumentDefinitions = {
                    content: portraitContent,
                    pageSize: 'LETTER',
                    pageOrientation: 'portrait',

                    styles: {
                        sizeTable: {
                            fontSize: 10,
                            margin: [5, 5, 5, 5]
                        },
                        tableHeader: {
                            fillColor: '#f2f2f2',
                            bold: true,
                            alignment: 'center',
                        }
                    }
                };

                pdfMake.createPdf(docDefinition).open();
            } catch (error) {
                console.error("Error in generatePortraitPDF:", error);
            }
        };

        const generatePDFs = () => {
            const styleDataContent = formatStyleDataForPdf(styleData);
            const allocationDataContent = formatAllocationDataForPdf(allocationData);

            const docDefinition: TDocumentDefinitions = {
                content: [...styleDataContent, allocationDataContent],
                pageSize: 'LETTER',
                pageOrientation: 'landscape',
                styles: {
                    header: {
                        fontSize: 18,
                        bold: true,
                        alignment: 'center',
                        margin: [0, 0, 0, 10]
                    },
                    subheader: {
                        fontSize: 14,
                        bold: true,
                        alignment: 'center',
                        margin: [0, 10, 0, 5]
                    },
                    subheader1: {
                        fontSize: 14,
                        bold: true,
                        alignment: 'center',
                        margin: [0, 10, 0, 35]
                    }
                }
            };

            pdfMake.createPdf(docDefinition).open();
        };


    const downloadPDF =  () => {
        generatePDFs();
        generatePortraitPDF();
    };
    return (
        <div>
            <Button onClick={downloadPDF}>Allocation Paper</Button>
        </div>
    )
};

    export default PrintAllocationPaper;
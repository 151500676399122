import React, { useEffect, useState } from "react";
import Homepage from "../components/Navigation/parentHome";
import './AllocTable.css';
import { Button, Col, Flex, Grid, Modal, Select, Text, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

interface AllocationData {
    allocation_id: number;
    location: string;
    poNo: string;


}
const DeleteAlloc: React.FC = () => {
    const [navbarOpened, setNavbarOpened] = useState(false);
    const [poNoList, setPoNoList] = useState<string[]>([]);
    const [poNo, setPoNo] = useState('');
    const [entireData, setEntireData] = useState<any[] | null>(null);
    const [allocationData, setAllocationData] = useState<any | null>(null);
    const [styleList, setStyleList] = useState<string[]>([]);
    const [styleListId, setStyleListId] = useState<{styleNo: string, styleId: string}[]>([]);
    const [styleNo, setStyleNo] = useState<string>('');
    const [popUp, setPopUp] = useState(false);
    const [opened, { open, close }] = useDisclosure(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [poNoListId, setPoNoListId] = useState<{poNo: string, poId: string}[]>([]);


const fetchAllPoNo = async () => {
    
    try {
        fetch('/allocation/getPoNo', { credentials: 'include' })
            .then(response => response.json())
            .then(data => {
                setPoNoList(data.map((item: any) => item.poNo));
                setPoNoListId(data.map((item: any) => ({poNo: item.poNo, poId: item.po_id})));
            })
    } catch (error) {
        console.error(error);
    }

}

    useEffect(() => {
        fetchAllPoNo();
    }, [])
    const handlePoNoChange = (value: string) => {
        const po = poNoListId.find((item) => item.poNo === value);
        if (value !== null) {
            setPoNo(value);
            try {
                fetch(`/allocation/poNo/${po?.poId}`, { credentials: 'include' })
                    .then(response => response.json())
                    .then(data => {
                        setStyleList(data.map((item: any) => item.styles.style_no));
                        setStyleListId(data.map((item: any) => ({styleNo: item.styles.style_no, styleId: item.styles.style_id})));
                        setEntireData(data);
                    })
            } catch (error) {
                console.error(error);
            }
        }
    }

    const handleStyleChange = async (value: string) => {
        if (value !== null) {
            const style = styleListId.find((item) => item.styleNo === value);
            await setStyleNo(value);
            await setAllocationData(entireData?.find(item => item.styles.style_id === style?.styleId));
        }
    }

    const handleDelete = async () => {
        if (allocationData !== null) {
            const response = await fetch(`/allocation/${allocationData.allocation_id}`, { method: 'DELETE', credentials: 'include' });
            const data = await response.json();
            if (response.status === 200) {
                setStyleNo('');
                setPoNo('');
                setShowSuccessModal(true);
                setAllocationData(null);
                setStyleList([]);
                setPopUp(false);
            } else {
                alert(data.message);
            }
        }
        fetchAllPoNo();
    }

    return (
        <div>
            <Homepage setNavbarOpened={setNavbarOpened} />
            {popUp && (<Modal style={{ height: '100%', zIndex: 100 }} opened={opened} onClose={() => setPopUp(false)} title="Delete confirmation" centered>
                <Flex   
                    direction='column'
                    justify='center'
                    mih={220}
                    gap='md'
                >
                    <Text
                        variant="gradient"
                        gradient={{ from: 'black', to: 'red', deg: 20 }}
                        sx={{ fontFamily: 'Greycliff CF, sans-serif' }}
                        ta="center"
                        fz="xl"
                        fw={700}
                    >
                        Are you sure you want to delete this allocation?
                    </Text>
                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', gap: '40px' }}>
                        <Button style={{ marginTop: '25px' }} size="md" color="red" onClick={handleDelete} uppercase>Yes</Button>
                        <Button style={{ marginTop: '25px' }} size="md" onClick={() => setPopUp(false)} uppercase>No</Button>
                    </div>
                </Flex>
            </Modal>)}
            {showSuccessModal && (<Modal style={{ height: '100%', zIndex: 100 }} opened={showSuccessModal} onClose={() => setShowSuccessModal(false)} title="Message" centered>
            <Flex
                    direction='column'
                    justify='center'
                    mih={220}
                    gap='md'
                    style={{  display: "flex", justifyContent: "center", alignContent: "center" }}
                >
                <h2 style={{ textAlign: "center" }}>Success!</h2>
                <p style={{ textAlign: "center" }}>Data has been deleted successfully.</p>
                <Button size="md" uppercase onClick={() => setShowSuccessModal(false)}>Close</Button>
                </Flex>
            </Modal>)}
            <div style={{ marginTop: "80px", display: "flex", justifyContent: "center", alignContent: "center" }}>
                <Flex
                    direction="column"
                    gap="lg"
                    justify="center"
                    align="center"
                    wrap="wrap"
                    style={{ width: "100vw" }}
                >
                    <div>
                        <h1 className="h1-delete">Delete Allocation</h1>
                    </div>
                    <Grid gutter="lg" gutterXl={"lg"} align="center" style={{ width: "50vw", display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <Col span={4}>
                            <Select
                                label="PO No"
                                placeholder="Enter PO Number"
                                data={poNoList}
                                searchable
                                onChange={handlePoNoChange}
                                style={{ width: "100%" }}
                            />
                        </Col>
                        <Col span={4}>
                            <Select
                                label="Style No"
                                placeholder="Enter Style Number"
                                data={styleList}
                                searchable
                                onChange={handleStyleChange}
                                style={{ width: "100%" }}
                            />
                        </Col>
                        <Col span={2}>
                            <div style={{ marginTop: "20px" }}>
                                <Button uppercase color="red" onClick={() => setPopUp(true)} style={{ width: "100%" }}>Delete</Button>
                            </div>
                        </Col>
                    </Grid>
                    {allocationData && (<div style={{ marginTop: "20px", border: "2px solid black", boxShadow: "10px 10px 5px #ccc", borderRadius: "5px", padding: "30px", display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <Grid gutter="lg" columns={12} gutterXl={"lg"} align="center" style={{ width: "50vw", display: "flex", justifyContent: "center", alignContent: "center" }}>
                            <Col span={4}>
                                <TextInput
                                    label="Allocation ID"
                                    placeholder="Enter Allocation ID"
                                    value={allocationData?.allocation_id}
                                    style={{ width: "100%" }}
                                />
                                <TextInput
                                    label="Supplier Name"
                                    placeholder="Enter Supplier Name"
                                    value={allocationData?.styles.supplier_name}
                                    style={{ width: "100%" }}
                                />


                                <TextInput
                                    label="Color"
                                    placeholder="Enter Color"
                                    value={allocationData?.styles.color}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                            <Col span={4}>
                                <TextInput
                                    label="PO No"
                                    placeholder="Enter PO Number"
                                    value={allocationData?.PurchaseOrders?.po_no}
                                    style={{ width: "100%" }}
                                />

                                <TextInput
                                    label="Description"
                                    placeholder="Enter Description"
                                    value={allocationData?.styles.description}
                                    style={{ width: "100%" }}
                                />

                                <TextInput
                                    label="MSRP"
                                    placeholder="Enter MSRP"
                                    value={allocationData?.styles.msrp}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                            <Col span={4}>
                                <TextInput
                                    label="Style No"
                                    placeholder="Enter Style Number"
                                    value={allocationData?.styles.style_no}
                                    style={{ width: "100%" }}
                                />
                                <TextInput
                                    label="Location"
                                    placeholder="Location"
                                    value={allocationData?.location}
                                    style={{ width: "100%" }}
                                />
                                <TextInput
                                    label="Cost"
                                    placeholder="Enter Cost"
                                    value={allocationData?.styles.cost}
                                    style={{ width: "100%" }}
                                />
                            </Col>
                        </Grid>
                    </div>)}
                </Flex>
            </div>
        </div>
    );
};
export default DeleteAlloc
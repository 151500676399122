import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface ReceivedQty {
  size: string;
  quantity: number;
}

interface AllocationData {
  style_id: number;
  supplierName: string;
  receivedQty: ReceivedQty[];
  total: number;
  totalAllocationPerSize: number[];
  overstockPerSize: number[];
  poNo: string;
  skuNumbers: string[];
  styleQty_id: number;
  styles: StyleData;
}

interface ReceivedQty {
  size: string;
  quantity: number;

}

interface StyleData {

  supplier_name: string;
  style_no: string;
  description: string;
  color: string;
  cost: number;
  msrp: number;
  total_qty: number;
  location: string;
  first_name: string;
  receivedQty: ReceivedQty[];
}

// function capitalizeFirstLetter(str: string): string {
//   return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
// }
const createPdf = (data: AllocationData[], filter: any) => {
  const transformedData = data.map((allocation) => {
    return allocation.receivedQty.map((rq, rqIndex) => [
      {
        text: rqIndex === 0 ? allocation.styles.style_no : '',
        fillColor: rqIndex === 0 ? '#ebebeb' : null,
        style: 'bodyText'
      },
      {
        text: rqIndex === 0 ? allocation.styles.description : '',
        fillColor: rqIndex === 0 ? '#ebebeb' : null,
        style: 'bodyText'
      },
      {
        text: rqIndex === 0 ? allocation.styles.color : '',
        fillColor: rqIndex === 0 ? '#ebebeb' : null,
        style: 'bodyText'
      },
      {
        text: rqIndex === 0 ? [{ text: '$', bold: true }, allocation.styles.cost] : '',
        fillColor: rqIndex === 0 ? '#ebebeb' : null,
        style: 'bodyText'
      },
      {
        text: rqIndex === 0 ? [{ text: '$', bold: true }, allocation.styles.msrp] : '',
        fillColor: rqIndex === 0 ? '#ebebeb' : null,
        style: 'bodyText'
      },
      {
        text: rqIndex === 0 ? allocation.total.toString() : '',
        fillColor: rqIndex === 0 ? '#ebebeb' : null,
        style: 'bodyText'
      },
      { text: rq.size, fillColor: rqIndex === 0 ? '#ebebeb' : null, style: 'bodyText' },
      { text: rq.quantity.toString(), fillColor: rqIndex === 0 ? '#ebebeb' : null, style: 'bodyText' },
      { text: allocation.totalAllocationPerSize[rqIndex].toString(), fillColor: rqIndex === 0 ? '#ebebeb' : null, style: 'bodyText' },
      { text: allocation.overstockPerSize[rqIndex].toString(), fillColor: rqIndex === 0 ? '#ebebeb' : null, style: 'bodyText' },
      {
        text: rqIndex === 0 ? allocation.total * allocation.styles.cost : '',
        fillColor: rqIndex === 0 ? '#ebebeb' : null,
        style: 'bodyText'
      },
    ]);
  }).flat();
  const headerRowStyle = {
    fillColor: '#CCCCCC', // Background color
    color: '#000000', // Text color
    bold: true,

  };

  const documentDefinition: TDocumentDefinitions = {
    content: [
      {
        text: 'PO Worksheet',
        fontSize: 20,
        bold: true,
        alignment: 'center',
        decoration: 'underline',
        margin: [0, 0, 0, 20],
      },
      {
        text: `Supplier: ${filter.supplierName} | PO No: ${filter.poNo.toUpperCase()}`,
        bold: true,
        margin: [-34, 0, 0, 10],
      },
      {
        margin: [-34, 0, 0, 0],
        table: {
          headerRows: 1,

          widths: [70,105,35,37,40,30,30,30,30,35,35],
          body: [
            [
              { text: 'Style No', style: headerRowStyle },
              { text: 'Description', style: headerRowStyle },
              { text: 'Color', style: headerRowStyle },
              { text: 'Cost', style: headerRowStyle },
              { text: 'MSRP', style: headerRowStyle },
              { text: 'Total Qty', style: headerRowStyle },
              { text: 'Size', style: headerRowStyle },
              { text: 'RCVQTY', style: headerRowStyle },
              { text: 'Total Alloc', style: headerRowStyle },
              { text: 'OS', style: headerRowStyle },
              { text: 'Total($)', style: headerRowStyle },
            ],
            ...transformedData,

          ],

        },
      },
      {
        columns: [
          {
            text: `Total Quantity: ${data.reduce((total, allocation) => total + allocation.total, 0)}`,
            fontSize: 14,
            bold: true,
            alignment: 'left',
            margin: [0, 15, 0, 0]
          },
          {
            text: `Total Amount: $${data.reduce((total, allocation) => total + allocation.total * allocation.styles.cost, 0)}`,
            fontSize: 14,
            bold: true,
            alignment: 'right',
            margin: [0, 15, 0, 0]
          },
        ],
      },
    ],
    styles: {
      bodyText: {
        fontSize: 10
      }
    }
  } as TDocumentDefinitions;

  pdfMake.createPdf(documentDefinition).open();
};

export default createPdf;
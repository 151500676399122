import {  Grid } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import InvoiceDataStyle from './invoiceDataStyle';

import ListPoNo from './listPoNo';
import InvoiceDataPoNo from './invoiceDataPoNo';
import '../../components/Styles/allocationInvoice.css';
import featureFlags from '../../Authentication/FeatureFlag';




const InvoiceData: React.FC = () => {
    const [poNoList, setPoNoList] = useState<{label: string, value: number}[]>([]);
    const getAllocationData = async () => {
        try {
            
            const response = await fetch('/allocation/getPoNo', { credentials: 'include' });
            const data = await response.json();
            await setPoNoList(data.map((item: { poNo: string, status: string, po_id: number }) => ({label: item.poNo, value: item.po_id})).slice(0,4));
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        if(featureFlags.INVOICE_DATA_PO_NO){
            getAllocationData();
        }
    },[]);

    return (
        <div>
            <Grid>
                <Grid.Col span={8} style={{ height: "300px"}}>
                    <div style={{ borderBottom: '1px solid black', height: "400px"}}>
                    <InvoiceDataStyle />
                    </div>
                    {featureFlags.INVOICE_DATA_PO_NO && (
                    <div style={{ height: "400px", overflowY: "scroll"}}>
                        <InvoiceDataPoNo props={{ poNoList: poNoList}} />
                    </div>
                )}   
                </Grid.Col>
                <Grid.Col span={4} style={{ marginTop: "150px"}}>
                    <ListPoNo />
                </Grid.Col>
            </Grid>
        </div>
    );
};

export default InvoiceData;
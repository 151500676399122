import React from 'react';
import { Button } from '@mantine/core';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { IconDownload } from '@tabler/icons-react';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { STORE_PAIRS } from '../Contant/component';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface SizeQuantity {
  size: string;
  quantity: number;
}
interface StyleData {

  supplier_name: string;
  style_id: number;
  style_no: string;
  description: string;
  color: string;
  cost: number;
  msrp: number;
  total_qty: number;
  location: string;
  first_name: string;
}
interface AllocationData {
  allocation_id: number;
  storeName: string[];
  style_id: number;
  supplierName: string;
  poNo: string;
  sizeQuantities: SizeQuantity[][];
  styles: StyleData;
}

interface StoreStyle {
  [key: string]: string[];
}
const initialFilterState = {
  storeName: '',
  supplierName: '',
  styleNo: '',
  poNo: ''
};

interface PDFGeneratorProps {
  data: AllocationData[];
  filter: typeof initialFilterState;
}

const PDFGenerator: React.FC<PDFGeneratorProps> = ({ data, filter }) => {
    type StorePair = {
    [key: string]: string; // Key is a string, value is a number
  };


const generateStylePDF = () => {
  let docDef = {
    content: [
      {
        text: 'Not In Transfer List',
        fontSize: 20,
        bold: true,
        alignment: 'center',
        decoration: 'underline',
        margin: [0, 0, 0, 20],
      },
      {
        table: {
          headerRows: 1,
          header: ['Store Name', 'Style Nos'],
          widths: ['*', '*'],
          body: buildNonTransferTable(data)
        },
        layout: {
          hLineWidth: () => 0.5,
          vLineWidth: () => 0.5,
          hLineColor: () => '#000000',
          vLineColor: () => '#000000',
        }
      }
    ],
    styles: {
      header: {
        fontSize: 12,
        bold: true
      }
    }
  };

  pdfMake.createPdf(docDef as TDocumentDefinitions).open();
}


let tempTotal = 0;
  const generatePDF = () => {
    tempTotal = 0;
    let docDefinition = {
      content: [
        {
          text: 'Transfer List',
          fontSize: 20,
          bold: true,
          alignment: 'center',
          decoration: 'underline',
          margin: [0, 0, 0, 20],
        },
        {
          columns: [
            {
              text: `Supplier Name: ${filter.supplierName}`,
              fontSize: 16,
              bold: true,
              alignment: 'center',
              margin: [0, 0, 0, 8]
            },
            {
              text: `PO No: ${filter.poNo}`,
              fontSize: 16,
              bold: true,
              alignment: 'center',
              margin: [0, 0, 0, 8]
            },
            {
              text: `Store Name: ${filter.storeName}`,
              fontSize: 16,
              bold: true,
              alignment: 'center',
              margin: [0, 0, 0, 16]
            },
          ]
        },
        {
          width: 'auto',
          margin: [-34, 0, 0, 0],
          table: {
            widths: [140, 180, 50, 40, 35, 45, 30],
            body: buildTableBody(data)
          },
          layout: {
            hLineColor: () => '#000000',
            vLineColor: () => '#000000',
            hLineWidth: () => 1,
            vLineWidth: () => 1
          },
        },{
          text: `Total Quantity: ${tempTotal}`,
          fontSize: 14,
          bold: true,
          alignment: 'right',
          margin: [0, 15, 0, 0]
        }
      ],
      defaultStyle: {
        fontSize: 12
      }
    };

    pdfMake.createPdf(docDefinition as TDocumentDefinitions).open();
  };
  const bodyRowStyle = {
    fillColor: '#CCCCCC', // Background color
    color: '#000000', // Text color
    bold: true
  };

  const   buildNonTransferTable = (data: AllocationData[]) => {
    let body = [];
    let notInStore: StoreStyle = Object.keys(STORE_PAIRS).reduce((acc, store) => {
      acc[store] = [];
      return acc;
    }, {} as StoreStyle);

    body.push([
      { text: "Store Name", style: bodyRowStyle },
      { text: "Style Nos", style: bodyRowStyle }
    ]);
    data.filter(row =>
      (!filter.supplierName || row.supplierName.toLowerCase().includes(filter.supplierName.toLowerCase())) &&
      (!filter.styleNo || row.styles.style_no.toLowerCase().includes(filter.styleNo.toLowerCase())) &&
      (!filter.supplierName || row.supplierName.toLowerCase().includes(filter.supplierName.toLowerCase())) &&
      (!filter.poNo || row.poNo.toLowerCase().includes(filter.poNo.toLowerCase())))
      .forEach(row => {
        // Apply the filter here
        if (row.storeName.length !== 15) {
          Object.keys(STORE_PAIRS).forEach(store => {
            if (!row.storeName.includes(store)) {
              notInStore[store] = [...notInStore[store], row.styles.style_no];
            }
          });
        }
      });

    Object.keys(notInStore).forEach(store => {
      body.push([
        { text: store },
        { text: notInStore[store].join(', ') }
      ]);
    });

    return body;
  }

  const buildTableBody = (data: AllocationData[]) => {
    let body = [];
    body.push([
      { text: "Style Number", style: bodyRowStyle },
      { text: "description", style: bodyRowStyle },
      { text: "color", style: bodyRowStyle },
      { text: "Store Name", style: bodyRowStyle },
      { text: "Size", style: bodyRowStyle },
      { text: "Quantity", style: bodyRowStyle },
      { text: "Total", style: bodyRowStyle }
    ]);
    data
      .filter(row =>
        (!filter.supplierName || row.supplierName.toLowerCase().includes(filter.supplierName.toLowerCase())) &&
        (!filter.styleNo || row.styles.style_no.toLowerCase().includes(filter.styleNo.toLowerCase())) &&
        (!filter.supplierName || row.supplierName.toLowerCase().includes(filter.supplierName.toLowerCase())) &&
        (!filter.poNo || row.poNo.toLowerCase().includes(filter.poNo.toLowerCase())))
      .forEach(row => {
        // Apply the filter here
        row.storeName
          .filter(storeName => (!filter.storeName || storeName.toLowerCase()  === filter.storeName.toLowerCase()))
          .forEach((storeName, i) => {
            const storeIndex = row.storeName.indexOf(storeName);
            const total = row.sizeQuantities[storeIndex].reduce((acc, curr) => acc + curr.quantity, 0);
            tempTotal += total;
            row.sizeQuantities[storeIndex].forEach((sq, j) => {
              let dataRow = [];
              dataRow.push(j === 0 ? row.styles.style_no : "");
              dataRow.push(j === 0 ? row.styles.description : "");
              dataRow.push(j === 0 ? row.styles.color : "");
              dataRow.push(j === 0 ? STORE_PAIRS[storeName] : "");
              dataRow.push(sq.size);
              dataRow.push(sq.quantity);
              dataRow.push(j === 0 ? total : "");
              body.push(dataRow);
            });
          });
      });
    return body;
  };
  const downloadPdf = () =>{
    generatePDF();
    generateStylePDF();
  }

  return (
    <Button onClick={downloadPdf} leftIcon={<IconDownload />} />
  );
};

export default PDFGenerator;
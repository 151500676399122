import { Table } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import React, { useEffect, useState } from "react";
import { RMS_STORE_PAIRS, STORE_PAIRS } from "../../Contant/component";

interface CheckExistingProps {
  styleNo: string;
  setColor: (value: string) => void;
  color: string;
  setDescription: (value: string) => void;
  description: string;
  setSizeInput: (value: string) => void;
  sizeInput: string;
  setSizes: (value: string[]) => void;
  binLocation: string;
  setBinLocation: (value: string) => void;
}
interface StyleData {
  store: string;
  size: string;
  quantity: number;
  inTransit: number;
  bin: string;
}
const CheckExisting: React.FC<CheckExistingProps> = ({ styleNo, setColor, color, setDescription, description, sizeInput, setSizeInput, setSizes, binLocation, setBinLocation }) => {
  const [styleData, setStyleData] = useState<StyleData[]>([]);
  const [storeList, setStoreList] = useState<{ store: string, totalQuantity: number, totalTransQuantity: number, maxSize?: boolean }[]>([]);
  const [storeName, setStoreName] = useState('');
  const [newStyle, setNewStyle] = useState(false);

  const getStyleData = async (styleNo: string) => {
    try {
      const response = await fetch(`allocation/rms/style/${styleNo}`, { credentials: 'include' });
      const { data, metaData } = await response.json();
      if (response.status === 200) {
        await setStyleData(data);
        if(metaData){ 
          setColor(metaData.color.toUpperCase());
          setDescription(metaData.description.toUpperCase());
          if(metaData.bin_location){setBinLocation(metaData.bin_location.toUpperCase())}
        }
        const temp = Array.from(new Set(data.map((item: StyleData) => item.store)));
        if (data.length > 0) {
          const storeLi = temp.map((store: any) => {
            return {
              store: RMS_STORE_PAIRS[store],
              totalQuantity: data.filter((item: StyleData) => item.store === store).reduce((sum: number, item: StyleData) => sum + item.quantity, 0),
              totalTransQuantity: data.filter((item: StyleData) => item.store === store).reduce((sum: number, item: StyleData) => sum + item.inTransit, 0),
              maxSize: data.filter((item: StyleData) => item.store === store).length > 1
            };
          });
          const sizeList = await data.filter((item: StyleData) => item.store === temp[0]).sort((a: any, b: any) => a.sku.localeCompare(b.sku)).map((item: StyleData) => item.size);
          // const sizeList = await data.filter((item: StyleData) => item.store === temp[0]).map((item: StyleData) => item.size);
          // setSizeInput(sizeList.join(","));
          // setSizes(sizeList);
          setStoreList(storeLi);
        }
      } else {
        if(response.status === 500) {
          setNewStyle(true);
        }

        setStoreList([]);
        setStyleData([]);
        // setColor('');
        // setDescription('');
      }
      
    }
    catch (error) {
      console.error('Error fetching styles:', error);
    }
  }

  const toggleRowExpansion = async (storeName: string) => {
    if (storeName) {
      setStoreName(storeName);
    }
  };
  const expandableRows = {
    content: ({ record }: { record: any }) => (
      record.store === storeName && (
        <Table>
          <thead >
            <tr style={{ backgroundColor: '#999999' }}>
              <th>Size</th>
              <th>QTY</th>
              <th>IN TRNS QTY</th>
              <th>Bin</th>
            </tr>
          </thead>
          <tbody>
            {styleData.filter((group: any) => group.store === Object.keys(RMS_STORE_PAIRS).find((key) => RMS_STORE_PAIRS[key] === storeName) && (group.quantity > 0 || group.inTransit > 0)).map((group: any) => (
              <tr key={group.size}>
                <td>{group.size}</td>
                <td>{group.quantity}</td>
                <td>{group.inTransit}</td>
                <td>{group.bin}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )
    ),
    // expandOnClick: true
  };

  useEffect(() => {
    getStyleData(styleNo);
  }, [styleNo]);

  return (
    <div>
      {(styleData.length > 0) ?
        (<div style={{ height: "300px" }}>
          <DataTable
            withBorder
            withColumnBorders
            horizontalSpacing="xs"
            verticalSpacing="xs"
            striped
            highlightOnHover
            columns={[
              { accessor: "store", title: "Store" },
              { accessor: "totalQuantity", title: "Quantity" },
              { accessor: "totalTransQuantity", title: "In Trans QTY" },
            ]}
            records={storeList.filter((store) => store.totalQuantity > 0 || store.totalTransQuantity > 0)}
            onRowClick={(row) => toggleRowExpansion(row.store)}
            rowExpansion={expandableRows}
          />
        </div>
        ) : (newStyle ? <div style={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f5f5f5" }}>
          <text style={{ fontSize: "20px", fontWeight: "bold", textAlign: "center" }}>New Style</text>

        </div> : <div style={{ height: "300px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f5f5f5" }}>
          
        </div>)}
    </div>
  )
}

export default CheckExisting;
import React, { useEffect, useState, useContext } from "react";
import { Button, Col, Container, Grid, Paper, ScrollArea,  Table,  Text } from "@mantine/core";
import { STORE_PAIRS } from "../../Contant/component";
import { DataTable } from "mantine-datatable";
import { DataGrid, stringFilterFn } from "mantine-data-grid";

interface AllocationData {
  storeName: string[];
  sizeQuantities: SizeQuantity[][];
  supplierName: string;
  poNo: string;
  styles: StyleData;
  PurchaseOrders: PurchaseOrderData;
}

interface PurchaseOrderData {
  po_id: string;
  po_no: string;
}


interface StyleData {
  style_no: string;
}


interface SizeQuantity {
  size: string;
  quantity: number;
}

interface StoreData {
  store: string;
  styles: number; // Represents number of unique styles in the store
  quantity: number; // Total quantity of all styles in the store
}

interface AllocationInvoiceProps {
  invoicePoId: string;
  updateSignal: number; // or string, or Date, depending on how you track updates
}

const AllocationInvoice = (props: any) => {
  const [allocationData, setAllocationData] = useState<AllocationData[]>([]);
  const [loading, setLoading] = useState(true);
  const [poNumber, setPoNumber] = useState('');
  const [supplier, setSupplier] = useState('');
  const [totalStyles, setTotalStyles] = useState(0);
  const [storeData, setStoreData] = useState<StoreData[]>([]);


  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/allocation/poNo/${props.values.invoicePoId}`, { credentials: 'include' });
      const data = await response.json() as AllocationData[];
      setAllocationData(data);
      if (data.length > 0) {
        setSupplier(data[0].supplierName);
        setPoNumber(data[0].PurchaseOrders.po_no);
      }

      const styleSet = new Set();
      const storeCounts: { [key: string]: number } = {};
      const storeQuantities: { [key: string]: number } = {};

      data.forEach(item => {
        styleSet.add(item.styles.style_no);
        item.storeName.forEach((store, index) => {
          storeCounts[store] = (item.sizeQuantities[index].reduce((sum, sq) => sum + sq.quantity, 0) > 0) ? (storeCounts[store] || 0) + 1 : storeCounts[store];
          storeQuantities[store] = (storeQuantities[store] || 0) + item.sizeQuantities[index].reduce((sum, sq) => sum + sq.quantity, 0);
        });
      });
      const computedStoreData = Object.keys(STORE_PAIRS).map(store => ({
        store:STORE_PAIRS[store],
        styles: storeCounts[store],
        quantity: storeQuantities[store]
      })).filter(item => item.quantity > 0);

      setTotalStyles(styleSet.size);
      setStoreData(computedStoreData);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [props.values.invoicePoId, props.values.trigger]);

  const initialStoreData = storeData;
  // const initialStoreData = storeData.filter(store => expanded || storeData.indexOf(store) <2 );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Paper shadow="lg" radius="lg" p="sm" withBorder style={{ width: '65%', height: '350px' }}>
      <Container >
        <Grid columns={12}>
          <Col span={6}>
            <label style={{ fontWeight: 'bold' }}>PO No:</label>
            <Text style={{ width: '100%', height: '100%' }}>{poNumber}</Text>
          </Col>
          <Col style={{ textAlign: 'right', paddingRight: '40px' }} span={6}>
            <label style={{ fontWeight: 'bold' }}>Supplier:</label>
            <Text>{supplier}</Text>
          </Col>
        </Grid>
      </Container>
      <Container style={{  borderTop: '2px solid', paddingTop: '10px', height: '30px', alignItems: 'center' }}>
        <Grid>
          <Col span={6}> 
          <label style={{ fontWeight: 'bold', marginRight: '7px' }}>Total Styles:{totalStyles}</label>
          </Col>
          <Col span={6}>
          <div style={{ justifyContent: 'right' }}>
            <Button variant='subtle' size='xs' radius='xl' onClick={fetchData}>Refresh</Button>
          </div>
          </Col>
        </Grid>
      </Container>
      <Container style={{ height: '200px', width: '100%', marginLeft: '-15px', marginTop: '10px'}}>
     
        <DataTable
          withBorder
          withColumnBorders
          horizontalSpacing="xs"
          verticalSpacing="xs"
          striped
          highlightOnHover
          columns={[
            { accessor: "store", title: "Store" },
            { accessor: "styles", title: "No. of Styles" },
            { accessor: "quantity", title: "Total QTY" },
          ]}
          records={storeData}
        />
   
      </Container>
    </Paper>
  );
};

export default AllocationInvoice;
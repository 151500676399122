import axios from 'axios';
import { Grid, Table, Paper, Col, Input, Button, Modal, Text, Group, Select, Flex } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import './AllocTable.css';
import Homepage from '../components/Navigation/parentHome';
import { IconDeviceFloppy, IconFilter, IconFilterX } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import featureFlags from '../Authentication/FeatureFlag';
import { useDebounce } from '../components/Hooks/useDebounce';

//import HomePage from './parent';

interface StyleRow {
  supplier_name: string;
  style_id: number;
  style_no: string;
  description: string;
  color: string;
  cost: number;
  msrp: number;
}


const initialFilterState = {
  supplierName: '',
  styleNo: '',
  description: '',
  color: ''
};

const useStyles = createUseStyles({
  fixedBar: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    background: '#ECF0F1 ',
    padding: '8px 0',
    boxShadow: '0px -2px 10px rgba(0,0,0,0.1)'
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
  },
  tableCell: {
    border: '1px solid',


  },
});
const StylesTable: React.FC = () => {
  const classes = useStyles();
  const [data, setData] = useState<StyleRow[]>([]);
  const [editedData, setEditedData] = useState<StyleRow[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filter, setFilter] = useState(initialFilterState);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [navbarOpened, setNavbarOpened] = useState(false);
  const [opened, { open, close }] = useDisclosure(true);
  const [styleData, setStyleData] = useState<string[]>([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(13);
  const [styleNo, setStyleNo] = useState('');
  const [styleList, setStyleList] = useState<string[]>([]);
  const [maxPage, setMaxPage] = useState(0);
  const [supplierName, setSupplierName] = useState('');
  
  const debounceSearch = useDebounce(styleNo, 1000);
  
  const handlePageSizeChange = (value: string | null) => {
    if (value !== null) {
      const parsedValue = parseInt(value, 10);
      if (!isNaN(parsedValue)) {
        setPageSize(parsedValue);
        setPageNo(1);
      }
    }
  };

  // useEffect(() => {
  //   fetch('/style/get')
  //     .then(response => response.json())
  //     .then(jsonData => {
  //       setData(jsonData);
  //     })
  //     .catch(error => console.error('Error fetching data:', error));
  // }, []);
  useEffect(() => {
    fetchData();
  }, [])
  useEffect(() => {
    fetchData();
  }, [pageNo, pageSize]);
  const fetchData = async (style_no?: string) => {
    try {
      const url = new URL('/style/get', window.location.origin);
      url.searchParams.append('pageNo', String(pageNo));
      url.searchParams.append('pageSize', String(pageSize));
      if (style_no) {
        url.searchParams.append('style_no', String(style_no));
      }
      if(supplierName) {
        url.searchParams.append('supplier_name', String(supplierName));
      }

      const response = await fetch(url.toString(), {
        credentials: 'include' // Include credentials in the request
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      await setData(data.data);
      setMaxPage(Math.ceil(data.maxLength / pageSize));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  const handleEdit = (style_id: number, key: string, newValue: string) => {
    const isRowInEditedData = editedData.some(row => row.style_id === style_id);

    if (isRowInEditedData) {
      setEditedData(editedData.map(row => {
        if (row.style_id === style_id) {
          return { ...row, [key]: newValue };
        } else {
          return row;
        }
      }));
    } else {
      const row = data.find(row => row.style_id === style_id);
      if (row) {
        setEditedData([...editedData, { ...row, [key]: newValue }]);
      }
    }
  };

  const handleSave = async () => {
    let tempData = [...data];
    for (const row of editedData) {
      try {
        const response = await axios.put(`/style/update/${row.style_id}`, row, { withCredentials: true });
        if (response.status === 200) {
          tempData = tempData.map(item => {
            if (item.style_no === row.style_no) {
              return row;
            } else {
              return item;
            }
          });
        }
      } catch (error) {
        console.error('Error updating style:', error);
      }
    }
    setData(tempData);
    setEditedData([]); // Clear the editedData after successful save
  };

  const filteredData = Array.from(data);
  

  const resetFilter = async () => {
    setFilter(initialFilterState);
    setSearchTerm('');
    setStyleNo('');
    setPageNo(1);
    setSupplierName('');
    fetchData('');

  };
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [tempFilter, setTempFilter] = useState(initialFilterState);

  const applyFilter = () => {
    setFilterModalOpen(false);
    // setFilter(tempFilter);
    setPageNo(1);
    fetchData(debounceSearch);

  }
  const searchStyle = async (styleNo?: string) => {
    try {
      // setStyleList([]);
      const url = new URL('/style/get', window.location.origin);
      if (styleNo) {
        url.searchParams.append('style_no', String(styleNo));
      }
      const response = await fetch(url.toString(), {
        credentials: 'include' // Include credentials in the request
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();

      await setStyleList(data.data.map((item: any) => item.style_no));
    } catch (error) {
      console.error('Error:', error);
    }
  }
  const handleStyleSearch = async (value: string) => {
    setStyleNo(value);
  }
  useEffect(() => {
    const search = async () => {
      try {
        await searchStyle(debounceSearch);
      } catch (error) {
        console.error('Error searching:', error);
      }
    };

    if (debounceSearch !== '') {
      setFilter({ ...filter, styleNo: debounceSearch.toString() });
      search();
    }
  },[debounceSearch])
  const handleOnChangeStyle = (value: string) => {
    setPageNo(1);
    // setStyleNo(value);
    fetchData(value);
  }
  const handleGet = () => {
    close();
  }

  return (
    <>
      <Homepage setNavbarOpened={setNavbarOpened} />
      {featureFlags.POP_UP_STYLE_LIST && <Modal style={{ height: '100%', zIndex: 100 }} opened={opened} onClose={close} title="Enter Style No." centered>
        <Flex
          direction='row'
          justify='center'
          mih={220}
          gap='md'
        >
          <Select
            type="text"
            label="Style No"
            value={styleNo} // Convert to uppercase
            onChange={handleOnChangeStyle} // Convert to uppercase
            // onBlur={handlePoNoBlur}
            data={styleList.slice(0, 4)}
            dropdownPosition='bottom'
            searchable
            onSearchChange={handleStyleSearch}
            placeholder="Enter po_no"
            mih={50}
          // style={{ marginBottom: '40px' }}
          />
          <Button style={{ marginTop: '25px' }} onClick={handleGet}>Get</Button>
        </Flex>
      </Modal>}
      <Grid gutter="md" className="table-wrapper">
        <Col span={12}>
          <Paper shadow="xs" className="alloc-table">
            <Table className={classes.table}>
              <thead className={classes.tableCell}>
                <tr className="tableHeader">
                  <th>
                    <Text ta="center" fz="lg" fw={600}>
                      Supplier Name
                    </Text>
                  </th>
                  <th>
                    <Text ta="center" fz="lg" fw={600}>
                      Style No
                    </Text>
                  </th>
                  <th>
                    <Text ta="center" fz="lg" fw={600}>
                      Description
                    </Text>
                  </th>
                  <th>
                    <Text ta="center" fz="lg" fw={600}>
                      Color
                    </Text>
                  </th>
                  <th>
                    <Text ta="center" fz="lg" fw={600}>
                      Cost
                    </Text>
                  </th>
                  <th>
                    <Text ta="center" fz="lg" fw={600}>
                      MSRP
                    </Text>
                  </th>
                </tr>
              </thead>
              <tbody className={classes.tableCell}>
                {Array.isArray(filteredData) && filteredData.map((row, index) => (
                  <tr key={row.style_id} className={classes.tableCell}>
                    <td>{row.supplier_name}</td>
                    <td>{row.style_no}</td>
                    <td><Input defaultValue={row.description} onBlur={(e) => handleEdit(row.style_id, 'description', e.target.value)} variant="unstyled" /></td>
                    <td><Input defaultValue={row.color} onBlur={(e) => handleEdit(row.style_id, 'color', e.target.value)} variant="unstyled" /></td>
                    <td><Input defaultValue={row.cost.toString()} onBlur={(e) => handleEdit(row.style_id, 'cost', e.target.value)} variant="unstyled" /></td>
                    <td><Input defaultValue={row.msrp.toString()} onBlur={(e) => handleEdit(row.style_id, 'msrp', e.target.value)} variant="unstyled" /></td>
                  </tr>
                ))}
              </tbody>
            </Table>

          </Paper>
        </Col>
        <div className={classes.fixedBar}>
          <Grid gutter="xs" >

            <Col span={1} >
              <Button onClick={handleSave} leftIcon={<IconDeviceFloppy />} variant="subtle" color="dark">Save</Button>
            </Col>
            <Col span={4}>
              <Select
                type="text"
                // value={filter.styleNo} // Convert to uppercase
                onChange={handleOnChangeStyle} // Convert to uppercase
                data={styleList.slice(0, 4)}
                dropdownPosition='top'
                searchable
                onSearchChange={(value) => setStyleNo(value)}
                placeholder="Enter style_no"
                mih={50}
              />
            </Col>
            <Col offset={1} span={3}>
              <Button onClick={() => pageNo === 1 ? setPageNo(1) : setPageNo(pageNo - 1)} disabled={pageNo === 1}>Prev</Button>
              <Button disabled>{pageNo}</Button>
              <Button onClick={() => pageNo === maxPage ? setPageNo(pageNo) : setPageNo(pageNo + 1)} disabled={pageNo === maxPage}>Next</Button>
            </Col>
            <Col span={1}>
              <Button onClick={() => setFilterModalOpen(true)} leftIcon={<IconFilter />} variant="subtle" color="dark" uppercase>Filter</Button>
            </Col>
            <Modal
              title='Filter'
              opened={filterModalOpen}
              onClose={() => setFilterModalOpen(false)}
              centered>
              <Group position='center'>
                <Input
                  placeholder="Filter by Supplier Name"
                  value={supplierName}
                  onChange={(event) => setSupplierName(event.currentTarget.value)}
                />

                <Button onClick={applyFilter}>Apply</Button>
              </Group>
            </Modal>
            <Col span={1}>
              <Button
                leftIcon={<IconFilterX />}
                title="Reset Filter"
                onClick={resetFilter}
                variant="subtle" color="dark"
              />
            </Col>
            <Col span={1}>
              <Select
                value={String(pageSize)}
                onChange={handlePageSizeChange}
                data={[{ value: '13', label: '13' }, { value: '25', label: '25' }, { value: '50', label: '50' }]}
                mih={50}
                placeholder="Page Size"
              />
            </Col>
          </Grid>
        </div>
      </Grid>
    </>
  );
}

export default StylesTable;
import { Button, Col, Flex, Grid, Group, Stack, Table, Text, TextInput } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { DataTable } from 'mantine-datatable';
import Homepage from "../Navigation/parentHome";
import '../Styles/allocationCalculator.css';
import '../Styles/allocationInvoice.css';


interface AllocationData {
    storeName: string[];
    sizeQuantities: SizeQuantity[][];
    supplierName: string;
    poNo: string;
    styles: StyleData;
    updatedAt: Date;
    PurchaseOrders: PurchaseOrderData;
}

interface PurchaseOrderData {
    po_id: string;
    po_no: string;
}


interface StyleData {
    style_no: string;
    description: string;
}


interface SizeQuantity {
    size: string;
    quantity: number;
}

interface StoreData {
    store: string;
    // styles: number; // Represents number of unique styles in the store
    quantity: number; // Total quantity of all styles in the store
}
interface mainTableData {
    poNo: string;
    updatedAt: Date;
    stores: StoreData[];
}
type StorePair = {
    [key: string]: string; // Key is a string, value is a number
};

// Create an object of key-value pairs

const InvoiceDataStyle = () => {

    const [styleNo, setStyleNo] = useState('');
    const [navbarOpened, setNavbarOpened] = useState(false);
    const [loading, setLoading] = useState(true);
    const [allocationData, setAllocationData] = useState<AllocationData[]>([]);
    const [poNumber, setPoNumber] = useState('');
    const [supplier, setSupplier] = useState('');
    const [totalStyles, setTotalStyles] = useState(0);
    const [storeData, setStoreData] = useState<StoreData[]>([]);


    const storePairs: StorePair = {
        "STEELES": "ST",
        "WEB": "WEB",
        "OPM": "OPM",
        "VAUGHAN": "V",
        "NIAGARA": "NF",
        "ALLISTON": "AL",
        "SVP QW": "QW",
        "CARTWRIGHT": "CW",
        "Brampton": "BR",
        "PICKERING": "PC",
        "YORKGATE": "YG",
        "SVP NM": "SVP NM",
        "ORFUS":"ORFUS",
        "OPM-HAMILTON": "OHAM",
        "SC SuperStore": "SCS"
    };
    const fetchEntireData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`/allocation/style_no/${styleNo}`, { credentials: 'include' });
            const data = await response.json() as AllocationData[];
            setAllocationData(data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };
    useEffect(() => {
        setNavbarOpened(false);
    }, []);

    const rows: mainTableData[] = allocationData.map((item) => ({
        poNo: item.PurchaseOrders.po_no,
        updatedAt: item.updatedAt,
        stores: item.storeName.map((store, index) => ({ 
            store,
            quantity: item.sizeQuantities[index].reduce((sum, sq) => sum + sq.quantity, 0)
        }))
    }));

    return (
        <div>
            <Homepage setNavbarOpened={setNavbarOpened} />
            <div style={{ margin: "100px", justifyContent: "center", alignItems: "center" }}>
                <Grid gutter="lg" align="center" justify="center">
                    <Col span={4} style={{ justifyContent: "center", alignItems: "center" }}>
                        <TextInput
                            type="text"
                            label="Style No"
                            value={styleNo.toUpperCase()} // Convert to uppercase
                            placeholder="Enter Style No"
                            onChange={(e) => setStyleNo(e.target.value)}
                            // onBlur={(e) => fetchEntireData()}
                            mih={50}
                        />
                    </Col>
                    <Col span={2} style={{ justifyContent: "center", alignItems: "center", marginTop: "24px" }}>
                        <Button onClick={() => fetchEntireData()}>Fetch</Button>
                    </Col>
                </Grid>
                <Flex
                    mih={50}
                    gap="large"
                    justify="center"
                    align="center"
                    direction="row"
                    wrap="wrap"
                    style={{ width: "100%", marginTop: "20px", justifyContent: "center", alignItems: "center", height: "250px" }}
                >
                    <DataTable
                        withBorder
                        style={{ width: "100%", justifyContent: "center" }}
                        withColumnBorders
                        columns={[{ accessor: 'poNo', title: 'Po No', titleClassName: 'expanded-vertical-table' }, { accessor: 'updatedAt', title: 'Updated At', titleClassName: 'expanded-vertical-table' }]}
                        records={rows}
                        idAccessor="poNo"
                        rowExpansion={{
                            allowMultiple: true,
                            content: ({ record }: { record: mainTableData }) => (
                                <Stack key={record.poNo} p="xs" spacing={6}>
                                    <Table>
                                        <thead style={{ backgroundColor: '#f5f9ee' }}>
                                            <td style={{ fontWeight: 'bold', border: '1px solid black' }}>Stores</td>
                                            {record.stores.map(item =>
                                                <td className='expanded-vertical-table-head'>
                                                    {storePairs[item.store]}
                                                </td>
                                            )}
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td style={{ fontWeight: 'bold', border: '1px solid black' }}>Quantity</td>
                                            {record.stores.map(item =>
                                                <td className="expanded-vertical-table-data">{item.quantity}</td>
                                            )}
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Stack>
                            ),
                        }}
                    />
                </Flex>

            </div>
        </div>
    )
};

export default InvoiceDataStyle;
// // import React, { useEffect, useState } from 'react';
// // import { TextInput, Button, Paper, Table, Center, Text, Group, Container, Flex, Collapse } from '@mantine/core';
// // import { DataTable, DataTableColumn } from 'mantine-datatable';
// // import axios from 'axios';
// // import Homepage from '../components/Navigation/parentHome';
// // import { STORE_PAIRS } from '../Contant/component';
// // import { IconDownload } from '@tabler/icons-react';

// // interface SizeQuantity {
// //   size: string;
// //   quantity: number;
// //   sku: string;
// // }

// // interface StyleData {
// //   supplier_name: string;
// //   style_no: string;
// //   description: string;
// //   color: string;
// //   cost: number;
// //   msrp: number;
// //   notes: string;
// //   total_qty: number;
// //   location: string;
// //   first_name: string;
// // }

// // interface AllocationData {
// //   allocation_id: number;
// //   storeName: string[];
// //   style_no: string;
// //   supplierName: string;
// //   poNo: string;
// //   status: boolean[];
// //   skuNumbers: string[];
// //   initial: boolean[];
// //   sizeQuantities: SizeQuantity[][];
// //   styles: StyleData;
// //   PurchaseOrders: PurchaseOrderData;
// // }

// // interface PurchaseOrderData {

// //   po_id: string;
// //   po_no: string;
// // } 

// // interface TableData{
// //   storeName: string;
// //   styleNo: string[];
// //   supplierName: string;
// //   sizeQuantities: SizeQuantity[][];
// //   poNo: string;
// //   cost: number[];
// //   msrp: number[];
// //   totalQty: number[];
// //   status: boolean[];
// //   skuNumbers: string[];
// //   color: string[];
// //   description: string[];
// //   notes: string[];

// // }

// // const Transfer: React.FC = () => {
// //   const [allocations, setAllocations] = useState<AllocationData[]>([]);
// //   const [poNo, setPoNo] = useState<string>('');
// //   const [storeName, setStoreName] = useState<string>('');
// //   const [navbarOpened, setNavbarOpened] = useState(false);
// //   const [storeList, setStoreList] = useState<string[]>(Object.keys(STORE_PAIRS));
// //   const [tableData, setTableData] = useState<TableData[] >([]);
// //   const [openedRows, setOpenedRows] = useState<{ [index: number]: boolean }>({});
// //   const fetchData = async () => {
// //     try{
// //     const response = await axios.get(`/allocation/tr`, {
// //       params: { poNo },
// //       withCredentials: true
// //     });
// //     setAllocations(response.data);
    
// //     await setTableData(storeList.map((store ) => {
// //       let data = response.data.filter((data: any) => data.storeName.includes(store));
// //       return {
// //         storeName: store,
// //         styleNo: data.map((data: any) => data.styles.style_no),
// //         supplierName: (new Set(data.map((data: any) => data.supplierName))).values().next().value,
// //         sizeQuantities: data.map(( data: any) => data.sizeQuantities[data.storeName.indexOf(store)]),
// //         poNo: (new Set(data.map((data: any) => data.PurchaseOrders.po_no))).values().next().value,
// //         cost: data.map((data: any) => data.styles.cost),
// //         msrp: data.map((data: any) => data.styles.msrp),
// //         color: data.map((data: any) => data.styles.color),
// //         description: data.map((data: any) => data.styles.description),
// //         notes: data.map((data: any) => data.styles.notes),
// //         totalQty:data.map(( data: any) => data.sizeQuantities[data.storeName.indexOf(store)].reduce((total: number, item: { quantity: number; }) => total + item.quantity, 0)),
// //         status: data.map((data: any) => {
// //           if(data.status[data.storeName.indexOf(store)] === true){
// //             return 'Allocated';
// //           }else{
// //             return 'Pending';
// //           }
// //         }),
// //         skuNumbers: data.map((data: any) => data.skuNumbers),
// //     };
// //   }));
// //     }catch(error){
// //       console.error(error);
// //     }
// //   };

// //   // Function to toggle the expansion state of a specific row
// //   const toggleRow = (index: number) => {
// //     setOpenedRows((prevOpenedRows) => ({
// //       ...prevOpenedRows,
// //       [index]: !prevOpenedRows[index], // Toggle the state of the clicked row
// //     }));
// //   };
// //   const toggleRowExpansion = (storeName: string) => {
// //     if(storeName){
// //       setStoreName(storeName);
// //     }
// //   };

// //   const expandableRows = {
// //     content: ({ record }: { record: TableData }) => (
// //       record.storeName === storeName && (
// //         <Table>
// //           <thead >
// //             <tr style={{ backgroundColor: '#999999'}}>
// //               <th>Style No</th>
// //               <th>Supplier Name</th>
// //               <th>Total Quantity</th>
// //               <th>Status</th>
// //               <th>Cost</th>
// //               <th>MSRP</th>
// //               <th>Description</th>
// //             </tr>
// //           </thead>
// //             {record.sizeQuantities.map((sizeQuantities, index) => {
// //               return (  
// //                 <tbody>
// //               <tr key={index} onClick={() => toggleRow(index)}>
// //                 <td>{record.styleNo[index]}</td>
// //                 <td>{record.supplierName}</td>
// //                 <td>{record.totalQty[index]}</td>
// //                 <td>{record.status[index]}</td>
// //                 <td>{record.cost[index]}</td>
// //                 <td>{record.msrp[index]}</td>
// //                 <td>{record.description[index]}</td>
// //               </tr>    
// //               <tr>
// //                 <td colSpan={7}>
// //               <Collapse in={openedRows[index]}>          
// //               <Table style={{ width: '100%' }}>
// //                 <thead>
// //                   <tr style={{ backgroundColor: '#cccccc'}}>
// //                     <th style={{ textAlign: 'center', fontWeight: 'bold' }}>Sku</th>
// //                     {sizeQuantities.map((sq, i) => (
// //                       <th key={`${i}-${sq.size}`}>{record.skuNumbers[index][i]}</th>
// //                     ))}
// //                   </tr>
// //                 </thead>
// //                 <tbody>
// //                   <tr >
// //                     <td style={{ textAlign: 'center', fontWeight: 'bold' }}>Size</td>
// //                   {sizeQuantities.map((sq, i) => (
// //                       <td>{sq.size}</td>
// //                       ))}
// //                   </tr>
// //                   <tr>
// //                     <td style={{ textAlign: 'center', fontWeight: 'bold' }}>Quantity</td>
// //                   {sizeQuantities.map((sq, i) => (
// //                       <td>{sq.quantity}</td>
// //                       ))}
// //                   </tr>
// //                 </tbody>
// //               </Table>
// //                 </Collapse>
// //                 </td>
// //               </tr>
// //           </tbody>
// //               )
// //             })}

// //         </Table>
// //       )
// //     ),
// //     expandOnClick: false
// //   };


// //   const tableColumns: DataTableColumn<TableData>[] = [
// //     { accessor: 'storeName', title: 'Store Name' },
// //     { accessor: 'storeName', title: 'Download TR file', 
// //       render: (value) => <Button onClick={() => handleDownload(value.storeName? value.storeName : '')}  leftIcon={<IconDownload />} variant="subtle" color="dark"/> },
// //   ]




// //   const generateDataString = (store: string) => {
// //     let dataString = '';

// //     allocations.filter(allocation => allocation.storeName.includes(store)).forEach(allocation => {
// //       let i = allocation.storeName.indexOf(store);
// //       allocation.sizeQuantities.forEach((sizes, storeIndex) => {
// //         if (i === storeIndex) {
// //           sizes.forEach((size, sizeIndex) => {
// //             // Access the corresponding SKU using the sizeIndex within this store's context
// //             const sku = allocation.skuNumbers && allocation.skuNumbers.length > sizeIndex ? allocation.skuNumbers[sizeIndex] : 'N/A';
// //             // Check if the quantity is greater than 0 and SKU is available before adding to the string
// //             if (size.quantity > 0 && sku !== 'N/A') {
// //               dataString += `${sku},${size.quantity},,\n`; // Format the string as needed
// //             }
// //           });
// //         }
// //       });
// //     });
// //     return dataString;
// //   };

// //   const handleDownload = (store: string) => {
// //     const dataString = generateDataString(store);

// //     // Check if the dataString is empty
// //     if (!dataString) {
// //       alert('No data available to download.');
// //       return;
// //     }

// //     // Format the filename to include poNo and storeName, replacing any spaces or invalid characters
// //     const formattedPoNo = poNo.replace(/[^a-zA-Z0-9]/g, '_');
// //     const formattedStoreName = storeName.replace(/[^a-zA-Z0-9]/g, '_');
// //     const fileName = `${formattedPoNo}_${formattedStoreName}.txt`;

// //     const blob = new Blob([dataString], { type: 'text/plain' });
// //     const url = URL.createObjectURL(blob);
// //     const link = document.createElement('a');
// //     link.href = url;
// //     link.download = fileName; // Name of the file to be downloaded using formatted poNo and storeName
// //     link.click();
// //     URL.revokeObjectURL(url); // Clean up the URL object
// //   };



// //   return (
// //     <div style={{ marginTop: '100px', padding: '20px' }}>
// //       <Homepage setNavbarOpened={() => setNavbarOpened(!navbarOpened)} />
// //         <Container style={{ marginBottom: '10px' }}>
// //           <TextInput placeholder="Enter PO Number" value={poNo} onChange={(e) => setPoNo(e.target.value)} style={{ marginBottom: 20 }} />
// //           <Group style={{ justifyContent: 'center' }}>
// //             <Button onClick={fetchData} color="blue" style={{ marginRight: 10 }}>GET TR DATA</Button>
// //           </Group>
// //         </Container>
// //       <Paper px="md" shadow="xl">
// //         {tableData && 
// //         <DataTable 
// //         records={tableData.filter(record => record.totalQty.reduce((a, b) => a + b, 0) > 0)} 
// //         columns={tableColumns} 
// //         withBorder 
// //         withColumnBorders
// //         rowExpansion={expandableRows}
// //         onRowClick={(row) => toggleRowExpansion(row.storeName)}
// //         />}
// //       </Paper>
// //     </div>
// //   );
// // };

// // export default Transfer;
// import React, { useEffect, useState } from 'react';
// import { TextInput, Button, Paper, Table, Center, Text, Group, Container, Flex, Collapse } from '@mantine/core';
// import { DataTable, DataTableColumn } from 'mantine-datatable';
// import axios from 'axios';
// import Homepage from '../components/Navigation/parentHome';
// import { STORE_PAIRS } from '../Contant/component';
// import { IconDownload } from '@tabler/icons-react';

// interface SizeQuantity {
//   size: string;
//   quantity: number;
//   sku: string;
// }

// interface StyleData {
//   supplier_name: string;
//   style_no: string;
//   description: string;
//   color: string;
//   cost: number;
//   msrp: number;
//   notes: string;
//   total_qty: number;
//   location: string;
//   first_name: string;
// }

// interface AllocationData {
//   allocation_id: number;
//   storeName: string[];
//   style_no: string;
//   supplierName: string;
//   poNo: string;
//   status: boolean[];
//   skuNumbers: string[];
//   initial: boolean[];
//   sizeQuantities: SizeQuantity[][];
//   styles: StyleData;
//   PurchaseOrders: PurchaseOrderData;
// }

// interface PurchaseOrderData {

//   po_id: string;
//   po_no: string;
// } 

// interface TableData{
//   storeName: string;
//   styleNo: string[];
//   supplierName: string;
//   sizeQuantities: SizeQuantity[][];
//   poNo: string;
//   cost: number[];
//   msrp: number[];
//   totalQty: number[];
//   status: boolean[];
//   skuNumbers: string[];
//   color: string[];
//   description: string[];
//   notes: string[];

// }

// const Transfer: React.FC = () => {
//   const [allocations, setAllocations] = useState<AllocationData[]>([]);
//   const [poNo, setPoNo] = useState<string>('');
//   const [storeName, setStoreName] = useState<string>('');
//   const [navbarOpened, setNavbarOpened] = useState(false);
//   const [storeList, setStoreList] = useState<string[]>(Object.keys(STORE_PAIRS));
//   const [tableData, setTableData] = useState<TableData[] >([]);
//   const [openedRows, setOpenedRows] = useState<{ [index: number]: boolean }>({});
//   const fetchData = async () => {
//     try{
//     const response = await axios.get(`/allocation/tr`, {
//       params: { poNo },
//       withCredentials: true
//     });
//     setAllocations(response.data);
    
//     await setTableData(storeList.map((store ) => {
//       let data = response.data.filter((data: any) => data.storeName.includes(store));
//       return {
//         storeName: store,
//         styleNo: data.map((data: any) => data.styles.style_no),
//         supplierName: (new Set(data.map((data: any) => data.supplierName))).values().next().value,
//         sizeQuantities: data.map(( data: any) => data.sizeQuantities[data.storeName.indexOf(store)]),
//         poNo: (new Set(data.map((data: any) => data.PurchaseOrders.po_no))).values().next().value,
//         cost: data.map((data: any) => data.styles.cost),
//         msrp: data.map((data: any) => data.styles.msrp),
//         color: data.map((data: any) => data.styles.color),
//         description: data.map((data: any) => data.styles.description),
//         notes: data.map((data: any) => data.styles.notes),
//         totalQty:data.map(( data: any) => data.sizeQuantities[data.storeName.indexOf(store)].reduce((total: number, item: { quantity: number; }) => total + item.quantity, 0)),
//         status: data.map((data: any) => {
//           if(data.status[data.storeName.indexOf(store)] === true){
//             return 'Allocated';
//           }else{
//             return 'Pending';
//           }
//         }),
//         skuNumbers: data.map((data: any) => data.skuNumbers),
//     };
//   }));
//     }catch(error){
//       console.error(error);
//     }
//   };

//   // Function to toggle the expansion state of a specific row
//   const toggleRow = (index: number) => {
//     setOpenedRows((prevOpenedRows) => ({
//       ...prevOpenedRows,
//       [index]: !prevOpenedRows[index], // Toggle the state of the clicked row
//     }));
//   };
//   const toggleRowExpansion = (storeName: string) => {
//     if(storeName){
//       setStoreName(storeName);
//     }
//   };

//   const expandableRows = {
//     content: ({ record }: { record: TableData }) => (
//       record.storeName === storeName && (
//         <Table>
//           <thead >
//             <tr style={{ backgroundColor: '#999999'}}>
//               <th>Style No</th>
//               <th>Supplier Name</th>
//               <th>Total Quantity</th>
//               <th>Status</th>
//               <th>Cost</th>
//               <th>MSRP</th>
//               <th>Description</th>
//             </tr>
//           </thead>
//             {record.sizeQuantities.map((sizeQuantities, index) => {
//               return (  
//                 <tbody>
//               <tr key={index} onClick={() => toggleRow(index)}>
//                 <td>{record.styleNo[index]}</td>
//                 <td>{record.supplierName}</td>
//                 <td>{record.totalQty[index]}</td>
//                 <td>{record.status[index]}</td>
//                 <td>{record.cost[index]}</td>
//                 <td>{record.msrp[index]}</td>
//                 <td>{record.description[index]}</td>
//               </tr>    
//               <tr>
//                 <td colSpan={7}>
//               <Collapse in={openedRows[index]}>          
//               <Table style={{ width: '100%' }}>
//                 <thead>
//                   <tr style={{ backgroundColor: '#cccccc'}}>
//                     <th style={{ textAlign: 'center', fontWeight: 'bold' }}>Sku</th>
//                     {sizeQuantities.map((sq, i) => (
//                       <th key={`${i}-${sq.size}`}>{record.skuNumbers[index][i]}</th>
//                     ))}
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr >
//                     <td style={{ textAlign: 'center', fontWeight: 'bold' }}>Size</td>
//                   {sizeQuantities.map((sq, i) => (
//                       <td>{sq.size}</td>
//                       ))}
//                   </tr>
//                   <tr>
//                     <td style={{ textAlign: 'center', fontWeight: 'bold' }}>Quantity</td>
//                   {sizeQuantities.map((sq, i) => (
//                       <td>{sq.quantity}</td>
//                       ))}
//                   </tr>
//                 </tbody>
//               </Table>
//                 </Collapse>
//                 </td>
//               </tr>
//           </tbody>
//               )
//             })}

//         </Table>
//       )
//     ),
//     expandOnClick: false
//   };


//   const tableColumns: DataTableColumn<TableData>[] = [
//     { accessor: 'storeName', title: 'Store Name' },
//     { accessor: 'storeName', title: 'Download TR file', 
//       render: (value) => <Button onClick={() => handleDownload(value.storeName? value.storeName : '')}  leftIcon={<IconDownload />} variant="subtle" color="dark"/> },
//   ]




//   const generateDataString = (store: string) => {
//     let dataString = '';

//     allocations.filter(allocation => allocation.storeName.includes(store)).forEach(allocation => {
//       let i = allocation.storeName.indexOf(store);
//       allocation.sizeQuantities.forEach((sizes, storeIndex) => {
//         if (i === storeIndex) {
//           sizes.forEach((size, sizeIndex) => {
//             // Access the corresponding SKU using the sizeIndex within this store's context
//             const sku = allocation.skuNumbers && allocation.skuNumbers.length > sizeIndex ? allocation.skuNumbers[sizeIndex] : 'N/A';
//             // Check if the quantity is greater than 0 and SKU is available before adding to the string
//             if (size.quantity > 0 && sku !== 'N/A') {
//               dataString += `${sku},${size.quantity},,\n`; // Format the string as needed
//             }
//           });
//         }
//       });
//     });
//     return dataString;
//   };

//   const handleDownload = (store: string) => {
//     const dataString = generateDataString(store);

//     // Check if the dataString is empty
//     if (!dataString) {
//       alert('No data available to download.');
//       return;
//     }

//     // Format the filename to include poNo and storeName, replacing any spaces or invalid characters
//     const formattedPoNo = poNo.replace(/[^a-zA-Z0-9]/g, '_');
//     const formattedStoreName = store.replace(/[^a-zA-Z0-9]/g, '_');
//     const fileName = `${formattedPoNo}_${formattedStoreName}.txt`;

//     const blob = new Blob([dataString], { type: 'text/plain' });
//     const url = URL.createObjectURL(blob);
//     const link = document.createElement('a');
//     link.href = url;
//     link.download = fileName; // Name of the file to be downloaded using formatted poNo and storeName
//     link.click();
//     URL.revokeObjectURL(url); // Clean up the URL object
//   };



//   return (
//     <div style={{ marginTop: '100px', padding: '20px' }}>
//       <Homepage setNavbarOpened={() => setNavbarOpened(!navbarOpened)} />
//         <Container style={{ marginBottom: '10px' }}>
//           <TextInput placeholder="Enter PO Number" value={poNo} onChange={(e) => setPoNo(e.target.value)} style={{ marginBottom: 20 }} />
//           <Group style={{ justifyContent: 'center' }}>
//             <Button onClick={fetchData} color="blue" style={{ marginRight: 10 }}>GET TR DATA</Button>
//           </Group>
//         </Container>
//       <Paper px="md" shadow="xl">
//         {tableData && 
//         <DataTable 
//         records={tableData.filter(record => record.totalQty.reduce((a, b) => a + b, 0) > 0)} 
//         columns={tableColumns} 
//         withBorder 
//         withColumnBorders
//         rowExpansion={expandableRows}
//         onRowClick={(row) => toggleRowExpansion(row.storeName)}
//         />}
//       </Paper>
//     </div>
//   );
// };

// export default Transfer;
import React, { useEffect, useState } from 'react';
import { TextInput, Button, Paper, Table, Center, Text, Group, Container, Flex, Collapse } from '@mantine/core';
import { DataTable, DataTableColumn } from 'mantine-datatable';
import axios from 'axios';
import Homepage from '../components/Navigation/parentHome';
import { STORE_PAIRS } from '../Contant/component';
import { IconDownload } from '@tabler/icons-react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { time } from 'console';
 
 
interface SizeQuantity {
  size: string;
  quantity: number;
  sku: string;
}

interface StyleData {
  supplier_name: string;
  style_no: string;
  description: string;
  color: string;
  cost: number;
  msrp: number;
  notes: string;
  total_qty: number;
  location: string;
  first_name: string;
}

interface AllocationData {
  allocation_id: number;
  storeName: string[];
  style_no: string;
  supplierName: string;
  poNo: string;
  status: boolean[];
  skuNumbers: string[];
  initial: boolean[];
  sizeQuantities: SizeQuantity[][];
  styles: StyleData;
  PurchaseOrders: PurchaseOrderData;
}

interface PurchaseOrderData {

  po_id: string;
  po_no: string;
}

interface TableData {
  storeName: string;
  styleNo: string[];
  supplierName: string;
  sizeQuantities: SizeQuantity[][];
  poNo: string;
  cost: number[];
  msrp: number[];
  totalQty: number[];
  status: boolean[];
  skuNumbers: string[][];
  color: string[];
  description: string[];
  notes: string[];

}

const Transfer: React.FC = () => {
  const [allocations, setAllocations] = useState<AllocationData[]>([]);
  const [poNo, setPoNo] = useState<string>('');
  const [storeName, setStoreName] = useState<string>('');
  const [navbarOpened, setNavbarOpened] = useState(false);
  const [storeList, setStoreList] = useState<string[]>(Object.keys(STORE_PAIRS));
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [openedRows, setOpenedRows] = useState<{ [index: number]: boolean }>({});
  const fetchData = async () => {
    try {
      const response = await axios.get(`/allocation/tr`, {
        params: { poNo },
        withCredentials: true
      });
      setAllocations(response.data);

      await setTableData(storeList.map((store) => {
        let data = response.data.filter((data: any) => data.storeName.includes(store));
        return {
          storeName: store,
          styleNo: data.map((data: any) => data.styles.style_no),
          supplierName: (new Set(data.map((data: any) => data.supplierName))).values().next().value,
          sizeQuantities: data.map((data: any) => data.sizeQuantities[data.storeName.indexOf(store)]),
          poNo: (new Set(data.map((data: any) => data.PurchaseOrders.po_no))).values().next().value,
          cost: data.map((data: any) => data.styles.cost),
          msrp: data.map((data: any) => data.styles.msrp),
          color: data.map((data: any) => data.styles.color),
          description: data.map((data: any) => data.styles.description),
          notes: data.map((data: any) => data.styles.notes),
          totalQty: data.map((data: any) => data.sizeQuantities[data.storeName.indexOf(store)].reduce((total: number, item: { quantity: number; }) => total + item.quantity, 0)),
          status: data.map((data: any) => {
            if (data.status[data.storeName.indexOf(store)] === true) {
              return 'Allocated';
            } else {
              return 'Pending';
            }
          }),
          skuNumbers: data.map((data: any) => data.skuNumbers),
        };
      }));
    } catch (error) {
      console.error(error);
    }
  };

  // Function to toggle the expansion state of a specific row
  const toggleRow = (index: number) => {
    setOpenedRows((prevOpenedRows) => ({
      ...prevOpenedRows,
      [index]: !prevOpenedRows[index], // Toggle the state of the clicked row
    }));
  };
  const toggleRowExpansion = (storeName: string) => {
    if (storeName) {
      setStoreName(storeName);
    }
  };

  const expandableRows = {
    content: ({ record }: { record: TableData }) => (
      record.storeName === storeName && (
        <Table>
          <thead >
            <tr style={{ backgroundColor: '#999999' }}>
              <th>Style No</th>
              <th>Supplier Name</th>
              <th>Total Quantity</th>
              <th>Status</th>
              <th>Cost</th>
              <th>MSRP</th>
              <th>Description</th>
            </tr>
          </thead>
          {record.sizeQuantities.map((sizeQuantities, index) => {
            return (
              <tbody>
                <tr key={index} onClick={() => toggleRow(index)}>
                  <td>{record.styleNo[index]}</td>
                  <td>{record.supplierName}</td>
                  <td>{record.totalQty[index]}</td>
                  <td>{record.status[index]}</td>
                  <td>{record.cost[index]}</td>
                  <td>{record.msrp[index]}</td>
                  <td>{record.description[index]}</td>
                </tr>
                <tr>
                  <td colSpan={7}>
                    <Collapse in={openedRows[index]}>
                      <Table style={{ width: '100%' }}>
                        <thead>
                          <tr style={{ backgroundColor: '#cccccc' }}>
                            <th style={{ textAlign: 'center', fontWeight: 'bold' }}>Sku</th>
                            <th style={{ textAlign: 'center', fontWeight: 'bold' }}>Size</th>
                            <th style={{ textAlign: 'center', fontWeight: 'bold' }}>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(record.skuNumbers[index])
                            ? record.skuNumbers[index].map((sku: string, i: number) => (
                              <tr style={{
                                textAlign: 'center',
                                color: (sku === 'N/A' ? 'red' : 'black'),
                                fontWeight: (sku === 'N/A' ? 'bold' : ''),
                              }}
                                key={i + '-' + sku}>
                                <td style={{ textAlign: 'center' }}>{sku || 'N/A'}</td>
                                <td>{record.sizeQuantities[index][i].size}</td>
                                <td>{record.sizeQuantities[index][i].quantity}</td>
                              </tr>
                            ))
                            : null}
                        </tbody>
                      </Table>
                    </Collapse>
                  </td>
                </tr>
              </tbody>
            )
          })}

        </Table>
      )
    ),
    expandOnClick: false
  };


  const tableColumns: DataTableColumn<TableData>[] = [
    { accessor: 'storeName', title: 'Store Name' },
    {
      accessor: 'storeName', title: 'Download TR file',
      render: (value) => <Button onClick={() => handleDownload(value.storeName ? value.storeName : '')} leftIcon={<IconDownload />} variant="subtle" color="dark" />
    },
  ]




  const generateDataString = (store: string) => {
    let dataString = '';

    allocations.filter(allocation => allocation.storeName.includes(store)).forEach(allocation => {
      let i = allocation.storeName.indexOf(store);
      allocation.sizeQuantities.forEach((sizes, storeIndex) => {
        if (i === storeIndex) {
          sizes.forEach((size, sizeIndex) => {
            // Access the corresponding SKU using the sizeIndex within this store's context
            const sku = allocation.skuNumbers && allocation.skuNumbers.length > sizeIndex ? allocation.skuNumbers[sizeIndex] : 'N/A';
            // Check if the quantity is greater than 0 and SKU is available before adding to the string
            if (size.quantity > 0 && sku !== 'N/A') {
              dataString += `${sku},${size.quantity},,\n`; // Format the string as needed
            }
          });
        }
      });
    });
    return dataString;
  };

  const handleDownload = (store: string) => {
    const dataString = generateDataString(store);

    // Check if the dataString is empty
    if (!dataString) {
      alert('No data available to download.');
      return;
    }

    // Format the filename to include poNo and storeName, replacing any spaces or invalid characters
    const formattedPoNo = poNo.replace(/[^a-zA-Z0-9]/g, '_');
    const formattedStoreName = store.replace(/[^a-zA-Z0-9]/g, '_');
    const fileName = `${formattedPoNo}_${formattedStoreName}.txt`;

    const blob = new Blob([dataString], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName; // Name of the file to be downloaded using formatted poNo and storeName
    link.click();
    URL.revokeObjectURL(url); // Clean up the URL object
  };


  const handleDownloadAll = async () => {
    const zip = new JSZip();
    const allStores = tableData.filter(data => data.totalQty.reduce((a, b) => a + b, 0) > 0).map(data => data.storeName);
    if(allStores.length === 0){
      alert('No data available to download.');
      return;
    }
    for (const store of allStores) {
      const dataString = generateDataString(store);
  
      // Check if the dataString is empty
      if (!dataString) {
        alert('No data available to download for some stores.');
        return;
      }
  
      // Format the filename to include poNo and storeName, replacing any spaces or invalid characters
      const formattedPoNo = poNo.replace(/[^a-zA-Z0-9]/g, '_');
      const formattedStoreName = store.replace(/[^a-zA-Z0-9]/g, '_');
      const fileName = `${formattedPoNo}_${formattedStoreName}.txt`;
  
      // Add the file to the ZIP
      zip.file(fileName, dataString);
    }
  
    // Generate the ZIP file and trigger the download
    const formattedPoNo = poNo.replace(/[^a-zA-Z0-9]/g, '_');
    const fileName = `${formattedPoNo}_ALL_STORES.zip`;
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, fileName);
  };


  return (
    <div style={{ marginTop: '100px', padding: '20px' }}>
      <Homepage setNavbarOpened={() => setNavbarOpened(!navbarOpened)} />
      <Container style={{ marginBottom: '10px' }}>
        <TextInput placeholder="Enter PO Number" value={poNo} onChange={(e) => setPoNo(e.target.value)} style={{ marginBottom: 20 }} />
        <Group style={{ justifyContent: 'center' }}>
          <Button onClick={fetchData} color="blue" style={{ marginRight: 10 }}>GET TR DATA</Button>
          <Button onClick={handleDownloadAll} color="green"><IconDownload />Download All</Button>
        </Group>
      </Container>
      <Paper px="md" shadow="xl">
        {tableData &&
          <DataTable
            records={tableData.filter(record => record.totalQty.reduce((a, b) => a + b, 0) > 0)}
            columns={tableColumns}
            withBorder
            withColumnBorders
            rowExpansion={expandableRows}
            onRowClick={(row) => toggleRowExpansion(row.storeName)}
          />}
      </Paper>
    </div>
  );
};

export default Transfer;

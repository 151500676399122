import React, { useEffect, useState } from 'react';
import {
    DataGrid,
    highlightFilterValue,
    stringFilterFn,
} from 'mantine-data-grid';

interface PoNoData {
    poNo: string;
    status: string;
}

const ListPoNo: React.FC = () => {
    const [poNoData, setPoNoData] = useState<PoNoData[]>([]);
    const fetchData = async () => {
        try {
            // const response = await fetch('/po/poNo', { credentials: 'include' });
            const response = await fetch('/allocation/getPoNo', { credentials: 'include' });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            data.sort((a: PoNoData, b: PoNoData) => {
                if (a.status === 'In progress' && b.status !== 'In progress') {
                    return -1;
                } else if (a.status === 'Pending' && b.status !== 'Pending' && b.status !== 'In progress') {
                    return -1;
                } else {
                    return 0;
                }
            }
            );
            setPoNoData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div style={{ height: 400, width: '95%' }}>
            <DataGrid
                data={poNoData}
                striped
                highlightOnHover
                withGlobalFilter
                withPagination
                withColumnFilters
                withSorting
                styles={ {
                    table: {
                        border: '1px solid black',
                        radius: '5px',
                    },
                    thead: {
                        border: '1px solid black',
                        backgroundColor: 'gold',
                    },
                }}
                 columns={[
                    {
                        accessorKey: 'poNo',
                        header: 'Po No',
                        filterFn: stringFilterFn,
                        size: 200,
                        cell: highlightFilterValue,
                    },
                    {
                        accessorKey: 'status',
                        filterFn: stringFilterFn,
                        size: 70,
                    },
                ]}
                initialState={{
                    pagination: {
                        pageIndex: 0,
                    },
                }}
            />
        </div>
    );
};

export default ListPoNo;
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Text, Grid, Container, TextInput, Col, Paper, Table, Select } from '@mantine/core';
import axios, { AxiosError } from 'axios';
import Homepage from '../Navigation/parentHome';
import AllocationInvoice from './allocationInvoice';
import { AllocationData } from './allocationData';
import CheckExisting from './checkExisting';

interface HeaderProps {

  styleNo: string;
  setStyleNo: (value: string) => void;
  styleId: number;
  setStyleId: (value: number) => void;
  poId: string;
  setPoId: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  color: string;
  setColor: (value: string) => void;
  cost: string;
  setCost: (value: string) => void;
  msrp: string;
  setMsrp: (value: string) => void;
  notes: string;
  setNotes: (value: string) => void;
  onSave: () => void;
  supplierName: string;
  setSupplierName: (value: string) => void;
  poNo: string;
  setPoNo: (value: string) => void;
  sizeInput: string;
  setSizeInput: (value: string) => void;
  sizes: string[]
  setSizes: (value: string[]) => void
  binLocation: string
  setBinLocation: (value: string) => void
  createResponse: {
    poId: string;
    poNo: string;
    styleId: number;
    styleNo: string;
  }
  // setCreateResponse: (value: any) => void
}

type Suggestion = { label: string; value: string };

const Header: React.FC<HeaderProps> = ({
  supplierName,
  setSupplierName,
  styleNo,
  setStyleNo,
  styleId,
  setStyleId,
  poId,
  setPoId,
  description,
  setDescription,
  color,
  setColor,
  cost,
  setCost,
  msrp,
  setMsrp,
  poNo,
  setPoNo,
  notes,
  setNotes,
  onSave,
  sizeInput,
  setSizeInput,
  sizes,
  setSizes,
  createResponse,
  binLocation,
  setBinLocation

}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [supplierData, setSupplierData] = useState<any>(null);
  const [supplierNameId] = useState(uuid());
  const [styleNoId] = useState(uuid());
  const [descriptionId] = useState(uuid());
  const [colorId] = useState(uuid());
  const [costId] = useState(uuid());
  const [msrpId] = useState(uuid());
  const [poNoId] = useState(uuid());
  const [notesId] = useState(uuid());
  const [allSuppliers, setAllSuppliers] = useState<Suggestion[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [navbarOpened, setNavbarOpened] = useState(false);
  // const [entireData, setEntireData] = useState<any[] | null>(null);
  const [styleList, setStyleList] = useState<string[] | null>(null);
  const [invoicePoId, setInvoicePoId] = useState<string>('');
  const [poNoList, setPoNoList] = useState<any[]>([]);
  const [poNoListSearch, setPoNoListSearch] = useState<string[]>([]);
  const [poNoSelected, setPoNoSelected] = useState<string>('');
  const [checkStyleNo, setCheckStyleNo] = useState<string>('');
  const [ trigger, setTrigger] = useState(false);

  const fetchEntireData = async (value: string) => {
    try {
      setStyleList([]); // Clear the styleList
      let poId = poNoList.find((item) => item.poNo === value.toUpperCase())?.poId || '';
      const response = await fetch(`/allocation/poNo/${poId}`, {credentials:'include'});
      const data = await response.json();
      if (response.status === 404) {
        setPoNo(value.toUpperCase());
        // setInvoicePoId(value.toUpperCase());
        // setPoNoListSearch( [...poNoListSearch,value.toUpperCase()]);
        return; // Exit early, don't proceed with setting state or parsing JSON
      }
      // setEntireData(Object.values(data));  // Convert into an array
      setStyleList(data ? data.map((item: AllocationData) => item.styles.style_no) : []);
    } catch (error) {
      console.error(' Error fetching allocation data: ', error);
    }
  };

  const handleNotesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNotes(event.target.value.toUpperCase());
  };


  useEffect(() => {
    const fetchAllSuppliers = async () => {
      try {
        const response = await axios.get('/supplier/');
        const suppliers: Suggestion[] = response.data.map((supplier: any) => ({
          label: supplier.supplier_name,
          value: supplier.supplier_name,
        }));
        setAllSuppliers(suppliers);
      } catch (error) {
        console.error('Error fetching all suppliers:', error);
      }
    };

    fetchAllSuppliers();
    fetchPoNoList();
  }, []);

  const fetchPoNoList = async () => {
    setPoNoList([]);
    try {
      const response = await fetch('/allocation/getPoNo', { credentials: 'include' });
      const data = await response.json();
      await setPoNoList(data.map((item: { poNo: string, po_id: string }) => ({ poNo: item.poNo, poId: item.po_id })));
      await setPoNoListSearch(data.map((item: { poNo: string, po_id: string }) => item.poNo));
    } catch (error) {
      console.error('Error fetching poNo list:', error);
    }
  }



  const fetchSupplierByName = async (name: string) => {
    try {
      const response = await axios.get(`/supplier`);
      setSupplierData(response.data);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.status === 404) {
        alert('Supplier not found');
      } else {
        console.error('Error fetching supplier data:', axiosError);
      }
    }
  };

  const handlePoNoChange = async (value: string | null) => {
    if (value) {
      setPoNoSelected(value.toUpperCase());
      let tempPoNo = { poId: '', poNo: '' };
      if (poNoList.length !== 0) {
        tempPoNo = poNoList.find((item) => item.poNo === value.toUpperCase()) || { poId: '', poNo: value.toUpperCase() };
        if (tempPoNo.poId === '') {
          tempPoNo = { poId: '', poNo: value.toUpperCase() };
          setPoNoListSearch(poNoListSearch.find((item) => item === value.toUpperCase()) ? [...poNoListSearch] : [...poNoListSearch, value.toUpperCase()]);
        }
      }
      setPoNo(value.toUpperCase());
      // setPoNo();
      setPoId(tempPoNo.poId);
      setInvoicePoId(tempPoNo.poId);
      fetchEntireData(value);
    }
  };
useEffect(() => {
  if (createResponse) {
    setPoNoList([...poNoList, { poId: createResponse.poId, poNo: createResponse.poNo }]);
    setPoId(createResponse.poId);
    setPoNo(createResponse.poNo);
    setInvoicePoId(createResponse.poId);
    fetchEntireData(createResponse.poNo);
    setTrigger(!trigger);
  }
},[createResponse]);

  const handleSupplierChange = async (value: string) => {
    setSupplierName(value);

    // Check if the supplier is not in the current list of suppliers
    const isSupplierNew = allSuppliers.every(supplier => supplier.value !== value);

    // If the supplier is new, create it
    if (isSupplierNew) {
      try {
        await axios.post('/supplier/add', { supplier_name: value });
        // Add the new supplier to the state
        setAllSuppliers(prev => [...prev, { label: value, value }]);
      } catch (error) {
        console.error('Error creating new supplier:', error);
      }
    }

    // Fetch the supplier by name, regardless of whether it's new or not
    fetchSupplierByName(value);
  };

  const handleStyleNoChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStyleNo(event.target.value.toUpperCase());
  };
  const handleStyleNoBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    await setCheckStyleNo('');
    if (styleList && styleList.includes(event.target.value.toUpperCase())) {
      event.target.focus();
      event.preventDefault();
      alert('Style No already exists for this PO...!');
      setStyleNo('');
      setCheckStyleNo(event.target.value);
    } else {
      setStyleNo(event.target.value.toUpperCase());
      setCheckStyleNo(event.target.value);
    }
  }
  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value.toUpperCase());
  };

  const handleColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value.toUpperCase());
  };

  const handleCostChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCost(event.target.value);
  };

  const handleMSRPChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMsrp(event.target.value);
  };

  const handleBinLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBinLocation(event.target.value.toUpperCase());
  };

  return (
    <div>
      <Homepage setNavbarOpened={setNavbarOpened} />
      <Paper p="xs" shadow="xs" style={{ marginBottom: '1rem', marginTop: "50px" }}>
        <Text align="center" size="xl" weight={500}>
          Store Allocation Calculator
        </Text>
      </Paper>
      <Grid>
        <Col span={3}>
          <Paper p="md" shadow="xs" radius={'lg'} style={{ marginBottom: '1rem', marginTop: "50px", marginLeft: "80px" }}>
            <CheckExisting 
            styleNo={checkStyleNo} 
            setColor={setColor}
            color={color}
            setDescription={setDescription}
            description={description}
            sizeInput={sizeInput}
            setSizeInput={setSizeInput}
            setSizes={setSizes}
            binLocation={binLocation}
            setBinLocation={setBinLocation}
            />
          </Paper>
        </Col>
        <Col span={4}>
          <Container>
            {/* <Table id="table-to-print-2" style={{ width: '100%' }}> */}
            <Grid gutter="xs" justify="center" >
              <Col span={6}>
                {/* <Text ta="center" fz="lg" fw={600} style={{ width: '100%', textAlign: 'left', alignItems: 'left' }}>PO No <span style={{ color: 'red' }}>*</span></Text> */}
                {/* <Select
                  placeholder='Select PO No'
                  id={poNoId}
                  value={poNoSelected.toUpperCase()}
                  data={poNoListSearch}
                  onChange={handlePoNoChange}
                  searchable
                  creatable
                  getCreateLabel={(query) => `+ Create ${query}`}
                  style={{ width: '80%' }}
                  required
                /> */}
              </Col>
            </Grid>
            <Grid gutter="xl" justify="center">
              <Col span={12}>
                <Grid style={{marginTop: "20px" }} >
                <Text ta="center" fz="lg" fw={600} style={{ width: '100%', textAlign: 'left', alignItems: 'left', marginLeft: "10px" }}>PO No <span style={{ color: 'red' }}>*</span></Text>
                <Select
                  placeholder='Select PO No'
                  id={poNoId}
                  value={poNoSelected.toUpperCase()}
                  data={poNoListSearch}
                  onChange={handlePoNoChange}
                  searchable
                  creatable
                  getCreateLabel={(query) => `+ Create ${query}`}
                  style={{ width: '80%', marginLeft: "10px" }}
                  required
                />
                <Col span={6}>
                <Text ta="center" fz="lg" fw={600} style={{ width: '100%', textAlign: 'left', alignItems: 'left' }}>Supplier<span style={{ color: 'red' }}>*</span></Text>
                <Select
                  placeholder="Select supplier"
                  id={supplierNameId}
                  value={supplierName.toUpperCase()}
                  data={allSuppliers}
                  searchable
                  creatable
                  onCreate={(value) => ({ value, label: value })}
                  onChange={handleSupplierChange}
                  getCreateLabel={(query) => `+ Create ${query}`}
                  style={{ width: '80%' }}
                  maxDropdownHeight={50}
                  required
                />
                <Text ta="center" fz="lg" fw={600} style={{ width: '100%', textAlign: 'left', alignItems: 'left' }}>Style No. <span style={{ color: 'red' }}>*</span></Text>
                <TextInput
                  id={styleNoId}
                  value={styleNo.toUpperCase()}
                  placeholder="Enter style number"
                  onChange={handleStyleNoChange}
                  onBlur={handleStyleNoBlur}
                  style={{ width: '80%' }}
                  required
                />

                <TextInput
                  label={<Text ta="center" fz="lg" fw={600}>Description</Text>}
                  id={descriptionId}
                  value={description.toUpperCase()}
                  placeholder="Enter description"
                  onChange={handleDescriptionChange}
                  style={{ width: '80%' }}
                />
                <TextInput
                  label={<Text ta="center" fz="lg" fw={600}>Notes</Text>}
                  id={notesId}
                  value={notes}
                  placeholder="Enter notes"
                  onChange={handleNotesChange}
                  style={{ width: '80%' }}
                />
              </Col>
              <Col span={6}>
                <TextInput
                  label={<Text ta="center" fz="lg" fw={600}>Color</Text>}
                  id={colorId}
                  value={color.toUpperCase()}
                  placeholder="Enter color"
                  onChange={handleColorChange}
                  style={{ width: '80%' }}
                />
                <TextInput
                  label={<Text ta="center" fz="lg" fw={600}>Cost</Text>}
                  id={costId}
                  value={cost}
                  placeholder="Enter cost"
                  onChange={handleCostChange}
                  style={{ width: '80%' }}
                />
                <TextInput
                  label={<Text ta="center" fz="lg" fw={600}>MSRP</Text>}
                  id={msrpId}
                  value={msrp}
                  placeholder="Enter MSRP"
                  onChange={handleMSRPChange}
                  style={{ width: '80%' }}
                />
                <TextInput
                  label={<Text ta="center" fz="lg" fw={600}>Bin Location</Text>}
                  // id={binLocationId}
                  value={binLocation}
                  placeholder="Enter bin location"
                  onChange={handleBinLocationChange}
                  style={{ width: '80%' }}
                />
              </Col>
                </Grid>
              </Col>
            </Grid>
          </Container>
        </Col>
        <Col offset={1} span={3} style={{ marginTop: '20px' }}>
        {invoicePoId && (<AllocationInvoice values={{ invoicePoId, trigger }} />)}
        </Col>
      </Grid>
    </div>
  );
};

export default Header;
import { Button } from '@mantine/core';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from 'pdfmake/interfaces';
import React, { useEffect, useState } from 'react';
import { AllocationData } from '../Calculator/allocationData';
import JsBarcode from 'jsbarcode';
import { text } from 'stream/consumers';

interface PrintStoreLabelsProps {
    props: {
        allocationData: any;
        styleData: any;
        orientation: 'portrait' | 'landscape';
        manualsizeQty: string;
        imageData: string;
        storeTotals: number[];
        overstockTotal: number;
        styleNo: string;
        itemType: 'clothing' | 'footware';
        btnLabel?: string;
        singlePortraint?: boolean;
        totalAllocation: number;
    };
}
const PrintStoreLabels: React.FC<PrintStoreLabelsProps> = ({ props }) => {
    const { allocationData, styleData, orientation, manualsizeQty, imageData, storeTotals, overstockTotal, styleNo, itemType, btnLabel, singlePortraint, totalAllocation } = props;
    const [buttonLabel, setButtonLabel] = useState<string>('Print Labels');

    useEffect(() => {
        if (btnLabel) {
            setButtonLabel(btnLabel);
        }
    }, [btnLabel]);

    const generateCode = (): string => {


        // Assuming allocationData.storeName and storeTotals are available in the context
        let targetStoreIndex = allocationData.storeName.findIndex((store: string) => store.toUpperCase() === 'STEELES');
        let code = 0;
        if (targetStoreIndex === -1 || storeTotals[targetStoreIndex] === 0) { // If "Steeles" not found
            code = overstockTotal + totalAllocation;
        } else {
            let storeTotal = 0;
            if (targetStoreIndex !== -1) {

                // Use the total of the found store
                storeTotal = storeTotals[targetStoreIndex];
            } else if (allocationData.storeName.length === 1) {
                // If there is only one store, use its total regardless of the name
                storeTotal = storeTotals[0];
            } else {
                // If all stores have zero quantity, use overstock
                const allStoresZero = storeTotals.every(total => total === 0);
                if (allStoresZero) {
                    storeTotal = overstockTotal;
                } else {
                    // Find the first non-zero total if exists
                    storeTotal = storeTotals.find(total => total > 0) || 0;
                }
            }
            code = overstockTotal + storeTotal;
        }

        // Proceed with existing logic to calculate the code
        const date = new Date();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        let cost: string = '';
        const costValue = parseFloat(styleData.cost.toString());
        if (isNaN(costValue)) {
            return 'Cost is not a number';
        } else {
            if (costValue < 1) {
                // For costs less than $1
                cost = Math.round(costValue * 100).toString().padStart(4, '0');
            } else if (!Number.isInteger(costValue)) {
                // For decimal costs
                const cent = Math.round(costValue * 100);
                cost = cent.toString().padStart(4, '0');
            } else {
                // For integer costs
                cost = ((costValue * 100) / 100).toString().padStart(4, '0');
            }
        }

        const formattedCode = `${code.toString().padStart(3, '0')} - ${month.toString().padStart(2, '0')}${year.toString().slice(-2)}${cost.padStart(4, '0')}`;
        return formattedCode;
    };

    const generateBarcode = (data: string) => {
        const canvas = document.createElement('canvas'); // Create a canvas element
        JsBarcode(canvas, data); // Generate the barcode
        return canvas.toDataURL(); // Convert the canvas to a data URL
    };
    const generatePDF = () => {

        const portraitMode = orientation === 'portrait';

        let contentData: Content[] = [];
        let contentData1: Content[] = [];
        contentData = [
            {
                columns: [
                    {
                        text: `${styleData.supplier_name}`,
                        fontSize: portraitMode ? 14 : 20,
                        alignment: 'left',
                        margin: [-15, -30, 0, 10]
                    },
                    {
                        text: `${manualsizeQty}`,
                        fontSize: portraitMode ? 14 : 20,
                        alignment: 'right',
                        margin: [-15, -30, 0, 10]
                    }
                ]
            },
            {
                columns: [
                    {

                        text:
                            `${styleData.description}`,
                        fontSize: portraitMode ? 30 : 35,
                        alignment: 'center',
                        margin: [1, 3, 3, 3]

                    },
                ],
            },
            {
                columns: [
                    {
                        text:
                            `#${styleData.style_no}`,
                        fontSize: portraitMode ? 35 : 65,
                        alignment: 'center',
                        bold: true,
                        margin: [1, 3, 3, 3],
                    },
                ],
            },
            {
                columns: [
                    {
                        text: `${styleData.color}`,
                        width: '*',
                        fontSize: portraitMode ? 16 : 20,
                        alignment: 'center',
                        margin: [3, 3, 3, 3]
                    },
                    {
                        text: `R - ${parseFloat(styleData.msrp).toFixed(0)}`,
                        width: '*',
                        fontSize: portraitMode ? 16 : 20,
                        alignment: 'center',
                        margin: [3, 3, 3, 3]
                    },
                ],
            },
            {
                columns: [
                    {
                        text:
                            `${generateCode()}`,
                        fontSize: portraitMode ? 16 : 20,
                        alignment: 'center',
                        margin: [0, 3, 3, 3]

                    },
                ],
            }, // Add quantity data
            {


                image: imageData,
                width: portraitMode ? 300 : 480,
                height: portraitMode ? 150 : 280,
                margin: [0, 0, 0, 50],
                alignment: 'center'


            },
            {
                image: generateBarcode(styleNo),
                width: portraitMode ? 100 : 150,
                height: portraitMode ? 30 : 45,
                margin: portraitMode ? [0, -70, -15, 0] : [0, -70, -20, 0],
                alignment: 'right'
            }
            // Add overstock data
        ];

        contentData1 = [
            {
                columns: [
                    {
                        text: `${styleData.supplier_name}`,
                        fontSize: portraitMode ? 14 : 20,
                        alignment: 'left',
                        margin: [-15, -30, 0, 10]
                    },
                    {
                        text: `${manualsizeQty}`,
                        fontSize: portraitMode ? 14 : 20,
                        alignment: 'right',
                        margin: [-15, -30, 0, 10]
                    }
                ]
            },
            {
                columns: [
                    {

                        text:
                            `${styleData.description}`,
                        fontSize: portraitMode ? 30 : 35,
                        alignment: 'center',
                        margin: [3, 3, 3, 3]

                    },
                ],
            },
            {
                columns: [
                    {
                        text:
                            `#${styleData.style_no}`,
                        fontSize: portraitMode ? 35 : 65,
                        alignment: 'center',
                        bold: true,
                        margin: [3, 3, 3, 3]

                    },
                ],
            },
            {
                columns: [
                    {
                        text: `${styleData.color}`,
                        width: '*',
                        fontSize: portraitMode ? 16 : 20,
                        alignment: 'center',
                        margin: [3, 3, 3, 3]
                    },
                    {
                        text: `R - ${parseFloat(styleData.msrp).toFixed(0)}`,
                        width: '*',
                        fontSize: portraitMode ? 16 : 20,
                        alignment: 'center',
                        margin: [3, 3, 3, 3]
                    }
                ],
            },
            {
                columns: [
                    {
                        text:
                            `${generateCode()}`,
                        fontSize: portraitMode ? 16 : 20,
                        alignment: 'center',
                        margin: [3, 3, 3, 3]

                    },
                ],
            }, // Add quantity data

            {

                image: imageData,
                width: portraitMode ? 300 : 450,
                height: portraitMode ? 140 : 300,
                margin: [0, 0, 0, 50],
                alignment: 'center'

            },
            {
                image: generateBarcode(styleNo),
                width: portraitMode ? 100 : 170,
                height: portraitMode ? 30 : 50,
                margin: portraitMode ? [0, -70, -15, 0] : [0, -35, -15, 0],
                alignment: 'right'
            }
            // Add overstock data
        ];

        let content: Content[];

        if (portraitMode) {
            // Add a page break and duplicate the content
            contentData.push({ text: '', marginTop: 40 });
            content = [...contentData, ...contentData1];
        } else {
            content = contentData;
        }

        const docDefinition: TDocumentDefinitions = {
            content: content,
            pageSize: 'LETTER',
            pageOrientation: orientation,
        };

        pdfMake.createPdf(docDefinition).open();
    };

    const storeLabelPDF = () => {
        const portraitMode = orientation === 'portrait';
        const iType = itemType === 'clothing'
        let contentData: Content[] = [];
        let content: Content[];
        let currentContentData: Content[] = [];
        let currentContentData1: Content[] = [];
        let lastPage = allocationData.storeName.length;
        allocationData.storeName.forEach((store: string, index: number) => {
            let rows: string[] = [];
            allocationData.sizeQuantities[allocationData.storeName.indexOf(store)].forEach((sizeObj: any, index: number) => {
                let str = '';
                if (sizeObj.quantity > 0) {
                    str = `${sizeObj.quantity} - ${sizeObj.size}`
                    rows = [...rows, str];
                }
            })
            currentContentData = [
                {
                    columns: [
                        {
                            text: `${styleData.supplier_name}`,
                            fontSize: portraitMode ? 14 : 20,
                            alignment: 'left',
                            margin: portraitMode ? [-15, -30, 0, 10] : [-15, -30, 0, 10]
                        },
                        {
                            text: `${store}`,
                            fontSize: portraitMode ? 18 : 30,
                            alignment: 'center',
                            bold: true,
                            color: 'red',
                            margin: portraitMode ? [0, -30, 0, 10] : [0, -30, 0, 10]
                        },
                        {
                            text: `${manualsizeQty}`,
                            fontSize: portraitMode ? 14 : 20,
                            alignment: 'right',
                            margin: portraitMode ? [0, -30, 0, 10] : [0, -30, 0, 10]
                        }
                    ],
                },
                {
                    columns: [
                        {
                            text:
                                `${styleData.description}`,
                            fontSize: portraitMode ? 20 : 35,
                            alignment: 'center',
                            margin: portraitMode ? [1, 3, 3, 3] : [1, 3, 3, 3],
                        },
                    ],
                },
                {
                    columns: [
                        {
                            text:
                                `#${styleData.style_no}`,
                            fontSize: portraitMode ? 30 : 65,
                            alignment: 'center',
                            bold: true,
                            margin: portraitMode ? [1, 3, 3, 3] : [1, 3, 3, 3],

                        },
                    ],
                },
                {
                    columns: [
                        {
                            text: `${styleData.color}`,
                            width: '*',
                            fontSize: portraitMode ? 16 : 20,
                            alignment: 'center',
                            margin: portraitMode ? [3, 3, 3, 3] : [3, 3, 3, 3]
                        },
                        {
                            text: `R - ${parseFloat(styleData.msrp).toFixed(0)}`,
                            width: '*',
                            fontSize: portraitMode ? 16 : 20,
                            alignment: 'center',
                            margin: portraitMode ? [3, 3, 3, 3] : [3, 3, 3, 3]
                        },
                    ],
                },
                {
                    columns: [
                        {

                            table: {

                                widths: ['auto', 'auto'], // Set the width of each column
                                body: [
                                    [
                                        {
                                            border: overstockTotal < 1 && (store === 'STEELES' || store === 'WEB') ? [true, true, true, true] : [false, false, false, false],
                                            text: generateCode().toString().split('-')[0],
                                            bold: true,
                                            fontSize: portraitMode ? 16 : 20,

                                        },
                                        {
                                            text: `- ${generateCode().toString().split('-')[1]}`,
                                            fontSize: portraitMode ? 16 : 20,
                                        }

                                    ]
                                ]
                            },
                            layout: {
                                defaultBorder: false,
                            },
                            alignment: 'center',
                            margin: [220, 3, 3, 3],
                        }
                    ]
                }, // Add quantity data //////////////////////////////
                {
                    columns: [
                        {
                            text: iType ? [{ text: 'Qty\u00A0\u00A0\u00A0\u00A0\u00A0Size \n', bold: true, decoration: 'underline' }, { text: `${rows.join('\n')}` }] : [{ text: 'Qty\u00A0\u00A0\u00A0\u00A0\u00A0Size \n', bold: true, decoration: 'underline' }],
                            fontSize: portraitMode ? 14 : 20,
                            alignment: 'left',
                            // bold: true,
                            margin: portraitMode ? [-15, 0, -30, 0] : [-15, 0, -30, 0],
                            width: portraitMode ? '26%' : '15%',
                        },
                        {
                            image: imageData,
                            width: portraitMode ? 250 : 430,
                            height: portraitMode ? 190 : 280,
                            margin: portraitMode ? [0, 0, 0, 50] : [-15, 0, 0, 50],
                            alignment: 'center'
                        },
                        [
                            store === 'STEELES' ? {
                                text: 'SAMPLE \n PULLED',
                                fontSize: portraitMode ? 20 : 30,
                                alignment: 'center',
                                bold: true,
                                color: 'red',
                                margin: portraitMode ? [-10, 0, -15, 0] : [0, 0, -20, 0],
                            }: '',
                            {
                                image: generateBarcode(styleNo),
                                width: portraitMode ? 80 : 150,
                                height: portraitMode ? 25 : 45,
                                margin: portraitMode ? [-10, 100, -15, 0] : [0, 150, -20, 0],
                                alignment: 'center'
                            }
                        ]
                    ],
                },
                {
                    text: '',
                    pageBreak: (lastPage - 1 === allocationData.storeName.indexOf(store)) ? undefined : (portraitMode ? undefined : 'after'),
                },
                // Add overstock data
            ];
            currentContentData1 = [
                {
                    columns: [
                        {
                            text: `${styleData.supplier_name}`,
                            fontSize: portraitMode ? 14 : 20,
                            alignment: 'left',
                            margin: portraitMode ? [-15, -30, 0, 10] : [-15, -30, 0, 10]
                        },
                        {
                            text: `${store}`,
                            fontSize: portraitMode ? 18 : 30,
                            alignment: 'center',
                            bold: true,
                            color: 'red',
                            margin: portraitMode ? [0, -30, 0, 10] : [0, -30, 0, 10]
                        },
                        {
                            text: `${manualsizeQty}`,
                            fontSize: portraitMode ? 14 : 20,
                            alignment: 'right',
                            margin: portraitMode ? [0, -30, 0, 10] : [0, -30, 0, 10]
                        }
                    ],
                },
                {
                    columns: [
                        {
                            text:
                                `${styleData.description}`,
                            fontSize: portraitMode ? 20 : 35,
                            alignment: 'center',
                            margin: [3, 3, 3, 3],
                        },
                    ],
                },
                {
                    columns: [
                        {
                            text:
                                `#${styleData.style_no}`,
                            fontSize: portraitMode ? 30 : 65,
                            alignment: 'center',
                            bold: true,
                            margin: portraitMode ? [3, 3, 3, 3] : [3, 3, 3, 3],

                        },
                    ],
                },
                {
                    columns: [
                        {
                            text: `${styleData.color}`,
                            width: '*',
                            fontSize: portraitMode ? 16 : 20,
                            alignment: 'center',
                            margin: portraitMode ? [3, 3, 3, 3] : [3, 3, 3, 3]
                        },
                        {
                            text: `R - ${parseFloat(styleData.msrp).toFixed(0)}`,
                            width: '*',
                            fontSize: portraitMode ? 16 : 20,
                            alignment: 'center',
                            margin: portraitMode ? [3, 3, 3, 3] : [3, 3, 3, 3]
                        },
                    ],
                },
                {

                    columns: [
                        {

                            table: {

                                widths: ['auto', 'auto'], // Set the width of each column
                                body: [
                                    [
                                        {
                                            border: overstockTotal < 1 && (store === 'STEELES' || store === 'WEB') ? [true, true, true, true] : [false, false, false, false], text: generateCode().toString().split('-')[0],
                                            bold: true,
                                            fontSize: portraitMode ? 16 : 20,

                                        },
                                        {
                                            text: `- ${generateCode().toString().split('-')[1]}`,
                                            fontSize: portraitMode ? 16 : 20,
                                        }

                                    ]
                                ]
                            },
                            layout: {
                                defaultBorder: false,
                            },
                            alignment: 'center',
                            margin: [220, 3, 3, 3],
                        }
                    ]
                }, // Add quantity data //////////////////////////////
                {
                    columns: [
                        {
                            text: iType ? [{ text: 'Qty\u00A0\u00A0\u00A0\u00A0\u00A0Size \n', bold: true, decoration: 'underline' }, { text: `${rows.join('\n')}` }] : [{ text: 'Qty\u00A0\u00A0\u00A0\u00A0\u00A0Size \n', bold: true, decoration: 'underline' }],
                            fontSize: portraitMode ? 14 : 20,
                            alignment: 'left',
                            // bold: true,
                            margin: portraitMode ? [-15, 0, -30, 0] : [-15, 0, -30, 0],
                            width: portraitMode ? '26%' : '15%',
                        },
                        {
                            image: imageData,
                            width: portraitMode ? 250 : 450,
                            height: portraitMode ? 190 : 300,
                            margin: portraitMode ? [0, 0, 0, 50] : [-15, 0, 0, 50],
                            alignment: 'center'
                        },
                        [
                            store === 'STEELES' ? {
                                text: 'SAMPLE \n PULLED',
                                fontSize: portraitMode ? 20 : 30,
                                alignment: 'center',
                                bold: true,
                                color: 'red',
                                margin: portraitMode ? [-10, 0, -15, 0] : [0, 0, -20, 0],
                            }: '',
                            {
                                image: generateBarcode(styleNo),
                                width: portraitMode ? 80 : 150,
                                height: portraitMode ? 25 : 45,
                                margin: portraitMode ? [-10, 100, -15, 0] : [0, 150, -20, 0],
                                alignment: 'center'
                            }
                        ]
                    ],
                },

                {
                    text: '',
                    pageBreak: (lastPage - 1 === allocationData.storeName.indexOf(store)) ? undefined : 'after',
                },
                // Add overstock data
            ];
            if (orientation === 'portrait') {
                if (singlePortraint) {
                    contentData = [...contentData, ...currentContentData];
                    if((index+1) % 2 === 0){
                        
                        contentData.push({ text: '', pageBreak: 'after' });
                    }

                } else {
                    contentData = [...contentData, ...currentContentData, ...currentContentData1];
                }
            } else {
                contentData = [...contentData, ...currentContentData];
            }
        })

        content = contentData;

        const storeLabelDocDefinition: TDocumentDefinitions = {
            content: content,
            pageSize: 'LETTER',
            pageOrientation: orientation,
        }
        pdfMake.createPdf(storeLabelDocDefinition).open();
    }

    const handlePDFDownload = () => {

        if (!imageData || imageData === '') {
            alert('Please upload image');
            return;
        }
        if (buttonLabel === 'Store Label') {
            storeLabelPDF();
            return;
        }
        if (itemType === 'clothing') {
            storeLabelPDF();
        }

        generatePDF();

    };
    return (
        <div>
            <Button onClick={handlePDFDownload}>{buttonLabel}</Button>
            {/* <Button onClick={downloadPDF}>Print Portrait</Button> */}
        </div>
    )
};

export default PrintStoreLabels;
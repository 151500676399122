import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Col, Container, Grid, Input, Table, TextInput, Text, Center, MantineProvider, Group, Select, Flex, Modal, Switch } from '@mantine/core';
import Homepage from '../Navigation/parentHome';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { RefreshContext } from '../privateRoute/RefreshContext';
import '../Styles/allocationCalculator.css';
import PrintStoreLabels from './printStoreLables';
import PrintAllocationPaper from './printAllocationPaper';
import { useDisclosure } from '@mantine/hooks';
import { ModalProvider } from '@mantine/core/lib/Modal/Modal.context';
import { DataGrid, stringFilterFn } from 'mantine-data-grid';
import featureFlags from '../../Authentication/FeatureFlag';


pdfMake.vfs = pdfFonts.pdfMake.vfs;



interface PoNoData {
  po_id?: string;
  poNo: string;
  status: string;
}


const AllocationComponent: React.FC = () => {
  const [allocationData, setAllocationData] = useState<any>(null);
  const [styleData, setStyleData] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [navbarOpened, setNavbarOpened] = useState(false);
  const [poId, setPoId] = useState<string>('');
  const [poNo, setPoNo] = useState<string>('');
  const [poNoSelected, setPoNoSelected] = useState<string>('');
  const [styleNo, setStyleNo] = useState<string>('');
  const [storeTotals, setStoreTotals] = useState<number[]>([]);
  const [totalAllocation, setTotalAllocation] = useState<number>(0);
  const [overstockTotal, setOverstockTotal] = useState<number>(0);
  const [isMounted, setIsMounted] = useState(false);
  const [notes, setNotes] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [imageData, setImageData] = useState<string>("");
  const [styleList, setStyleList] = useState<string[]>([]);
  const [entireData, setEntireData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [poNoList, setPoNoList] = useState<{ value: string; label: string }[]>([]);
  const [poNoData, setPoNoData] = useState<{ value: string; label: string }[]>([]);
  const [activeRow, setActiveRow] = useState<string | null>(null);
  const [manualsizeQty, setManualSizeQty] = useState<string>('');
  // Inside AllocationComponent component
  const { refresh, setRefresh } = useContext(RefreshContext);
  const [opened, { open, close }] = useDisclosure(true);
  const [itemType, setItemType] = useState<'clothing' | 'footware'>('clothing');
  const [singlePortraint, setSinglePortraint] = useState<boolean>(false);
  const [styleTable, setStyleTable] = useState<StyleTableData[]>([]);


  interface AllocationData {
    storeName: string[];
    sizeQuantities: SizeQuantity[][];
    supplierName: string;
    poNo: string;
    styles: StyleData;
  }


  interface StyleData {
    style_no: string;
  }


  interface SizeQuantity {
    size: string;
    quantity: number;
  }

  interface StoreData {
    store: string;
    styles: number; // Represents number of unique styles in the store
    quantity: number; // Total quantity of all styles in the store
  }

  interface StyleTableData {
    styleNo: string;
    status: 'Pending' | 'Done';
  }




  useEffect(() => {
    // Fetch allocation data when the component mounts
    fetchAllocationData(styleNo, poNo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const fetchAllocationData = async (styleNo: string, poNo: string) => {
    if (entireData != null) {
      await setAllocationData(entireData.filter((data: any) => data.styles.style_no === styleNo)[0]);
      await setStyleData(entireData.filter((data: any) => data.styles.style_no === styleNo)[0].styles);
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {      // Customize your message here
      const message = 'Are you sure you want to leave this page?';

      // Some browsers require a return value to display a custom message
      // This doesn't work in all browsers and may be ignored by some
      event.returnValue = message;

      // Return the message to display the dialog in some browsers
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  });

  const fetchEntireData = async () => {
    setIsMounted(true);
    try {
      const response = await fetch(`/allocation/poNo/${poId}`, { credentials: 'include' });
      const data = await response.json() as AllocationData[];
      if (isMounted) {
        await setEntireData(data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handlePoNoBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    fetchEntireData();
    setStyleList([]); // Clear the styleList
    return () => {
      setIsMounted(false);
    }
  }

  useEffect(() => {
    //Extracting style numbers from entire data
    if (entireData != null && entireData.length > 0) {
      setStyleTable([]);
      setStyleList([]);
      let tempStyleTable: StyleTableData[] = [];
      entireData?.forEach((item: any) => {
        if (item) {
          item.styles.style_no && setStyleList((prevList: string[] | null) => [...(prevList || []), item.styles.style_no]);
          const status =
            item.status.every((value: boolean) => value === true)
              ? 'Done' : 'Pending';
          tempStyleTable = [...tempStyleTable, { styleNo: item.styles.style_no, status: status }];
          tempStyleTable.sort((a: StyleTableData, b: StyleTableData) => {
            if (a.status === 'Pending' && b.status !== 'Pending') return -1; // 'Pending' comes before anything else
            return 0;
          })
          setStyleTable(tempStyleTable);
        }
      }
      );

    }
  }, [entireData]);

  useEffect(() => {

    // Update total of received quantities
    if (allocationData?.receivedQty) {
      const total = allocationData.receivedQty.reduce((sum: number, obj: { quantity: number }) => sum + obj.quantity, 0);
      setAllocationData((prevState: typeof allocationData) => ({ ...prevState, total }));
    }

    if (allocationData?.sizeQuantities) {
      const total = allocationData.sizeQuantities.flat().reduce((sum: number, obj: { quantity: number }) => sum + obj.quantity, 0);
      setTotalAllocation(total);
    }
    // Calculate row totals and total allocation when allocationData changes
    calculateRowTotals();
    calculateTotalAllocation();
    calculateOverstockTotal();
    calculateOverstockPerSize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationData]);

  // Update the calculateRowTotals function
  const calculateRowTotals = () => {
    if (!allocationData) return;

    const totals: number[] = [];

    allocationData.storeName.forEach((store: string, rowIndex: number) => {
      let rowTotal = 0;

      allocationData.sizeQuantities[rowIndex].forEach((sizeObj: any) => {
        rowTotal += sizeObj.quantity;
      });

      totals.push(rowTotal);
    });

    setStoreTotals(totals);
  };


  // Function to calculate the total allocation
  const calculateTotalAllocation = () => {
    if (!allocationData) return;

    let total = 0;

    allocationData.totalAllocationPerSize.forEach((quantity: number) => {
      total += quantity;
    });

    setTotalAllocation(total);
  };

  // Function to calculate the overstock total
  const calculateOverstockTotal = () => {
    if (!allocationData) return;

    let total = 0;

    allocationData.overstockPerSize.forEach((quantity: number) => {
      total += quantity;
    });

    setOverstockTotal(total);
  };


  // Function to update allocation data
  const updateAllocationData = async (withAlert: boolean = false) => {
    setIsLoading(true);
    setError(null);
    let statusArray: boolean[] = allocationData.initial.map((init: string) => (init === 'SVP' || init.trim() === '') ? false : true);
    try {
      if (allocationData.initial === ('SVP' || '')) {
        alert('Please check initials.');
        return;
      }
      const response = await axios.put(`/allocation/updated/${allocationData.allocation_id}`, {
        storeName: allocationData.storeName,
        sizeQuantities: allocationData.sizeQuantities,
        receivedQty: allocationData.receivedQty,
        total: allocationData.total,
        totalAllocationPerSize: allocationData.totalAllocationPerSize,
        overstockPerSize: allocationData.overstockPerSize,
        style_id: allocationData.style_id,
        status: statusArray,
        supplierName: allocationData.supplierName,
        // poNo: allocationData.poNo,
        po_id: allocationData.po_id,
        initial: allocationData.initial
      }, { withCredentials: true });
      setAllocationData(response.data);
      await updateStyleData();
      fetchEntireData();
      setIsLoading(false);
      if (withAlert) {
        alert('Allocation Data Updated successfully');
      } 
    } catch (error) {
      console.error(error);
      setError('An error occurred while updating the allocation data.');
      setIsLoading(false);
    }
  };

  // Function to update style data
  const updateStyleData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      if (styleData.color === '' || styleData.description === '') {
        alert('Please fill all the fields.');
        setIsLoading(false);
        return;
      } else {
        const response = await axios.put(`/style/update/${styleData.style_id}`, {
          description: styleData.description,
          color: styleData.color,
          notes: styleData.notes,
          cost: styleData.cost,
          msrp: styleData.msrp
        }, { withCredentials: true });
        setStyleData(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setError('An error occurred while updating the style data.');
      setIsLoading(false);
    }

  };

  //Push to Store
  const pushToWeb = async (styleNo: string, poNo: string) => {
    const response = await fetch(`/allocation/updateShowOnWeb/${styleNo}/${poNo}`, {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ showOnWeb: true }),
    });

    const data = await response.json();
    setRefresh(true);
    // Update the UI accordingly
    alert('Data Pushed to Store successfully');
  };


  const handleStyleDataChange = (key: string, value: any) => {
    const newData = { ...styleData, [key]: value };
    setStyleData(newData);
  };

  // Function to handle received quantity changes
  const handleReceivedQtyChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newQty = Number(event.target.value);
    setAllocationData((prevData: any) => {
      const newData = { ...prevData };
      newData.receivedQty[index].quantity = newQty;
      return newData;
    });

    // Update total of received quantities
    if (allocationData?.receivedQty) {
      const total = allocationData.receivedQty.reduce((sum: number, obj: { quantity: number }) => sum + obj.quantity, 0);
      setAllocationData((prevState: any) => ({ ...prevState, total }));
    }
  };



  // Function to handle size quantity changes
  const handleSizeQuantityChange = (event: React.ChangeEvent<HTMLInputElement>, storeIndex: number, sizeIndex: number) => {
    const newQty = Number(event.target.value);
    setAllocationData((prevData: any) => {
      const newData = { ...prevData };
      newData.sizeQuantities[storeIndex][sizeIndex].quantity = newQty;

      // Update total allocation
      if (newData.sizeQuantities) {
        const total = newData.sizeQuantities.flat().reduce((sum: number, obj: { quantity: number }) => sum + obj.quantity, 0);
        setTotalAllocation(total);
      }

      // Calculate total allocation per size (column-wise sum)
      if (newData.sizeQuantities) {
        const totalAllocationPerSize: number[] = [];
        const columnLength = newData.sizeQuantities[0].length;
        for (let i = 0; i < columnLength; i++) {
          let sum = 0;
          for (let j = 0; j < newData.sizeQuantities.length; j++) {
            sum += newData.sizeQuantities[j][i].quantity;
          }
          totalAllocationPerSize.push(sum);
        }
        newData.totalAllocationPerSize = totalAllocationPerSize;
      }

      return newData;
    });
  };

  //Calculate OverstockPerSize after Changes
  const calculateOverstockPerSize = () => {
    if (allocationData?.receivedQty && allocationData?.totalAllocationPerSize) {
      const overstockPerSize: number[] = [];
      const length = Math.min(allocationData.receivedQty.length, allocationData.totalAllocationPerSize.length);
      for (let i = 0; i < length; i++) {
        let overstock = allocationData.receivedQty[i].quantity - allocationData.totalAllocationPerSize[i];
        overstockPerSize.push(overstock);
      }
      setAllocationData((prevState: any) => ({ ...prevState, overstockPerSize: overstockPerSize }));
    }
  };

  // Function to handle initials changes
  const handleInitialChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newInitial = event.target.value;
    setAllocationData((prevData: any) => {
      const newData = { ...prevData };
      newData.initial[index] = newInitial;
      return newData;
    });
  };

  // reduces image size it exceede certain size
  const handleImageProcessing = async (file: File | Blob) => {
    const reader = new FileReader();
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    reader.onload = () => {
      img.onload = () => {
        const maxWidth = 800; // Desired maximum width
        const maxHeight = 600; // Desired maximum height
        let newWidth = img.width;
        let newHeight = img.height;

        if (newWidth > maxWidth || newHeight > maxHeight) {
          if (newWidth > maxWidth) {
            newHeight *= maxWidth / newWidth;
            newWidth = maxWidth;
          }

          if (newHeight > maxHeight) {
            newWidth *= maxHeight / newHeight;
            newHeight = maxHeight;
          }

          canvas.width = newWidth;
          canvas.height = newHeight;
          ctx?.drawImage(img, 0, 0, newWidth, newHeight);

          const resizedImageData = canvas.toDataURL('image/jpeg', 0.8); // JPEG format with quality 0.8
          setImageData(resizedImageData);
        } else {
          setImageData(reader.result as string);
        }
      };

      img.src = reader.result as string;
    };

    reader.readAsDataURL(file);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      handleImageProcessing(file);
    }
  };

  const handleImageUrlChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      handleImageProcessing(blob);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [orientation, setOrientation] = useState<'portrait' | 'landscape'>('portrait');

  // Handler for orientation change
  const handleOrientationChange = (value: 'portrait' | 'landscape') => {
    setOrientation(value);
  };

  const fetchPoNo = async () => {
    try {
      const response = await fetch(`/allocation/getPoNo/`, { credentials: 'include' });
      const data = await response.json();
      // await setPoNoData(data.map((item: any) => item.poNo));
      const poData = data.map((item: PoNoData) => ({
        label: item.poNo,
        value: item.po_id || 0  // Provide a default value when item.po_id is undefined
      }));
      await setPoNoData(poData);
      setPoNoList(poData.map((item: { label: string; value: string | number }) => ({
        label: item.label,
        value: item.value.toString()
      })));
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchPoNo();
  }, []);



  const handleOnChangePoNo = async (value: string | null) => {
    // const value = event.target.value;
    if (value !== null) {
      setPoNoSelected(value);
      setPoId(value);
      let temPoNo = await poNoList.find((item) => item.value === value)?.label || '';
      setPoNo(temPoNo);
    }
    await fetchEntireData();
  }
  const
    handleOnChangeStyleNo = async (value: string) => {
      // if (styleNo !== '') {
      //   const confirmation = window.confirm('Do you want to save the changes before moving to the next item?');
      //   if (confirmation) {
      //     await updateAllocationData();
      //     setStyleNo(value);
      //     fetchAllocationData(value, poNo);
      //   }
      // } else {
      //   setStyleNo(value);
      //   fetchAllocationData(value, poNo);
      // }
      if(styleNo !== ''){await updateAllocationData();}
      setStyleNo(value);
      fetchAllocationData(value, poNo);
    };
  const handlePrev = async () => {
    const confirmation = window.confirm('Save the changes before moving to the previous item?');
    if (confirmation) {
      const currentIndex = styleList.indexOf(styleNo);
      const index = (currentIndex - 1 + styleList.length) % styleList.length;
      await setStyleNo(styleList[index]);
      await fetchAllocationData(styleList[index], poNo);
    }

  };

  const handleNext = async () => {
    const confirmation = window.confirm('Save the changes before moving to the next item?');
    if (confirmation) {
      let index = (styleList.indexOf(styleNo) + 1) % styleList.length;
      await setStyleNo(styleList[index]);
      await fetchAllocationData(styleList[index], poNo);
    }
  };
  const handleGet = async () => {
    if (styleList.length !== 0) {
      await fetchAllocationData(styleList[0], poId);
      await setStyleNo(styleList[0]);
    }
    close();
  };
  const handleSelectType = (values: 'clothing' | 'footware') => {
    setItemType(values);
  };

  return (
    <div>

      <Homepage setNavbarOpened={setNavbarOpened} />

      <Modal style={{ height: '100%', zIndex: 100 }} opened={opened} onClose={close} title="Enter Po No" centered>
        <Flex
          direction='row'
          justify='center'
          mih={220}
          gap='md'
        >
          <Select
            type="text"
            label="Po No"
            value={poNoSelected} // Convert to uppercase
            onChange={handleOnChangePoNo} // Convert to uppercase
            onBlur={handlePoNoBlur}
            data={poNoList}
            dropdownPosition='bottom'
            defaultValue={poNoList[0]?.value}
            searchable
            placeholder="Enter po_no"
            mih={50}
          // style={{ marginBottom: '40px' }}
          />
          <Button style={{ marginTop: '25px' }} onClick={handleGet}>Get</Button>
        </Flex>
      </Modal>
      <Grid gutter="lg">
        <Col span={8} style={{ paddingLeft: "40px", paddingRight: "40px" }}>
          <Container style={{ marginTop: "105px" }}>
            <Grid gutter="lg" justify="center" >
              <Col span={4}>
                <Select
                  type="text"
                  label="Po No"
                  value={poNoSelected} // Convert to uppercase
                  onChange={handleOnChangePoNo} // Convert to uppercase
                  onBlur={handlePoNoBlur}
                  data={poNoList}
                  defaultValue={poNoList[0]?.value}
                  searchable
                  placeholder="Enter po_no"
                  mih={50}
                />
              </Col>
              <Col span={4}>
                <Select
                  type="text"
                  label="Style No"
                  value={styleNo} // Convert to uppercase
                  onChange={handleOnChangeStyleNo} // Convert to uppercase
                  data={styleList}
                  placeholder="Enter style_no"
                  searchable
                  mih={50}
                />
              </Col>
              {featureFlags.NEXT_PREV_BTN && styleList.length > 0 && (<Col span={4} style={{ marginTop: "25px", justifyContent: "center", alignItems: "center", display: "flex", gap: "10px" }}>
                <Button variant='outline' onClick={handlePrev}>Prev</Button>
                <Button variant='outline' onClick={handleNext}>Next</Button>
              </Col>)}
            </Grid>
          </Container>



          {styleData && (<div>
            <Table>
              <Grid gutter="lg" justify="center" >
                <Col span={4}>
                  <TextInput
                    label="Supplier Name"
                    value={styleData.supplier_name}
                    ta="center"
                  />
                  <TextInput
                    label="Color"
                    value={styleData.color}
                    onChange={(e) => handleStyleDataChange('color', e.target.value)}
                    ta="center"
                    required
                  />

                </Col>
                <Col span={4}>

                  <TextInput
                    label="Style Number"
                    value={styleData.style_no}
                    ta="center"
                  />
                  <TextInput
                    label="Cost"
                    value={styleData.cost}
                    ta="center"
                  />
                </Col>
                <Col span={4}>
                  <TextInput
                    label="Description"
                    value={styleData.description}
                    onChange={(e) => handleStyleDataChange('description', e.target.value)}
                    ta="center"
                    required
                  />
                  <TextInput
                    label="MSRP"
                    value={styleData.msrp}
                    ta="center"
                  />
                </Col>
                <Col span={4}>
                  <TextInput
                    label="Notes"
                    value={styleData.notes}
                    onChange={(e) => handleStyleDataChange('notes', e.target.value)}
                    ta="center"
                  />
                </Col>
              </Grid>

            </Table>

          </div>
          )}
        </Col>
        <Col span={4}>
          {styleTable.length > 0 && (<Flex
            mih={50}
            // gap="sm"
            justify="center"
            align="center"
            direction="column"
            wrap="wrap"
            style={{ marginTop: "120px" }}
          >
            <Text size="lg" style={{ textDecoration: 'underline', position: 'sticky', left: 0, zIndex: 1, }} weight={500}>PoNo: {poNo}</Text>
            <Text size="md" weight={500}>Pending: {styleTable.filter((item) => item.status === 'Pending').length}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Done: {styleTable.filter((item) => item.status === 'Done').length}</Text>
            <DataGrid
              striped
              highlightOnHover
              withColumnFilters
              withSorting
              data={styleTable}
              height={250}
              styles={{
                globalFilter: {
                  border: '1px solid black',
                  radius: '5px',
                  backgroundColor: 'white',
                },
                table: {
                  border: '1px solid black',
                  radius: '5px',
                  width: '100%',
                },
                thead: {
                  border: '1px solid black',
                  backgroundColor: 'gold',
                  position: 'sticky',
                  left: 0, zIndex: 1,
                },
              }}
              columns={[
                {
                  accessorKey: 'styleNo',
                  header: 'Style No',
                  filterFn: stringFilterFn,
                  // size: 200,
                },
                {
                  accessorKey: 'status',
                  header: 'Status',
                  filterFn: stringFilterFn,
                  size: 90,
                }
              ]}
            />
          </Flex>)}
        </Col>
      </Grid>
      {/* Render the allocation data */}

      {allocationData && (
        <MantineProvider
          theme={{
            components: {
              Container: {
                defaultProps: {
                  sizes: {
                    xs: 540,
                    sm: 720,
                    md: 960,
                    lg: 1140,
                    xl: 2340,
                  },
                },
              },
            },
          }}
        >
          <Container style={{ marginTop: "30px" }} size="xl">
            <Grid style={{ overflowX: "auto" }}>
              <Table>
                <thead>
                  <tr>
                    <th style={{ position: 'sticky', left: 0, zIndex: 1, background: '#F6F6F6', fontWeight: 'bold' }}>Store Name</th>
                    {/* Render size quantities as the first row */}
                    {allocationData.sizeQuantities[0].map((sizeObj: any) => (
                      <th key={sizeObj.size}>{sizeObj.size}</th>
                    ))}
                    <th>Total</th>
                    <th>Initial</th>
                  </tr>
                </thead>
                <tbody style={{ border: '1px solid #d0d0d0', borderRight: '1px solid #d0d0d0' }}>
                  {/* Render received quantities as the second row */}
                  <tr>
                    <td style={{ position: 'sticky', left: 0, zIndex: 1, background: '#F6F6F6', fontWeight: 'bold' }}>RCV QTY</td>
                    {allocationData.receivedQty.map((receivedObj: any, i: number) => (
                      <td key={receivedObj.size} style={{ borderLeft: '1px solid #d0d0d0' }}>
                        <Input
                          type='number'
                          value={receivedObj.quantity}
                          onChange={(e) => handleReceivedQtyChange(e, i)}
                          style={{ width: "50px" }}
                          size='md'
                          variant='unstyled'
                          onWheel={(e) => e.currentTarget.blur()}

                        />
                      </td>
                    ))}
                    <td style={{ borderLeft: '1px solid #d0d0d0', borderRight: '1px solid #d0d0d0' }}><Input value={allocationData?.total} style={{ width: "50px" }} size='md' variant='unstyled' /></td>
                  </tr>
                  {/* ... */}
                  {/* Render size quantities for each store */}
                  {allocationData.storeName.map((store: string, storeIndex: number) => (
                    <tr onClick={() => setActiveRow(store)} onBlur={() => setActiveRow('')} className={activeRow === store ? 'highlighted' : ''} key={store} >
                      <td style={{ position: 'sticky', left: 0, zIndex: 1, background: '#F6F6F6', fontWeight: 'bold' }}>{store}</td>
                      {allocationData.sizeQuantities[storeIndex].map((sizeObj: any, sizeIndex: number) => (
                        <td key={sizeObj.size} style={{ borderLeft: '1px solid #d0d0d0' }}>
                          <Input
                            type="number"
                            value={sizeObj.quantity}
                            onChange={(e) => handleSizeQuantityChange(e, storeIndex, sizeIndex)}
                            style={{ width: "100%" }}
                            size='md'
                            variant='unstyled'
                            onWheel={(e) => e.currentTarget.blur()}
                          />
                        </td>
                      ))}
                      <td style={{ borderLeft: '1px solid #d0d0d0' }}><Input value={storeTotals[storeIndex]} style={{ width: "100%" }} size='md' variant='unstyled' readOnly /></td>
                      {/* Handling initial */}

                      <td style={{ borderLeft: '1px solid #d0d0d0' }}>

                        <Input
                          type='text'
                          value={allocationData.initial[storeIndex]}
                          onChange={(e) => handleInitialChange(e, storeIndex)}
                          style={{ width: "100%" }}
                          size='md'
                          variant='unstyled'
                        />

                      </td>

                    </tr>
                  ))}
                  {/* Render the overstock row */}
                  <tr style={{ borderBottom: '1px solid #d0d0d0', borderTop: '1px solid #d0d0d0' }}>

                    <td style={{ borderLeft: '1px solid #d0d0d0', position: 'sticky', left: 0, zIndex: 1, background: '#F6F6F6', fontWeight: 'bold' }}>Total Allocation</td>
                    {allocationData.totalAllocationPerSize.map((quantity: number, index: number) => (
                      <td key={index} style={{ borderLeft: '1px solid #d0d0d0' }}><Input value={quantity} style={{ width: "100%" }} size='md' variant='unstyled' /></td>
                    ))}
                    <td style={{ borderLeft: '1px solid #d0d0d0', borderRight: '1px solid #d0d0d0' }}><Input value={totalAllocation} style={{ width: "100%" }} size='md' variant='unstyled' readOnly /></td>

                  </tr>
                  <tr style={{ borderBottom: '1px solid #d0d0d0' }}>

                    <td style={{ borderLeft: '1px solid #d0d0d0', position: 'sticky', left: 0, zIndex: 1, background: '#F6F6F6', fontWeight: 'bold' }}>Overstock</td>
                    {allocationData.overstockPerSize.map((quantity: number, index: number) => (
                      <td key={index} style={{ borderLeft: '1px solid #d0d0d0' }}><Input value={quantity} style={{ width: "100%" }} size='md' variant='unstyled' /></td>
                    ))}
                    <td style={{ borderLeft: '1px solid #d0d0d0', borderRight: '1px solid #d0d0d0' }}><Input value={overstockTotal} style={{ width: "100%" }} size='md' variant='unstyled'></Input></td>

                  </tr>

                </tbody>
              </Table>
            </Grid>
            <Center style={{ marginTop: "30PX" }}>
              <Group>
                <Group>
                  <Text style={{ fontWeight: 'bold' }}>Size/Qty</Text>
                  <TextInput
                    // label="Size/Qty"
                    value={manualsizeQty}
                    placeholder="Enter size/qty"
                    onChange={(e) => setManualSizeQty(e.target.value)}
                  ></TextInput>
                  <Input type="file" onChange={handleFileChange} />
                  <TextInput
                    placeholder="Enter image URL"
                    onChange={handleImageUrlChange}
                  />
                </Group>
                <Select
                  data={[
                    { value: 'portrait', label: 'Portrait' },
                    { value: 'landscape', label: 'Landscape' },
                  ]}
                  value={orientation}
                  onChange={handleOrientationChange}
                />
                <Select
                  data={[{ value: 'clothing', label: 'Clothing' }, { value: 'footware', label: 'Footware/Accessories' }]} // Data array containing options
                  value={itemType} // Internal value
                  onChange={handleSelectType} // Handler for when an option is selected
                  placeholder="Select an option"
                />
                <Switch label="Single Page" checked={singlePortraint} onChange={(event) => setSinglePortraint(event.currentTarget.checked)} />

                <PrintStoreLabels props={{ allocationData, styleData, orientation, manualsizeQty, imageData, storeTotals, overstockTotal, styleNo, itemType, singlePortraint, totalAllocation }} />
                <PrintAllocationPaper props={{ allocationData, styleData, storeTotals, overstockTotal, styleNo, totalAllocation, poNo }} />
                <Button onClick={() => pushToWeb(styleNo, poNo)}>Push to Web</Button>
                <Button onClick={() => updateAllocationData(true)}>SAVE CHANGES</Button>

              </Group>
            </Center>
          </Container>
        </MantineProvider>
      )}

    </div>

  );
};

export default AllocationComponent;